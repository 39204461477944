export const validateAge = () => {
  const date = new Date()
  const year = date.getFullYear()
  const maxYear = year - 100
  const minYear = year - 3
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const maxAge = maxYear + '-' + month + '-' + day
  const minAge = minYear + '-' + month + '-' + day
  const thisYear = year + '-' + month + '-' + day

  return { maxAge, minAge, thisYear }
}
