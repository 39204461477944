import { motion } from 'framer-motion';
import { MdHighlightOff, MdTaskAlt } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { BREAK_POINTS, COLORS, SHADOWS } from '../../../../assets/styles/theme';

export const Wrapper = styled(motion.section)`
  min-height: 22rem;
  width: 40%;
  background-color: ${COLORS.background_l};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: ${SHADOWS.low};
  @media (${BREAK_POINTS.l}) {
    width: 65%;
  }
  @media (${BREAK_POINTS.m}) {
    width: 90%;
  }
  @media (${BREAK_POINTS.s}) {
    width: 95%;
  }
`;
export const NameField = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  gap: 0.5rem;
`;
export const NumberField = styled.div`
  width: 100%;
  display: flex;
`;
export const NumberInput = styled(NumberFormat)`
  width: 100%;
  height: 2.75rem;
  border: 1px solid ${COLORS.gray_l};
  padding: 0.5rem 1rem;
  border-radius: 0 5px 5px 0;
  &:hover {
    box-shadow: ${({ isBackgroundBlue }) =>
      isBackgroundBlue === 'true' ? SHADOWS.b_low : SHADOWS.so_low};
  }
  &:focus {
    outline: none;
    box-shadow: ${({ isBackgroundBlue }) =>
      isBackgroundBlue === 'true' ? SHADOWS.b_mid : SHADOWS.low};
  }
`;
export const StaticBox = styled.div`
  height: 2.75rem;
  border-radius: 3px 0 0 3px;
  display: flex;
  user-select: none;
  align-items: center;
  justify-content: center;
  font-size: 0.85rem;
  width: ${(props) => (props.length === 'small' ? '12.5%' : '20%')};
  cursor: default;
  background-color: ${COLORS.gray_l};
  @media (${BREAK_POINTS.m}) {
    font-size: 0.75rem;
  }
  @media (${BREAK_POINTS.m}) {
    font-size: 0.7rem;
  }
`;

export const PwdContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  user-select: none;
  transition: all 0.3s ease;
`;
export const PwdHeader = styled.h4`
  text-align: center;
`;
export const PwdList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.3rem 0;
`;
export const PwdItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`;
export const PwdIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
  width: 1rem;
`;
export const PwdInfo = styled.span``;
export const NotVerifiedIcon = styled(MdHighlightOff)`
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
`;
export const VerifiedIcon = styled(MdTaskAlt)`
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
`;
export const DomainList = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;
export const DomainItem = styled.div`
  height: 2rem;
  width: 8rem;
  background-color: ${COLORS.gray};
  color: ${COLORS.gray_d};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.gray_l};
  }
`;
