import Tooltip from '@mui/material/Tooltip';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../../../assets/styles/style/styled';
import { LOGIN_FAILURE, LOGIN_START, LOGIN_SUCCESS } from '../../../../data/resources/actionTypes';
import { MOBILE_CODE, ROLE_USER, SOURCE } from '../../../../data/resources/constants';
import { emailRegex } from '../../../../data/resources/regex';
import { signIn } from '../../../../services/api/authApi';
import { useAuth } from '../../../../utils/hooks/useAuth';
import { useLogger } from '../../../../utils/hooks/useLogger';
import { useLogin } from '../../../../utils/hooks/useLogin';
import { useToast } from '../../../../utils/hooks/useToast';
import InfoModal from '../../modals/info';
import { Desc, Field as Fielt, InputField, Name } from '../../modals/info/style';
import { EyeIconContainer, Info, Input } from '../login-wrapper/style';
import EyeContainer from './EyeContainer';
import { LoginPopupContainer } from './LoginPopupContainer';
import { Button, Cell, Container, Field, ForgetPassword, Label } from './style';

const LoginForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    formData,
    ppData,
    handleOnChange,
    handlePPChange,
    showPassword,
    setShowPassword,
    isBtnDisabled,
    isPPBtnDisabled,
  } = useLogin();
  const [showInfo, setShowInfo] = useState(false);
  const [showPPInfo, setShowPPInfo] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [externalData, setExternalData] = useState('');
  const [hidePwd, setHidePwd] = useState(false);
  const { dispatch } = useAuth();
  const logger = useLogger();

  const isEmail = (value) => value.match(emailRegex);
  const mobileNumberConversion = {
    9: (value) => MOBILE_CODE + value,
    10: (value) => MOBILE_CODE + value.substring(1),
    12: (value) => value,
    13: (value) =>
      value.startsWith('+') ? value.substring(1) : value.substring(0, 3) + value.substring(4),
    14: (value) => value.substring(1, 4) + value.substring(5),
  };

  const { setError } = useToast();

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (isBtnDisabled.value) return;

    const { email, password } = formData;

    let data = {
      password,
      registrationSource: SOURCE,
    };

    if (isEmail(email)) data.email = email.toLowerCase();
    else if (
      Object.keys(mobileNumberConversion)
        .map((i) => +i)
        .includes(email.length)
    ) {
      data.email = mobileNumberConversion[email.length](email);
    } else data.email = email.toUpperCase();

    dispatch({ type: LOGIN_START });
    logger('Login submitted on landing page', {
      email: data.email,
      registrationSource: data.registrationSource,
    });
    mutate(data);
  };

  // TODO: edited > signIn
  const { mutate, isLoading } = useMutation(signIn, {
    onError: (error) => {
      const errorMsg = error?.response?.data?.code || error.response.status;
      logger('Error on login', error);
      setError(error);
      dispatch({
        type: LOGIN_FAILURE,
        payload: errorMsg,
      });
    },
    onSuccess: ({ data }) => {
      const { accessToken, roles, pin, email, passwordChange } = data;
      if (!email?.includes('@')) {
        if (passwordChange === 'false') {
          setHidePwd(false);
        } else {
          setHidePwd(true);
        }
        setShowPopup(true);
        setExternalData({ mobileNumber: formData.email, accessToken, pin });
        logger('Login without email starting...', {
          mobileNumber: formData.email,
          accessToken,
          pin,
        });
        return;
      }

      if (accessToken && roles.includes(ROLE_USER)) {
        dispatch({ type: LOGIN_SUCCESS, payload: { accessToken, email, pin } });

        let expires = new Date();
        expires.setDate(expires.getDate() + 1);

        logger('Logged in successfully', { accessToken, email, pin });

        navigate('/dashboard', { email });
      } else if (accessToken && !roles.includes(ROLE_USER)) {
        dispatch({
          type: LOGIN_FAILURE,
          payload: 6001,
        });

        setError(6001);
      }
    },
  });

  return (
    <Container onSubmit={handleOnSubmit}>
      {showPopup && (
        <LoginPopupContainer
          hidePwd={hidePwd}
          formData={ppData}
          externalPassword={formData.password}
          handleOnChange={handlePPChange}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          isBtnDisabled={isPPBtnDisabled}
          showPPInfo={showPPInfo}
          setShowPPInfo={setShowPPInfo}
          setShowPopup={setShowPopup}
          loginLoading={isLoading}
          externalData={externalData}
          setExternalData={setExternalData}
          loginMutate={mutate}
        ></LoginPopupContainer>
      )}
      <Cell>
        <Label>{t('username')}</Label>
        <Field>
          <Input
            name='email'
            value={formData.email}
            onChange={handleOnChange}
            placeholder={t('email_pin_mobileNumber')}
            autoFocus
          />
          <EyeIconContainer
            onHoverStart={() => setShowInfo(true)}
            onHoverEnd={() => setShowInfo(false)}
            onTouchStart={() => setShowInfo(true)}
            onTouchEnd={() => setShowInfo(false)}
          >
            <Info />
          </EyeIconContainer>
          {showInfo && (
            <InfoModal title={'username'}>
              <Desc>{t('info_desc')}</Desc>
              <Fielt>
                <Name>{t('Email')}: </Name>
                <InputField placeholder={t('email_example')} disabled />
              </Fielt>
              <Fielt>
                <Name>{t('id_pin')}: </Name>
                <InputField placeholder={t('pin_example')} disabled />
              </Fielt>
              <Fielt>
                <Name>{t('mobile_number')}: </Name>
                <InputField placeholder={t('number_example')} disabled />
              </Fielt>
            </InfoModal>
          )}
        </Field>
      </Cell>
      <Cell>
        <Label>{t('Password')}</Label>
        <Field>
          <Input
            name='password'
            value={formData.password}
            onChange={handleOnChange}
            placeholder={t('enter')}
            type={showPassword ? 'text' : 'password'}
          />
          <EyeContainer field={formData.password} open={showPassword} setOpen={setShowPassword} />
        </Field>
        <ForgetPassword onClick={() => navigate('/auth/recovery')}>
          {t('forgot_password')}
        </ForgetPassword>
      </Cell>
      <Cell>
        <Tooltip title={t(isBtnDisabled.msg)} arrow>
          <Button
            whileTap={{
              scale: isBtnDisabled.value ? 1 : 0.95,
            }}
            isdisabled={isBtnDisabled.value || isLoading ? 'true' : 'false'}
          >
            {isLoading ? <Spinner col={'acc'} wid='1.9rem' /> : t('Login')}
          </Button>
        </Tooltip>
      </Cell>
    </Container>
  );
};

export default LoginForm;
