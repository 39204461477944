import { URLS } from '../../data/resources/constants'

const BASE = URLS.base

export const base = BASE + URLS.api
export const cardBase = BASE + URLS.card_base
export const merchantBase = BASE + URLS.merchant_base

export const commonBase = URLS.commonBase

export const token_type = 'Bearer '
