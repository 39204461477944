export const validateFields = (e) => {
  const { name, value } = e.target;

  if (name === 'firstname' || name === 'lastname' || name === 'middleName') {
    let noNumberValue = value.replace(/[^a-zəıüöğşçA-ZÜÖŞÇƏİ]/g, '');
    return noNumberValue.charAt(0).toUpperCase() + noNumberValue.slice(1);
  }
  if (name === 'pin') return value.toUpperCase().replace(/ /g, '');
  if (name === 'email') return value.replace(/ /g, '');

  return value;
};
