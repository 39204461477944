import { getSession } from '../../utils/helpers/session'

export const initialRegisterFormData = {
  firstName: getSession('tezode-field-firstName') || '',
  lastName: getSession('tezode-field-lastName') || '',
  middleName: getSession('tezode-field-middleName') || '',
  email: getSession('tezode-field-email') || '',
  pin: getSession('tezode-field-pin') || '',
  mobileNumber: getSession('tezode-field-mobileNumber') || '',
  password: '',
  confirmPassword: '',
  referralCode: ''
}

export const initialUserUpdateFormData = {
  firstname: '',
  lastname: '',
  middleName: '',
  email: '',
  pin: '',
  dateOfBirth: '',
  mobileNumber: '',
  referal: ''
}

export const initialPasswordValidation = {
  isLenghtTrue: false,
  isNumberTrue: false,
  isSymbolTrue: false,
  isCapitalTrue: false,
  isLetterTrue: false,
  isEverythingTrue: false
}

export const initialLoginFormData = {
  email: '',
  password: ''
}

export const initialPPFormData = {
  email: '',
  password: '',
  mobileNumber: ''
}

export const initialRecoveryData = {
  email: '',
  mobileNumber: ''
}

export const initialPaginationValues = {
  currentPage: 1,
  rowsPerPage: 5,
  start: 0,
  end: 5
}

export const initialMessageFormData = {
  name: '',
  email: '',
  message: ''
}
