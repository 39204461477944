import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import { Backdrop, CircularProgress, IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../assets/styles/style/styled';
import { formatCardNumber } from '../../../utils/helpers/helpers';
import { useAddCard } from '../../../utils/hooks/useAddCard';
import { Btn } from '../../components/features/login-wrapper/style';
import { CommisionText, Field, Form, FormWrapper, Label, Option, Row, Select } from './style';

const ServiceForm = ({
  handleSubmit,
  formData,
  setFormData,
  catsList,
  orgsList,
  cardsList,
  isLoading,
  location,
}) => {
  const { t } = useTranslation();
  const [showDrop, setShowDrop] = useState(false);
  const { mutate: urlMutate } = useAddCard();

  function handleAdd() {
    urlMutate();
    setFormData({
      ...formData,
      card: location?.state?.id || null,
    });
    setShowDrop(true);
  }

  return (
    <FormWrapper>
      <Form onSubmit={handleSubmit}>
        <Field>
          <Label>{t('category')}: </Label>
          <Select
            value={formData.category || undefined}
            onChange={(e) => setFormData({ ...formData, category: e.target.value })}
          >
            {catsList.map((cat) => (
              <Option value={cat} key={cat}>
                {cat}
              </Option>
            ))}
          </Select>
        </Field>
        <Field>
          <Label>{t('organizationName')}: </Label>
          <Select
            value={formData.organization || undefined}
            onChange={(e) => setFormData({ ...formData, organization: e.target.value })}
          >
            {orgsList
              ?.filter((org) => org?.id !== undefined)
              .map((org) => (
                <Option value={org.id} key={org.id}>
                  {org?.label}
                </Option>
              ))}
          </Select>
        </Field>
        <Field>
          <Label>{t('card')}: </Label>
          <Row>
            <Select
              value={
                formData?.card && cardsList?.filter((card) => card.id).length ? formData.card : null
              }
              defaultValue='default'
              onChange={(e) => setFormData({ ...formData, card: e.target.value })}
            >
              <Option value='default' disabled>
                {t('select_a_card')}
              </Option>
              {cardsList
                ?.filter((card) => card?.id !== undefined)
                .map((card) => (
                  <Option value={card.id} key={card.id}>
                    {card.cardNumber ? formatCardNumber(card.cardNumber) : ''}
                  </Option>
                ))}
            </Select>
            <Tooltip title={t('add_new_card')}>
              <IconButton
                onClick={handleAdd}
                sx={{ marginLeft: '0.25rem', width: '2.75rem', height: '2.75rem' }}
              >
                <AddCardOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Row>
        </Field>
        <CommisionText>{t('commisionInfo')}</CommisionText>
        <Btn
          whileTap={{ scale: 0.95 }}
          disabled={
            !formData.category ||
            !formData.card ||
            !formData.organization ||
            isLoading ||
            !cardsList?.filter((card) => card.id).length
          }
        >
          {isLoading ? <Spinner /> : t('approve')}
        </Btn>
      </Form>
      {(showDrop || isLoading) && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showDrop}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
    </FormWrapper>
  );
};

export default ServiceForm;
