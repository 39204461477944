import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { log } from '../../services/api/commonApi';

export const useLogger = () => {
  const location = useLocation();

  const { mutate } = useMutation((data) => log(data), {
    retry: false,
  });

  /**
   *
   * @param {string} message Message to log
   * @param {any} payload Any object that contains necessary information
   * @param {string?} page Page on which the log has been sent from
   */
  function logger(message, payload, page = location?.pathname || '/') {
    mutate({
      message: !message ? 'LOG FROM ONBOARD_REACT' : message.toUpperCase(),
      page,
      payload: !payload
        ? 'No payload'
        : typeof payload === 'string'
        ? payload
        : JSON.stringify(payload),
    });
  }

  return logger;
};
