import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import {
  AuthPage,
  Dashboard,
  CardsPage,
  HistoryPage,
  Profile,
  Landing,
  NotFound,
  PartnersPage,
} from '../../views';
import DashboardLayout from '../layout/dashboard-layout';
import { RecoveryWrapper } from '../../components';
import { useAuth } from '../../../utils/hooks/useAuth';
import { PATHS } from '../../../data/resources/constants';
import { TableProvier } from '../../../context/TableProvider';
// import HistoryPageTest from '../../views/history-page/test'
import { Toaster } from 'react-hot-toast';
import { useServiceStore } from '../../../context/ServiceIdProvider';
import LandingLayout from '../layout/landing-layout';

const AllRoutes = () => {
  const { user } = useAuth();
  const result = useServiceStore();

  return (
    <>
      <Toaster />
      <Routes>
        <Route path={PATHS.landing} element={<LandingLayout />}>
          <Route index element={<Landing />} />
          <Route path={PATHS.partners} exact element={<PartnersPage />} />
        </Route>
        <Route path={PATHS.notfound} element={<NotFound />} />
        {/* Public Paths for Auth */}
        <Route
          path={PATHS.auth}
          element={!user?.accessToken ? <AuthPage /> : <Navigate to={PATHS.landing} />}
        >
          <Route index element={<Navigate to={PATHS.landing} />} />
          <Route
            path={PATHS.recovery}
            element={!user?.accessToken ? <RecoveryWrapper /> : <Navigate to={PATHS.landing} />}
          />
        </Route>
        {/* Private Paths for Dashboard */}
        <Route
          path={PATHS.dashboard}
          element={
            !result ? (
              <Navigate to={PATHS.landing} />
            ) : user?.accessToken ? (
              <DashboardLayout />
            ) : (
              <Navigate to={PATHS.auth} />
            )
          }
        >
          <Route index element={<Dashboard />} />
          <Route path={PATHS.cards} element={<CardsPage />} />
          <Route
            path={PATHS.history}
            element={
              <TableProvier user={user}>
                <HistoryPage />
              </TableProvier>
            }
          />
          <Route path={PATHS.profile} element={<Profile />} />
          <Route path={PATHS.historyTest} element={<HistoryPage />} />
        </Route>
      </Routes>
    </>
  );
};

export default AllRoutes;
