import React from 'react'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded'
import { Menu, MenuItem } from '@mui/material'

export function UserMenu(props) {
  return (
    <Menu
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      id='profile-menu'
      anchorEl={props.anchorEl}
      open={props.open}
      onClose={props.handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button'
      }}
    >
      <MenuItem sx={props.menuItemStyle} onClick={props.handleDropdownClick}>
        <span>{props.t('language')}</span>
        <LanguageRoundedIcon />
      </MenuItem>
      <MenuItem sx={props.menuItemStyle} onClick={(e) => props.handleLogout(e)}>
        <span>{props.t('log_out')}</span>
        <LogoutRoundedIcon />
      </MenuItem>
    </Menu>
  )
}
