import { getCardsByEmail } from '../../services/api/cardApi'
import { useAuth } from './useAuth'
import { useFetch } from './useFetch'

export const useGetCardsByEmail = (onSuccess) => {
  const {
    user: { accessToken: token }
  } = useAuth()

  return useFetch('cards', () => getCardsByEmail(token), {
    onError: (err) => console.error(err),
    onSuccess: ({ data }) => onSuccess(data),
    refetchOnWindowFocus: false
  })
}
