import { createContext, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DialogBox } from '../app/components'
import { UserLanguageMenu } from '../app/components/features/user-section/UserLanguageMenu'
import { UserMenu } from '../app/components/features/user-section/UserMenu'
import { LOGOUT } from '../data/resources/actionTypes'
import { useAuth } from '../utils/hooks/useAuth'
import i18n from 'i18next'
import { languages } from '../data/resources/lists'
import { useMutation } from 'react-query'
import { signOut } from '../services/api/authApi'

const MenuContext = createContext({})

const menuItemStyle = {
  fontSize: 12,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '10rem'
}

const MenuProvider = ({ children }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { dispatch } = useAuth()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const [isLangModalOpen, setIsLangModalOpen] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const [lang, setLang] = useState(
    i18n.language ? languages.find((l) => i18n.language.toString() === l.code) : languages[0]
  )

  const { mutate } = useMutation(signOut, {
    onError: (err) => console.error(err),
    onSuccess: () => {
      dispatch({ type: LOGOUT })
      navigate('/')
      setAnchorEl(null)
    }
  })

  const agreeLogout = (token) => {
    mutate(token)
  }

  const handleLogout = (e) => {
    setIsDialogOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const openLanguageModal = () => {
    setIsLangModalOpen(true)
  }
  const closeLanguageModal = () => {
    setIsLangModalOpen(false)
  }
  const handleDropdownClick = (e) => {
    e.stopPropagation()
    setAnchorEl(null)
    openLanguageModal(true)
  }
  const handleLangSelect = (code) => {
    setLang(languages.find((l) => code === l.code))
    i18n.changeLanguage(code)
    setIsLangModalOpen(false)
    setAnchorEl(null)
  }

  const token = JSON.parse(sessionStorage.getItem('user'))?.value?.accessToken

  return (
    <MenuContext.Provider value={{ setMenu: setAnchorEl, isMenuOpen: open }}>
      <UserMenu
        t={t}
        anchorEl={anchorEl}
        open={open}
        handleLogout={handleLogout}
        handleClose={handleClose}
        handleDropdownClick={handleDropdownClick}
        menuItemStyle={menuItemStyle}
      ></UserMenu>
      <UserLanguageMenu
        isOpenModal={isLangModalOpen}
        lang={lang}
        openLanguageModal={openLanguageModal}
        closeLanguageModal={closeLanguageModal}
        handleLangSelect={handleLangSelect}
      ></UserLanguageMenu>
      <DialogBox
        isOpen={isDialogOpen}
        onAgree={agreeLogout}
        desc={'do_you_want_to_logout'}
        setIsOpen={setIsDialogOpen}
        token={token}
      />
      {children}
    </MenuContext.Provider>
  )
}

const useMenu = () => useContext(MenuContext)

export { MenuProvider, useMenu }
