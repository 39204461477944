import { carPlateFormats } from '../../data/resources/lists';
import { alphaNumeric } from '../../data/resources/regex';

import {
  americanExpressLogo,
  discoverLogo,
  maestroLogo,
  mastercardLogo,
  visaLogo,
} from '../../assets/images';
import { CARD_TYPE } from '../../data/resources/constants';

const cardTypeMap = {
  [CARD_TYPE.VISA]: visaLogo,
  [CARD_TYPE.MASTERCARD]: mastercardLogo,
  [CARD_TYPE.MAESTRO]: maestroLogo,
  [CARD_TYPE.DISCOVER]: discoverLogo,
  [CARD_TYPE.AMERICANEXPRESS]: americanExpressLogo,
};

export const getCardTypeImg = (type) => {
  return cardTypeMap[type] || visaLogo;
};

export const getBase64 = (file) => {
  return new Promise((res, rej) => {
    let reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      res(reader.result);
    };
    reader.onerror = (err) => {
      rej(err);
    };
  });
};

export const formatCardNumber = (num) => {
  const allow = [0, 1, 2, 3, 12, 13, 14, 15];
  const insert = [4, 9, 14];
  let astNum = num.split('').map((v, i) => (allow.includes(i) ? v : '*'));
  insert.forEach((v) => astNum.splice(v, 0, ' '));
  return astNum.join('');
};

export const formatCarPlate = (num) => {
  if (!carPlateFormats.find((car) => car.type === detectString(num))) return null;
  let splitted = num.split('');
  carPlateFormats
    .find((car) => car.type === detectString(num))
    .allow.forEach((v) => splitted.splice(v, 0, ' - '));
  return splitted;
};
export const formatMobileNumber = (num) => {
  const formatted = num.replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, '$1-$2-$3-$4');
  return formatted;
};

export const formatPlate = () => {};

export const detectString = (str) => {
  let result = '';
  str.split('').forEach((c) => {
    if (!detectChar(c)) {
      return false;
    }
    result += detectChar(c);
  });
  return result;
};

const detectChar = (char) => {
  if (typeof char !== 'string') return false;
  if (char.trim() === '') return false;
  if (!char.match(alphaNumeric)) return false;

  return isNaN(char) ? 's' : 'n';
};

export const removeDuplicates = (arr) =>
  [...new Set(arr.map((a) => JSON.stringify(a)))].map((a) => JSON.parse(a));

export const getServices = (selectedPlates, selectedCards, chain, services) => {
  const byPlate = chain.filter((c) => selectedPlates.includes(c.plate));
  const cardIds = selectedCards.map((c) => c.id);
  const byCards = byPlate.filter((c) => cardIds.includes(c.cardId));
  const byOperation = [...new Set(byCards.map((c) => c.operationId))];
  const result = byOperation.map((op) =>
    services.find((s) => s.operationId.toString() === op.toString())
  );
  return result;
};

export const capitalizeWord = (word) => {
  return word.charAt(0) + word.slice(1).toLowerCase();
};

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
