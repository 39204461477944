import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getMerchants } from '../../../services/api/merchantApi';
import { useFetch } from '../../../utils/hooks/useFetch';
import { BreadCrumb, Title, TitleRow as Container } from './style';
import { useServiceStore } from '../../../context/ServiceIdProvider';

const TitleRow = ({ handleCategoryClick, category, params }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { ASAN_SERVICE_ID: asanServiceId } = useServiceStore();

  const { data } = useFetch('merchants', getMerchants);

  return (
    <Container>
      <Title className='intro_dashboard_1' onClick={() => navigate('/dashboard')}>
        {t('services')}{' '}
        {params?.merchantId &&
          data &&
          `(${data.data.services.find((s) => s.id === +params?.merchantId)?.shortName})`}
      </Title>
      {params && params.merchantId === asanServiceId.toString() && (
        <>
          <p>-</p>
          <BreadCrumb onClick={() => navigate('/dashboard?merchantId=1')}>
            {t('category')}
          </BreadCrumb>
        </>
      )}
      {params && params.category && (
        <>
          <p>-</p>
          <BreadCrumb onClick={() => handleCategoryClick(category)}>
            {t('organizationName')}
          </BreadCrumb>
        </>
      )}
      {params && params.organization && (
        <>
          <p>-</p>
          <BreadCrumb>{t('form')}</BreadCrumb>
        </>
      )}
    </Container>
  );
};

export default TitleRow;
