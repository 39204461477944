import styled from 'styled-components'
import { COLORS, SHADOWS, BREAK_POINTS } from '../../../../assets/styles/theme'

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${COLORS.background_l};
  box-shadow: ${SHADOWS.mid};
  min-height: 27.5rem;
  z-index: 1000;
  width: 40%;
  @media (${BREAK_POINTS.l}) {
    width: 65%;
  }
  @media (${BREAK_POINTS.m}) {
    width: 80%;
  }
  @media (${BREAK_POINTS.s}) {
    width: 90%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 2rem;
  border-radius: 12px;
`
export const Title = styled.h2`
  margin-bottom: 2rem;
`
export const Form = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 1rem;
  position: relative;
`
