import React from 'react'
import {
  Container,
  Title,
  Subtitle,
  Content,
  Card,
  ImgContainer,
  CardTitle,
  CardDesc,
  Dots
} from './style'
import { dots1, dots2 } from '../../../../assets/images'
import { useTranslation, Trans } from 'react-i18next'
import { subheaderCards } from '../../../../data/resources/lists'

const SubHeader = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Dots src={dots1} pos={{ top: '10rem', left: '10rem' }} alt='Decoration' />
      <Dots src={dots2} pos={{ top: '20rem', left: '75rem' }} alt='Decoration' />
      <Title>{t('sh_title')}</Title>
      <Trans>
        <Subtitle>{t('sh_subtitle')}</Subtitle>
      </Trans>
      <Content>
        {subheaderCards.map((c) => (
          <Card key={c.key}>
            <ImgContainer>{c.icon}</ImgContainer>
            <CardTitle>{t(c.title)}</CardTitle>
            <CardDesc>{t(c.desc)}</CardDesc>
          </Card>
        ))}
      </Content>
    </Container>
  )
}

export default SubHeader
