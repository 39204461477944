import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import Navbar from '../navbar';
import { Footer } from '../../../components';

const LandingLayout = () => {
  const { t } = useTranslation();

  return (
    <main>
      <Helmet>
        <title>{t('pt_landing')}</title>
      </Helmet>
      <Navbar />
      <Outlet />
      <Footer />
    </main>
  );
};

export default LandingLayout;
