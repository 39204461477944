import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import { COLORS, SHADOWS } from '../../../../assets/styles/theme';
import { AnimatePresence, motion } from 'framer-motion';

const Container = styled(motion.div)`
  position: fixed;
  bottom: 6rem;
  right: 1.7rem;
  height: 3.5rem;
  aspect-ratio: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.background_l};
  color: ${COLORS.gray_d};
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: ${SHADOWS.mid};
  z-index: 999;
  &:hover {
    transform: scale(1.1);
  }
`;

const GoTop = () => {
  const [showArrow, setShowArrow] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 790) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    });
  }, []);

  const handleUp = () => {
    window.scrollTo(0, 0);
  };

  return (
    <AnimatePresence>
      {showArrow && (
        <Container
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2, type: 'tween' }}
          exit={{ opacity: 0 }}
          onClick={handleUp}
        >
          <ExpandLessRoundedIcon />
        </Container>
      )}
    </AnimatePresence>
  );
};

export default GoTop;
