import { Skeleton } from '@mui/material'
import React from 'react'
import { COLORS } from '../../../assets/styles/theme'

const CardSkeletons = ({ height }) => {
  return Array.from({ length: 3 }).map((_, i) => (
    <Skeleton
      variant='rounded'
      key={i}
      height={height}
      width='100%'
      animation='wave'
      sx={{ backgroundColor: COLORS.gray_l, margin: '0', borderRadius: '4px' }}
    />
  ))
}

export default CardSkeletons
