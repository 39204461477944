import axios from 'axios';
import { URLS, requestHeaders } from '../../data/resources/constants';
import { base, token_type } from './baseURL';

const authApi = axios.create({
  baseURL: base + URLS.auth,
  headers: requestHeaders,
});

const authApiPrivate = axios.create({
  baseURL: base + URLS.auth,
  headers: requestHeaders,
});

export const signUp = async (data) => await authApi.post(URLS.auth_signup, data);
export const signUpConfirm = async (data) => await authApi.post(URLS.auth_signup_confirm, data);
export const signIn = async (data) => await authApi.post(URLS.auth_signin, data);
export const signInNew = async (data) => await authApi.post(URLS.auth_signin_new, data);
export const passwordCheck = async (data) => await authApi.post(URLS.auth_pwd_check, data);
export const passwordConfirm = async (data) => await authApi.post(URLS.auth_pwd_confirm, data);
export const passwordUpdate = async (data) => await authApi.post(URLS.auth_pwd_update, data);
export const checkRefferal = async (data) => await authApi.get('/onboard/referral/' + data);

authApiPrivate.interceptors.request.use(function (config) {
  const user = JSON.parse(sessionStorage.getItem('user')) || null;
  const value = user?.value || null;
  const token = value.accessToken;

  config.headers['Authorization'] = token_type + token;
  return config;
});

export const editEmail = async (data) => await authApiPrivate.post(URLS.auth_edit, data);
export const signOut = async () => await authApiPrivate.post(URLS.auth_signout, null);
