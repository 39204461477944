import React from 'react'
import { useTranslation } from 'react-i18next'
import { Blank, ListHeader, Organization, ServiceHeader } from './style'

const ServiceListHeader = ({ serviceLength }) => {
  const { t } = useTranslation()

  return (
    <ListHeader>
      {serviceLength !== 1 && <Blank wid='5%' mright='1rem'></Blank>}
      <Organization nomrg={serviceLength === 1}>{t('service')}</Organization>
      <ServiceHeader>{t('service_identificator')}</ServiceHeader>
      <Blank wid='15%'></Blank>
    </ListHeader>
  )
}

export default ServiceListHeader
