/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { LARGE_BREAKPOINT, MOBILE_BREAKPOINT } from '../../../../assets/styles/theme';
import { EMAIL_TO } from '../../../../data/resources/constants';
import { emailRegex, mobNumRegex } from '../../../../data/resources/regex';
import { mailSend, partnerSend } from '../../../../services/api/commonApi';
import { formatFields } from '../../../../utils/helpers/formatFields';
import { useLogger } from '../../../../utils/hooks/useLogger';
import { useToast } from '../../../../utils/hooks/useToast';
import { useWindow } from '../../../../utils/hooks/useWindow';
import { Cell, Container, Label } from '../login-form/style';
import { Input } from '../login-wrapper/style';
import MobileField from '../register-form/MobileField';
import { RegisterButton } from '../register-form/RegisterButton';
import { Option, Select } from './style';

const initialForm = {
  workingSector: 'YDM',
  organizationName: '',
  duty: '',
  fullName: '',
  mobileNumber: '',
  email: '',
};

const PartnerWrapper = () => {
  const { t } = useTranslation();
  const { width } = useWindow();
  const [formData, setFormData] = useState(initialForm);
  const [isBtnDisabled, setIsBtnDisabled] = useState({
    value: true,
    msg: 'fill_the_gaps',
  });
  const { setSuccess, setError } = useToast();
  const logger = useLogger();

  const { mutate } = useMutation(partnerSend, {
    onError: (err) => {
      setError(err);
      logger('Partner mail sent error', err)
    },
    onSuccess: ({ data }) => {
      setSuccess('data_sent_successfully');
    },
  });

  const { mutate: mutateMail } = useMutation(mailSend, {
    onError: (err) => {
      setError(err);
      logger('Mail sent error', err)
    },
  });

  const handleChange = (e) => {
    const { name } = e.target;

    setFormData((prev) => ({ ...prev, [name]: formatFields(e) }));
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (isBtnDisabled.value) return;

    setFormData(initialForm);
    mutate(formData);
    const data = {
      name: formData.fullName,
      email: formData.email,
      emailTo: EMAIL_TO,
      message: JSON.stringify({ ...formData, mobileNumber: '+994' + formData.mobileNumber }),
    };
    mutateMail(data);
    logger('Partner message sent', data);
  };

  function checkName(fullName) {
    const names = fullName.trim().split(' ');
    if (names.length < 2) return true;
    return names.some((name) => name.length < 3);
  }

  function getIsBtnDisabled() {
    if (
      !(
        formData.email &&
        formData.fullName &&
        formData.mobileNumber &&
        formData.workingSector &&
        formData.duty &&
        formData.organizationName
      )
    ) {
      setIsBtnDisabled({ value: true, msg: 'fill_the_gaps' });
    } else if (!formData.email.match(emailRegex)) {
      setIsBtnDisabled({ value: true, msg: 'error_msg_email_valid' });
    } else if (checkName(formData.fullName)) {
      setIsBtnDisabled({ value: true, msg: 'invalid_full_name' });
    } else if (formData.mobileNumber.length < 9 || !formData.mobileNumber.match(mobNumRegex)) {
      setIsBtnDisabled({ value: true, msg: 'error_msg_mobile_phone' });
    } else {
      setIsBtnDisabled({ value: false });
    }
  }

  useEffect(() => {
    getIsBtnDisabled();
  }, [formData]);

  return (
    <Container onSubmit={handleOnSubmit}>
      <Cell>
        <Label>{t('area')}</Label>
        <Select onChange={handleChange} value={formData.area} name='area'>
          <Option value={'YDM'}>YDM</Option>
          <Option value={'Parklama'}>Parklama</Option>
          <Option value={'Others'}>{t('others')}</Option>
        </Select>
      </Cell>
      <Cell>
        <Label>{t('organization_name')}</Label>
        <Input
          autoFocus
          value={formData.organizationName}
          onChange={handleChange}
          name='organizationName'
          placeholder={t('enter')}
        />
      </Cell>
      <Cell>
        <Label>{t('role')}</Label>
        <Input value={formData.duty} onChange={handleChange} name='duty' placeholder={t('enter')} />
      </Cell>
      <Cell>
        <Label>{t('name_surname')}</Label>
        <Input
          value={formData.fullName}
          onChange={handleChange}
          name='fullName'
          placeholder={t('enter')}
        />
      </Cell>
      <Cell>
        <Label>{t('mobile_number')}</Label>
        <MobileField number={formData.mobileNumber} setNumber={setFormData} />
      </Cell>
      <Cell>
        <Label>{t('Email')}</Label>
        <Input
          value={formData.email}
          onChange={handleChange}
          name='email'
          placeholder={'example@gmail.com'}
        />
      </Cell>
      {width > MOBILE_BREAKPOINT && <Cell></Cell>}
      {width > LARGE_BREAKPOINT && <Cell></Cell>}
      <Cell>
        <RegisterButton t={t} isBtnDisabled={isBtnDisabled} text={'apply'} isLoading={false} />
      </Cell>
    </Container>
  );
};

export default PartnerWrapper;
