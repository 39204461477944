import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import client from './config/queryClient';
import App from './app/App';
import { AuthProvider } from './context/AuthProvider';
import './config/i18';
import './assets/styles/index.css';

import { AlertProvider } from './context/AlertProvider';
import { MenuProvider } from './context/MenuProvider';
import './assets/fonts/Poppins-Regular.ttf';
import { ServiceIdProvider } from './context/ServiceIdProvider';

/**
 * Entry point of the application. ReactDom.createRoot adds the whole application to the div (inside the 'index.html' file) which has an id 'root'.
 */
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={client}>
    <BrowserRouter>
      <AlertProvider>
        <AuthProvider>
          <ServiceIdProvider>
            <MenuProvider>
              <App />
            </MenuProvider>
          </ServiceIdProvider>
        </AuthProvider>
      </AlertProvider>
    </BrowserRouter>
  </QueryClientProvider>
);
