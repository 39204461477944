import styled from 'styled-components'
import { motion } from 'framer-motion'
import { BREAK_POINTS, COLORS, SHADOWS } from '../../../../assets/styles/theme'
import InfoIcon from '@mui/icons-material/Info'

export const Wrapper = styled(motion.section)`
  min-height: 22rem;
  width: 40%;
  @media (${BREAK_POINTS.l}) {
    width: 65%;
  }
  @media (${BREAK_POINTS.m}) {
    width: 90%;
  }
  @media (${BREAK_POINTS.s}) {
    width: 95%;
  }
  background-color: ${COLORS.background_l};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: ${SHADOWS.low};
`
export const Header = styled.header`
  text-align: center;
  width: 100%;
`
export const WelcomeText = styled.span`
  font-size: 0.9rem;
  font-style: italic;
  color: ${COLORS.gray_d};
`
export const HeaderText = styled.h1`
  font-weight: 600;
  font-size: 1.3rem;
`
export const ErrorBlock = styled(motion.div)`
  position: relative;
  height: 3rem;
  padding-left: 1rem;
  background-color: ${COLORS.error_l};
  color: ${COLORS.error_d};
  width: 100%;
  border-radius: 5px;
  border-left: 6px solid ${COLORS.error_d};
  font-size: 0.9rem;
  @media (${BREAK_POINTS.m}) {
    font-size: 0.8rem;
  }
  display: flex;
  align-items: center;
  margin: ${({ mgh }) => (mgh ? mgh : '-1rem')} 0;
  box-shadow: ${SHADOWS.so_low};
`
export const Form = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  position: relative;
`

export const Field = styled.div`
  width: 100%;
  height: 2.75rem;
  position: relative;
`
export const EyeIconContainer = styled(motion.div)`
  position: absolute;
  height: 2.75rem;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: ${COLORS.gray_d};
  top: 0;
  right: 0;
`
export const Input = styled.input`
  width: 100%;
  height: 2.75rem;
  border: 1px solid ${({ isPasswordSame }) => (isPasswordSame ? COLORS.primary_l : COLORS.gray_l)};
  padding: 0.5rem 1rem;
  border-radius: ${(props) => props.radius || '5px'};
  transition: all 0.3s ease;
  &:hover {
    border: 1px solid ${({ isPasswordSame }) => (isPasswordSame ? COLORS.primary : COLORS.gray)};
    box-shadow: ${({ isBackgroundBlue }) =>
      isBackgroundBlue === 'true' ? SHADOWS.b_low : SHADOWS.so_low};
  }
  &:focus {
    outline: none;
    border: 1px solid ${({ isPasswordSame }) => (isPasswordSame ? COLORS.primary_d : COLORS.gray_d)};
    box-shadow: ${({ isBackgroundBlue }) =>
      isBackgroundBlue === 'true' ? SHADOWS.b_mid : SHADOWS.low};
  }
  &:disabled {
    background-color: ${COLORS.gray_l};
    &:hover {
      border: 1px solid ${COLORS.gray_l};
    }
  }
`
export const Btn = styled(motion.button)`
  margin-top: 0.5rem;
  height: 3rem;
  font-weight: bolder;
  border-radius: 10px;
  font-size: 0.9rem;
  width: 100%;
  position: relative;
  background-color: ${COLORS.primary};
  color: ${COLORS.background};
  &:hover {
    background-color: ${COLORS.primary_d};
  }
  &:disabled {
    background-color: ${COLORS.gray_l};
    color: ${COLORS.gray_d};
    cursor: default;
  }
  &:focus {
    background-color: ${COLORS.primary_d};
  }
`
export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: -1rem;
  @media (${BREAK_POINTS.s}) {
    gap: 1rem;
  }
`
export const FooterLink = styled.span`
  color: ${COLORS.gray_d};
  position: relative;
  font-style: italic;
  font-size: 0.8rem;
  @media (${BREAK_POINTS.m}) {
    font-size: 0.7rem;
  }
  cursor: pointer;
  transition: all 0.3s ease;
  &:before {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    bottom: 0;
    background-color: ${COLORS.gray_d};
    transition: all 0.2s ease;
    transform: scale(0);
    z-index: 2;
  }
  &:hover {
    color: ${COLORS.text_l};
    &:before {
      transform: scale(1);
      background-color: ${COLORS.text_l};
    }
  }
  @media (${BREAK_POINTS.s}) {
    font-size: 0.6rem;
  }
  @media (${BREAK_POINTS.xs}) {
    font-size: 0.5rem;
  }
`
export const HiddenField = styled.div`
  margin-top: 0.5rem;
  height: 3rem;
  font-weight: bolder;
  border-radius: 10px;
  font-size: 0.9rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 10;
  opacity: 0;
  display: none;
  @media (${BREAK_POINTS.m}) {
    display: ${({ isNone }) => (isNone ? 'block' : 'none')};
  }
`
export const SuccessBlock = styled(ErrorBlock)`
  background-color: ${COLORS.primary_ll};
  color: ${COLORS.primary_d};
  border-left: 6px solid ${COLORS.primary_d};
`

export const Info = styled(InfoIcon)`
  position: absolute;
  right: 0.65rem;
  top: 50%;
  width: 1.5rem !important;
  height: 1.5rem !important;
  transform: translateY(-50%);
  color: ${COLORS.gray};
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    color: ${COLORS.gray_d};
  }
`
