import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatCardNumber, getCardTypeImg } from '../../../utils/helpers/helpers'
import { DialogBox } from '../../components'
import { Card, CardBtn, CardImg, CardImgContainer, CardNumber } from './style'

const CardContainer = ({ c, handleCardClick, selectedCard, setSelectedCard, agreeDeactivate }) => {
  const { t } = useTranslation()
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleCardDeactiveClick = () => {
    setIsDialogOpen(true)
  }

  useEffect(() => {
    setSelectedCard({ id: null })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen])

  return (
    <Card onClick={() => handleCardClick(c)} isselected={selectedCard.id === c.id}>
      <CardImgContainer>
        <CardImg src={getCardTypeImg(c.type)} alt={c.type} />
      </CardImgContainer>
      <CardNumber>{formatCardNumber(c.cardNumber)}</CardNumber>
      <CardBtn onClick={handleCardDeactiveClick}>{t('deactivate')}</CardBtn>
      <DialogBox
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        desc='agree_to_deactivate'
        info={c.cardNumber}
        onAgree={() => agreeDeactivate(c)}
      />
    </Card>
  )
}

export default CardContainer
