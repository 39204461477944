import { emailRegex, pinRegex } from '../../data/resources/regex'
import { validatePassword } from './validatePassword'

export const getIsPwdBtnDisabled = (data) => {
  if (!data.password || !data.confirmPassword) return [true, 'error_msg_all_fields_filled']
  if (Object.values(validatePassword(data.password)).some((i) => !i)) {
    return [true, 'error_msg_password']
  }

  if (data.confirmPassword !== data.password) {
    return [true, 'error_msg_confirm_password']
  }

  return [false, 'success_msg_everything_true']
}

export const getIsBtnDisabled = (data) => {
  if (
    !data.firstName ||
    !data.lastName ||
    !data.email ||
    !data.password ||
    !data.confirmPassword ||
    !data.pin ||
    !data.mobileNumber
  ) {
    return [true, 'error_msg_all_fields_filled']
  }

  if (!data.email.match(emailRegex)) {
    return [true, 'error_msg_email_valid']
  }

  if (data.pin.length < 5) {
    return [true, 'error_msg_pin_min']
  }
  if (data.pin.length > 7) {
    return [true, 'error_msg_pin_max']
  }
  if (!data.pin.match(pinRegex)) {
    return [true, 'error_pin_regex']
  }

  if (data.mobileNumber.length < 9) {
    return [true, 'error_msg_mobile_phone']
  }

  if (Object.values(validatePassword(data.password)).some((i) => !i)) {
    return [true, 'error_msg_password']
  }

  if (data.confirmPassword !== data.password) {
    return [true, 'error_msg_confirm_password']
  }

  return [false, 'success_msg_everything_true']
}
