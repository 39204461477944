/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Backdrop, CircularProgress } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../../assets/styles/style/styled';
import { BREAK_POINTS } from '../../../assets/styles/theme';
import { steps } from '../../../data/resources/intro';
import {
  changeStatus,
  deactivateCard,
  deleteService,
  getConnectedPaymentsByEmail,
} from '../../../services/api/cardApi';
import { getMerchants } from '../../../services/api/merchantApi';
import { useAddCard } from '../../../utils/hooks/useAddCard';
import { useAuth } from '../../../utils/hooks/useAuth';
import { useFetch } from '../../../utils/hooks/useFetch';
import { useGetCardsByEmail } from '../../../utils/hooks/useGetCardsByEmail';
import { useIntro } from '../../../utils/hooks/useIntro';
import { useLogger } from '../../../utils/hooks/useLogger';
import { useToast } from '../../../utils/hooks/useToast';
import { useWindow } from '../../../utils/hooks/useWindow';
import { DialogBox } from '../../components';
import AddNewCard from './AddNewCard';
import CardContainer from './CardContainer';
import CardSkeletons from './CardSkeletons';
import ServiceListHeader from './ServiceListHeader';
import {
  Checkbox,
  CommisionText,
  Container,
  Content,
  DeleteIcon,
  FooterBtn,
  FooterLabel,
  Header,
  ListCheckbox,
  NoText,
  ServiceBtn,
  ServiceData,
  ServiceFooter,
  ServiceLabel,
  ServiceList,
  ServiceName,
  Title,
  Wrapper,
} from './style';
import { useServiceStore } from '../../../context/ServiceIdProvider';
import { uniqBy } from 'lodash';

const BREAK_POINT = Number(BREAK_POINTS.m.split(' ')[1].slice(0, -2));

const CardsPage = () => {
  const { t } = useTranslation();
  const { width } = useWindow();
  const navigate = useNavigate();
  const serviceIds = useServiceStore();

  const [cards, setCards] = useState([]);
  const [merchantMap, setMerchantMap] = useState({});
  const [operationMap, setOperationMap] = useState({});
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedCard, setSelectedCard] = useState({ id: null });
  const [customLoading, setCustomLoading] = useState(false);
  const [delXref, setDelXref] = useState('');
  const [isServiceDialogOpen, setIsServiceDialogOpen] = useState(false);
  const [labels, setLabels] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [delServiceLoading, setDelServiceLoading] = useState(false);
  const { setSuccess, setSuccessWithId, setError, setErrorWithId } = useToast();

  const JoyRide = useIntro('cards-initial', steps.cards_initial);

  const selectAllRef = useRef(null);
  const queryClient = useQueryClient();

  const { mutate: urlMutate } = useAddCard();

  function onAddNewCard() {
    setSaveLoading(true);
    setTimeout(() => {
      setSaveLoading(false);
    }, 5000);
    urlMutate();
  }

  const logger = useLogger();
  const {
    user: { pin },
  } = useAuth();

  const { mutate: changeStatusMutate, isLoading: changeStatusLoading } = useMutation((id) =>
    changeStatus(id)
  );
  const { isLoading, refetch: refetchCards } = useGetCardsByEmail((data) => {
    const duplicate = data.find((card) => card.status === 'DUPLICATE' && card.isActive === 'true');
    data = data.filter((obj) => obj?.status === 'SUCCESS' && obj?.isActive === 'true');

    if (duplicate) {
      setError('duplicate_card');
      duplicate?.id && changeStatusMutate(duplicate.id);
    }

    setCards(data);
  });

  const { isLoading: serviceLoading, refetch } = useFetch(
    ['services', selectedCard.id],
    () => getConnectedPaymentsByEmail(),
    {
      onSuccess: ({ data }) => {
        const result = data.filter(
          (src) =>
            src.cardId === selectedCard.id &&
            src.isActive === 'true' &&
            src.statusCode === 0 &&
            src.statusCode !== null &&
            src.statusCode !== 'null'
        );

        setServices(result);
      },
      onError: (err) => {
        setErrorWithId(err, 'services-toast');
      },
    },
    { enabled: false }
  );

  useFetch('merchants', getMerchants, {
    onSuccess: ({ data }) => {
      let srvcs = data.services;
      let result = {};
      let operations = {};
      setLabels(
        data.services.reduce((obj, value) => {
          obj[value.id] = value.shortName;
          return obj;
        }, {})
      );

      srvcs.forEach((s) => {
        if (s.id === serviceIds.PARKING_SERVICE_ID) {
          s.organizations.forEach((org) => {
            result[org.id] = org.label;
            org.operations.forEach((op) => {
              operations[op.operationId] = op.name;
            });
          });
        } else {
          s.organizations.forEach((org) => {
            result[org.id] = org.label;
          });
        }
      });
      setOperationMap(operations);
      setMerchantMap(result);
    },
  });

  const intervalCountRef = useRef(0);

  const {
    mutate,
    isLoading: sDelLoading,
    isFetching: sDelFetching,
  } = useMutation((xref) => deleteService(xref), {
    onSuccess: async () => {
      setCustomLoading(delXref);
      logger('Deleting service started', { userPin: pin, xref: delXref });

      const interval = setInterval(async () => {
        intervalCountRef.current += 1;
        const refetchData = await refetch();
        const deletedService = refetchData.data.data.find((s) => s.xref === delXref);

        if (!deletedService || deletedService?.status === 'DEACTIVATED') {
          setDelXref('');
          queryClient.refetchQueries(['cards', 'services']);
          setCustomLoading(false);
          setSuccessWithId(8002, 'service-delete-success');
          clearInterval(interval);
          setDelServiceLoading(false);
          setSaveLoading(false);
          intervalCountRef.current = 0;
          setSelectedServices([]);
        }

        if (intervalCountRef.current === 10) {
          clearInterval(interval);
          intervalCountRef.current = 0;
          setSelectedServices([]);
          setSaveLoading(false);
          setCustomLoading(false);
          setDelServiceLoading(false);
        }
      }, 1000);
    },
    onError: (err) => {
      setSaveLoading(false);
      setCustomLoading(false);
      setDelServiceLoading(false);
      setError(err);
    },
  });

  const { mutate: deactivate } = useMutation((id) => deactivateCard(id), {
    onSuccess: ({ data }) => {
      logger('Deactivated card', { ...data, userPin: pin });
      queryClient.invalidateQueries(['cards', 'services']);
      refetchCards();
      setDelServiceLoading(false);
      setSuccess(8002);
    },
    onError: (err) => {
      logger('Card deactivation failed', err);
      setDelServiceLoading(false);
      setError(6007);
    },
  });

  const handleDeleteBulk = () => {
    setSaveLoading(true);
    const xrefs = selectedServices.map((ss) => ss.xref);
    logger('Services started to get deleted (delete bulk)', { xrefs, selectedServices });
    xrefs.forEach((x) => {
      setDelXref(x);
      setTimeout(() => mutate(x), 1000);
    });
  };

  useEffect(() => {
    if (selectedCard.id) refetch();
  }, [selectedCard.id, refetch]);

  const handleCardClick = (c) => {
    setSelectedServices([]);
    if (selectAllRef.current) {
      selectAllRef.current.checked = false;
    }
    if (selectedCard.id === c.id) {
      setSelectedCard({ id: null });
    } else {
      setSelectedCard(c);
    }
  };

  const agreeDeactivate = (card) => {
    setSelectedServices([]);
    setSelectedCard({ id: null });
    deactivate(card.id);
    setDelServiceLoading(true);
    logger('Card deactivation started', { cardId: card.id, userPin: pin });
  };

  const handleServiceClick = (service) => {
    if (selectedServices.find((ss) => ss.id === service.id)) {
      setSelectedServices(selectedServices.filter((ss) => ss.id !== service.id));
      if (selectedServices.filter((ss) => ss.id !== service.id).length === services.length) {
        selectAllRef.current.checked = true;
      } else {
        selectAllRef.current.checked = false;
      }
    } else {
      setSelectedServices((prev) => [...prev, service]);
      if (selectedServices.length + 1 === services.length) {
        selectAllRef.current.checked = true;
      } else {
        selectAllRef.current.checked = false;
      }
    }
  };

  const handleSelectAll = () => {
    let isChecked = selectAllRef.current.checked;
    if (isChecked) {
      setSelectedServices(services);
    } else {
      setSelectedServices([]);
    }
  };

  const handleServiceDelete = (xref) => {
    setIsServiceDialogOpen(true);
    setDelXref(xref);
  };

  const agreeServiceDelete = () => {
    logger('Service started to get deleted', { xref: delXref });
    setDelServiceLoading(true);
    setTimeout(() => mutate(delXref), 1000);
  };

  const [index, setIndex] = useState(0);
  useHotkeys('mod+shift+e', () => {
    if (cards.length) {
      handleCardClick(cards[0]);
    }
  });

  useHotkeys('shift+down', () => {
    if (cards.length && selectedCard?.id) {
      const len = cards.length;
      let next = index + 1 === len ? 0 : index + 1;
      handleCardClick(cards[next]);
      setIndex(next);
    }
  });
  useHotkeys('shift+up', () => {
    if (cards.length && selectedCard?.id) {
      const len = cards.length;
      let next = index - 1 === -1 ? len - 1 : index - 1;
      handleCardClick(cards[next]);
      setIndex(next);
    }
  });

  useEffect(() => {
    setSaveLoading(false);
    setCustomLoading(false);
    setDelServiceLoading(false);

    return () => {
      setSaveLoading(false);
      setCustomLoading(false);
      setDelServiceLoading(false);
    };
  }, []);

  return (
    <Container>
      <Helmet>
        <title>{t('pt_cards')}</title>
      </Helmet>
      {JoyRide}
      <Wrapper>
        <Header>
          <Title>{t('my_cards')}</Title>
        </Header>
        <Content>
          <AddNewCard onClick={onAddNewCard} text='add_new_card' />
          {isLoading ? (
            <CardSkeletons height={'5rem'} />
          ) : cards.length === 0 ? (
            <NoText>{t('no_card')}</NoText>
          ) : (
            cards.map((c) => (
              <CardContainer
                key={c.id}
                c={c}
                handleCardClick={handleCardClick}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
                agreeDeactivate={agreeDeactivate}
              />
            ))
          )}
        </Content>
      </Wrapper>

      <AnimatePresence>
        {selectedCard.id && (
          <Wrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Header>
              <Title>{t('services')}</Title>
            </Header>
            <Content>
              <AddNewCard
                onClick={() => navigate('/dashboard', { state: selectedCard })}
                text='add_new_service'
              />
              {serviceLoading ? (
                <CardSkeletons height={'4rem'} />
              ) : services.length ? (
                <>
                  <ServiceList>
                    <ServiceListHeader serviceLength={services.length} />
                    {services.map((s) => {
                      return (
                        <ServiceLabel
                          as={services.length === 1 ? 'div' : 'label'}
                          key={s.id}
                          checked={selectedServices.find((ss) => ss.id === s.id) ? true : false}
                        >
                          {services.length !== 1 && (
                            <ListCheckbox
                              checked={selectedServices.find((ss) => ss.id === s.id) ? true : false}
                              type='checkbox'
                              onChange={() => handleServiceClick(s)}
                            />
                          )}
                          <ServiceName>
                            {labels ? labels[s.serviceId] || t('service') : t('service')}
                          </ServiceName>
                          <ServiceData>{s.serviceData}</ServiceData>
                          <DialogBox
                            isOpen={isServiceDialogOpen}
                            setIsOpen={setIsServiceDialogOpen}
                            desc='agree_to_delete'
                            onAgree={agreeServiceDelete}
                          />
                          <ServiceBtn
                            disabled={sDelLoading || sDelFetching || customLoading}
                            onClick={() => handleServiceDelete(s.xref)}
                          >
                            {customLoading === s.xref &&
                            (sDelLoading || sDelFetching || customLoading) ? (
                              <Spinner wid='0.5rem' col='err' />
                            ) : width > BREAK_POINT ? (
                              t('delete_service')
                            ) : (
                              <DeleteIcon />
                            )}
                          </ServiceBtn>
                        </ServiceLabel>
                      );
                    })}
                  </ServiceList>
                  {services.length !== 1 && (
                    <ServiceFooter>
                      <FooterLabel>
                        <Checkbox type='checkbox' ref={selectAllRef} onChange={handleSelectAll} />
                        {t('select_all')}
                      </FooterLabel>
                      <FooterBtn
                        onClick={handleDeleteBulk}
                        disabled={!selectedServices.length || isLoading}
                      >
                        {isLoading ? (
                          <Spinner wid='0.75rem' col='err' />
                        ) : (
                          t('delete_selected_services')
                        )}
                      </FooterBtn>
                    </ServiceFooter>
                  )}
                </>
              ) : (
                <NoText>{t('no_service_for_card')}</NoText>
              )}
            </Content>
          </Wrapper>
        )}
      </AnimatePresence>
      {(saveLoading || delServiceLoading) && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={saveLoading || delServiceLoading}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
    </Container>
  );
};

export default CardsPage;
