export const errors = {
  'Network Error': 'error_network',
  'Request failed with status code 404': 'error_email_password',
  'Request failed with status code 401': 'error_something_went_wrong',
  'Request failed with status code 400': 'error_check',
  'Img size too big': 'img_size_too_big',
  'Img type not allowed': 'img_type_not_allowed',
  'Mobil nömrə artıq istifadə olunur': 'mobile_number_in_use',
  'Verilən məlumata uyğun müştəri tapılmadı': 'error_email_password',
  'OTP Düzgün daxil edilməyib': 'error_otp_wrong',
  401: 'error_email_password',
  invalid_plate: 'invalid_plate',
  undefined: 'error_something_went_wrong'
}
