import { motion } from 'framer-motion'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import styled from 'styled-components'
import { BREAK_POINTS, COLORS, SHADOWS } from '../../../../assets/styles/theme'

export const Container = styled.main`
  width: 100vw;
  min-height: 100vh;
  padding: 5rem 4rem;
  @media (${BREAK_POINTS.l}) {
    padding: 3rem 2rem;
  }
  @media (${BREAK_POINTS.m}) {
    padding: 1rem;
  }
  @media (${BREAK_POINTS.s}) {
    padding: 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Slide = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  position: relative;
  cursor: pointer;
  @media (${BREAK_POINTS.l}) {
    flex-direction: column;
  }
`
export const Img = styled(LazyLoadImage)`
  height: 70vh;
  width: 40%;
  object-fit: cover;
  box-shadow: ${SHADOWS.mid};
  border-radius: 12px;
  @media (${BREAK_POINTS.l}) {
    width: 90%;
    height: 50%;
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
  @media (${BREAK_POINTS.l}) {
    height: 50%;
    width: 90%;
  }
`
export const Title = styled.h2``
export const Desc = styled.span`

`
export const Deco = styled.img`
  position: absolute;
  z-index: -1;
  left: ${({ pos }) => (pos.left ? pos.left : '0')};
  top: ${({ pos }) => (pos.top ? pos.top : '0')};
  transform: rotate(${({ pos }) => (pos.rotate ? pos.rotate : '0deg')});
  @media (${BREAK_POINTS.l}) {
    transform: scale(0.5);
  }
`
export const Btn = styled.button`
  height: 3rem;
  width: 8rem;
  border-radius: 4px;
  background-color: ${COLORS.primary};
  color: ${COLORS.background_d};
  &:hover {
    background-color: ${COLORS.primary_d};
  }
`
export const Modal = styled(motion.div)`
  position: fixed;
  height: 80vh;
  width: 90%;
  background-color: ${COLORS.background_l};
  box-shadow: ${SHADOWS.mid};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  display: none;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  padding-bottom: 0.75rem;
  @media (${BREAK_POINTS.l}) {
    display: flex;
  }
`
export const ModalTitle = styled.h3``
export const ModalImg = styled(LazyLoadImage)`
  width: 100%;
  box-shadow: ${SHADOWS.mid};
  border-radius: 4px 4px 0 0;
`
export const ModalDesc = styled.span`
  font-size: 0.55rem;
  padding: 0 1rem;
  margin-bottom: auto;
`
