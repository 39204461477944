import styled from 'styled-components'
import { BREAK_POINTS, COLORS } from '../../../../assets/styles/theme'

export const Container = styled.div`
  padding: 1rem;
  padding-bottom: 2.5rem;
  background-color: ${COLORS.background_l};

  border-radius: 0 10px 10px 10px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media (${BREAK_POINTS.m}) {
    display: grid;
    border-radius: 0 0 10px 10px;
    grid-template-columns: 1fr;
  }
`
export const Select = styled.select`
  width: 100%;
  height: 2.75rem;
  border: 1px solid ${COLORS.gray_l};
  padding: 0.5rem 1rem;
  border-radius: 5px;
  &:hover {
    border: 1px solid ${COLORS.gray};
  }
  &:focus {
    outline: none;
    border: 1px solid ${COLORS.gray_d};
  }
  &:disabled {
    background-color: ${COLORS.gray_l};
    &:hover {
      border: 1px solid ${COLORS.gray_l};
    }
  }
`
export const Option = styled.option``
