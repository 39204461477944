import styled from 'styled-components';
import { BREAK_POINTS, COLORS, SHADOWS } from '../../../assets/styles/theme';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { motion } from 'framer-motion';
import { SIDEBAR_WIDTH } from '../../components/layout/sidebar/style';

export const Container = styled.main`
  margin-left: ${SIDEBAR_WIDTH};
  width: calc(100% - ${SIDEBAR_WIDTH});
  @media (${BREAK_POINTS.m}) {
    margin-left: 0;
    width: 100%;
  }
  display: flex;
  padding: 2rem 1rem;
  gap: 1rem;
  @media (${BREAK_POINTS.l}) {
    flex-direction: column;
  }
`;
export const Wrapper = styled(motion.div)`
  width: 49%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  @media (${BREAK_POINTS.l}) {
    width: 100%;
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Title = styled.h2``;
export const HeaderLabel = styled.label``;
export const Content = styled.div`
  background-color: ${COLORS.background_l};
  border-radius: 5px;
  box-shadow: ${SHADOWS.low};
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const AddNew = styled.div`
  height: 3.5rem;
  border-radius: 4px;
  background-color: ${COLORS.background};
  padding-left: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: ${COLORS.gray_l};
  }
  display: flex;
  align-items: center;
  gap: 1rem;
`;
export const Icon = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Text = styled.span``;
export const Card = styled.div`
  height: 5rem;
  border-radius: 4px;
  padding: 0 1rem;
  border: 1px solid ${COLORS.gray_l};
  cursor: pointer;
  background-color: ${({ isselected }) => (isselected ? COLORS.primary : COLORS.background_l)};
  color: ${({ isselected }) => (isselected ? COLORS.background_d : COLORS.black)};
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: ${({ isselected }) => (isselected ? COLORS.primary_d : COLORS.background)};
  }
  display: flex;
  align-items: center;
  gap: 1rem;
`;
export const CardImgContainer = styled.div`
  height: 100%;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const CardImg = styled.img`
  width: 4rem;
  @media (${BREAK_POINTS.m}) {
    width: 3rem;
  }
  @media (${BREAK_POINTS.s}) {
    width: 2.5rem;
  }
  @media (${BREAK_POINTS.xs}) {
    width: 2.25rem;
  }
`;
export const CardNumber = styled.span`
  font-size: 1rem;
  @media (${BREAK_POINTS.m}) {
    font-size: 0.85rem;
  }
  @media (${BREAK_POINTS.s}) {
    font-size: 0.7rem;
  }
  @media (${BREAK_POINTS.xs}) {
    font-size: 0.6rem;
  }
`;
const Btn = styled.button`
  border-radius: 4px;
  background-color: ${COLORS.background_l};
  border: 1px solid ${COLORS.error};
  color: ${COLORS.error};
  &:hover {
    background-color: ${COLORS.error};
    border: 1px solid ${COLORS.error_d};
    color: ${COLORS.background_d};
  }
`;
export const CardBtn = styled(Btn)`
  height: 2rem;
  width: 20%;
  margin-left: auto;
  @media (${BREAK_POINTS.xl}) {
    font-size: 0.75rem;
  }
  @media (${BREAK_POINTS.m}) {
    font-size: 0.7rem;
  }
  @media (${BREAK_POINTS.s}) {
    font-size: 0.6rem;
  }
  @media (${BREAK_POINTS.xs}) {
    font-size: 0.55rem;
  }
  &:disabled {
    background-color: ${COLORS.gray_l};
    color: ${COLORS.gray_d};
    cursor: default;
    border: ${COLORS.gray_d};
  }
`;
export const ServiceList = styled.div`
  display: flex;
  border-radius: 0 0 4px 4px;
  flex-direction: column;
`;

export const ServiceLabel = styled.label`
  height: 4rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  border: 1px solid ${COLORS.gray_l};
  border-bottom: none;
  background-color: ${(props) => (props.checked ? COLORS.background_d : 'tranparent')};
  &:first-child {
    border-top: none;
  }
  &:last-child {
    border-radius: 0 0 4px 4px;
    border-bottom: 1px solid ${COLORS.gray_l};
  }
`;
export const Checkbox = styled.input`
  cursor: pointer;
`;
export const ListCheckbox = styled(Checkbox)`
  margin-right: 1rem;
  width: 5%;
`;
export const ServiceName = styled.span`
  width: 42%;
`;
export const ServiceData = styled.span`
  width: 35%;
`;
export const ServiceBtn = styled(Btn)`
  width: 18%;
  height: 2rem;
  margin-left: auto;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    background-color: ${COLORS.gray_l};
    color: ${COLORS.gray_d};
    cursor: default;
    border: ${COLORS.gray_d};
  }
`;
export const DeleteIcon = styled(DeleteOutlineRoundedIcon)`
  @media (${BREAK_POINTS.l}) {
    font-size: 1.4rem !important;
  }
  @media (${BREAK_POINTS.m}) {
    font-size: 1.2rem !important;
  }
`;
export const ServiceFooter = styled.div`
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
`;
export const FooterLabel = styled.label`
  display: flex;
  gap: 1rem;
  cursor: pointer;
  @media (${BREAK_POINTS.l}) {
    font-size: 0.7rem;
  }
  @media (${BREAK_POINTS.m}) {
    font-size: 0.6rem;
  }
`;
export const FooterBtn = styled(Btn)`
  height: 2rem;
  width: 14rem;
  &:disabled {
    background-color: ${COLORS.gray_l};
    color: ${COLORS.gray_d};
    border: none;
  }
  @media (${BREAK_POINTS.l}) {
    font-size: 0.8rem;
  }
  @media (${BREAK_POINTS.m}) {
    font-size: 0.7rem;
  }
`;
export const ListHeader = styled.div`
  background-color: ${COLORS.gray_l};
  height: 2.5rem;
  display: flex;
  align-items: center;
  border-radius: 4px 4px 0 0;
  padding: 1rem;
  color: ${COLORS.black};
`;
export const Organization = styled.span`
  width: 42%;
`;
export const ServiceHeader = styled.span`
  width: 35%;
  @media (${BREAK_POINTS.m}) {
    font-size: 0.65rem;
  }
`;
export const Blank = styled.div`
  height: 2.5rem;
  width: ${({ wid }) => (wid ? wid : '1rem')};
  margin-right: ${({ mright }) => (mright ? mright : '0')};
`;
export const NoText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
`;
export const Msg = styled.div`
  height: 3rem;
  width: 20rem;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
`;

export const CommisionText = styled.p`
  margin-top: 1rem;
  color: ${COLORS.gray_d};
  font-style: italic;
`;
