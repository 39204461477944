import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { FALLBACKLANG, LOADPATH } from '../data/resources/constants'
import { i18order, languages } from '../data/resources/lists'

const loadPath = process.env.PUBLIC_URL + LOADPATH
const fallbackLng = FALLBACKLANG
const order = i18order
const caches = [i18order[0]]

export default i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: languages.map((lang) => lang.code),
    backend: { loadPath },
    fallbackLng,
    detection: {
      order,
      caches
    }
  })
