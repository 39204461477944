import styled from 'styled-components'
import { styled as muistyled } from '@mui/material/styles'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

import { BREAK_POINTS, COLORS } from '../../../../assets/styles/theme'

export const Container = styled.section`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  @media (${BREAK_POINTS.l}) {
    padding: 2rem 1rem;
  }
`
export const Title = styled.h2`
  margin-bottom: 2rem;
`
export const Accordion = muistyled((props) => (
  <MuiAccordion disableGutters elevation={1} square {...props} />
))(() => ({
  border: `1px solid ${COLORS.gray_l}`,
  '&:first-of-type': {
    borderRadius: '5px 5px 0 0'
  },
  '&:last-child': {
    borderRadius: '0 0 5px 5px'
  },
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '@media (max-width: 800px)': {
    width: '100%'
  },
  width: '60%',
  '&:before': {
    display: 'none'
  }
}))
export const AccordionSummary = muistyled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(() => ({
  fontWeight: '500',
  fontStyle: 'italic',
  backgroundColor: COLORS.background_l,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: '1rem'
  }
}))

export const AccordionDetails = muistyled(MuiAccordionDetails)(() => ({
  padding: '1rem',
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}))
