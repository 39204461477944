import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Container, Title } from './style'

const InfoModal = ({ title, children }) => {
  const { t } = useTranslation()

  return (
    <Container initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Title>{t(title)}</Title>
      <Trans>{children}</Trans>
    </Container>
  )
}

export default InfoModal
