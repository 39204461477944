const names = ['firstName', 'lastName', 'middleName'];

export const formatFields = (e) => {
  const { name, value } = e.target;

  if (names.includes(name)) {
    let noNumberValue = value.replace(/[^a-zəıüöğşçA-ZÜÖŞÇƏİ]/g, '');
    return noNumberValue.charAt(0).toUpperCase() + noNumberValue.slice(1);
  }
  if (name === 'fullName') {
    const onlyLetters = value.replace(/[^a-zəıüöğşçA-ZÜÖŞÇƏİ\s]/g, '');
    if (onlyLetters.includes(' ')) {
      return onlyLetters
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    } else {
      return onlyLetters.charAt(0).toUpperCase() + onlyLetters.slice(1).toLowerCase();
    }
  }
  if (name === 'pin' || name === 'referralCode') return value.toUpperCase().replace(/ /g, '');
  if (name === 'email' || name === 'password' || name === 'confirmPassword')
    return value.replace(/ /g, '');

  return value;
};
