/* eslint-disable react-hooks/exhaustive-deps */
import { Backdrop, CircularProgress } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { uniq, uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import styled from 'styled-components';
import { BREAK_POINTS, COLORS } from '../../../assets/styles/theme';
import { MOBILE_CODE } from '../../../data/resources/constants';
import {
  connectBulkOrg,
  getBulkStatus,
  getConnectedPaymentsByEmail,
} from '../../../services/api/cardApi';
import { getMerchants } from '../../../services/api/merchantApi';
import { formatCardNumber, formatMobileNumber } from '../../../utils/helpers/helpers';
import { validateMobileNumberPrefix } from '../../../utils/helpers/validateMobileNumber';
import { useAuth } from '../../../utils/hooks/useAuth';
import { useFetch } from '../../../utils/hooks/useFetch';
import { useGetCardsByEmail } from '../../../utils/hooks/useGetCardsByEmail';
import { useLogger } from '../../../utils/hooks/useLogger';
import { useToast } from '../../../utils/hooks/useToast';
import { Button } from '../../components/features/login-form/style';
import { NumberInput, StaticBox } from '../../components/features/register-wrapper/style';
import { useServiceStore } from '../../../context/ServiceIdProvider';

const Container = styled.main`
  width: 100%;
  height: 75vh;
  display: flex;
  gap: 1rem;
  @media (${BREAK_POINTS.m}) {
    flex-direction: column;
    min-height: 75vh;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 0.5rem;
`;
const Left = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const Right = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const ServicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
`;
const AddContainer = styled.form`
  display: flex;
  align-items: center;
`;

const tableStyles = {
  '& .MuiDataGrid-cell': {
    cursor: 'pointer',
  },
  boxShadow: 1,
  '& .MuiDataGrid-cell:hover': {
    color: COLORS.accent,
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
    width: '0.3em',
    height: '0.3em',
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.accent,
      light: COLORS.accent_l,
      dark: COLORS.accent_d,
    },
  },
});

const TaxiView = () => {
  const { t } = useTranslation();

  const { TAKSI_SERVICE_ID: taxiServiceId } = useServiceStore();
  const lang = {
    // Root
    noRowsLabel: t('no_result_table'),
    noResultsOverlayLabel: t('no_result_table'),
    errorOverlayDefaultLabel: t('error_something_went_wrong'),

    // Columns panel text
    columnsPanelTextFieldLabel: t('toolbar_search_column'),
    columnsPanelTextFieldPlaceholder: t('search'),
    columnsPanelDragIconLabel: t('toolbar_search_column'),
    columnsPanelShowAllButton: t('toolbar_search_column_show'),
    columnsPanelHideAllButton: t('toolbar_search_column_hide'),

    // Filter panel text
    filterPanelAddFilter: t('toolbar_filter_add'),
    filterPanelDeleteIconLabel: t('toolbar_filter_add'),
    filterPanelLinkOperator: t('toolbar_filter_link_operators'),
    filterPanelOperators: t('toolbar_filter_operators'),

    // TODO v6: rename to filterPanelOperator
    filterPanelOperatorAnd: t('toolbar_op_and'),
    filterPanelOperatorOr: t('toolbar_op_or'),
    filterPanelColumns: t('toolbar_op_cols'),
    filterPanelInputLabel: t('toolbar_op_cols_input'),
    filterPanelInputPlaceholder: t('toolbar_op_value'),

    // Filter operators text
    filterOperatorContains: t('toolbar_val_contains'),
    filterOperatorEquals: t('toolbar_val_equals'),
    filterOperatorStartsWith: t('toolbar_val_stw'),
    filterOperatorEndsWith: t('toolbar_val_enw'),
    filterOperatorIs: t('toolbar_val_eq'),
    filterOperatorNot: t('toolbar_val_neq'),
    filterOperatorAfter: t('toolbar_val_after'),
    filterOperatorOnOrAfter: t('toolbar_val_on_after'),
    filterOperatorBefore: t('toolbar_val_before'),
    filterOperatorOnOrBefore: t('toolbar_val_on_before'),
    filterOperatorIsEmpty: t('toolbar_val_emp'),
    filterOperatorIsNotEmpty: t('toolbar_val_nemp'),

    // Column menu text
    columnMenuLabel: t('toolbar_col_menu'),
    columnMenuShowColumns: t('toolbar_col_menu_show'),
    columnMenuFilter: t('toolbar_col_add_filter'),
    columnMenuHideColumn: t('toolbar_col_hide'),
    columnMenuUnsort: t('toolbar_col_unsort'),
    columnMenuSortAsc: t('toolbar_col_sort_asc'),
    columnMenuSortDesc: t('toolbar_col_sort_desc'),

    // Column header text
    columnHeaderFiltersTooltipActive: (count) => t('toolbar_columns_active', { count }),
    columnHeaderFiltersLabel: t('toolbar_col_filters_show'),
    columnHeaderSortIconLabel: t('toolbar_col_filters_sort'),
  };

  const {
    user: { email, pin },
  } = useAuth();
  const logger = useLogger();

  const { setSuccess, setError } = useToast();

  useFetch('merchants', getMerchants, {
    onError: (err) => console.error(err),
    onSuccess: ({ data }) => {
      const taxiServices = data.services.find((item) => item.id === taxiServiceId);
      let result = [];
      taxiServices.organizations.forEach((org) => {
        result.push({ id: org.id, taxi: org.label });
      });
      setTaxis(result);
    },
  });

  const [mobileNumbers, setMobileNumbers] = useState([]);
  const [selectedMobileNumbers, setSelectedMobileNumbers] = useState([]);

  const [cards, setCards] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);

  const [taxis, setTaxis] = useState([]);
  const [selectedTaxis, setSelectedTaxis] = useState([]);

  const [chain, setChain] = useState([]);

  const { cardsLoading } = useGetCardsByEmail((data) => setCards(data));

  const [saveLoading, setSaveLoading] = useState(false);
  const [addMode, setAddMode] = useState({
    open: false,
    value: '',
  });

  const queryClient = useQueryClient();

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useFetch('connectedPayments', () => getConnectedPaymentsByEmail(), {
    onError: (err) => setError(err),
    onSuccess: ({ data }) => {
      const taxiData = data.filter(
        (item) =>
          item.isActive === 'true' && item.serviceId === taxiServiceId && item.statusCode === 0
      );
      let res = [];
      taxiData.forEach((item) => {
        const { id, organizationId, serviceData, cardId } = item;
        res.push({ id, service: organizationId, mobileNumber: serviceData, card: cardId });
      });
      setChain(res);

      setMobileNumbers(
        uniqBy(
          res.map((item) => ({
            id: item.id,
            mobileNumber: item.mobileNumber,
            service: item.service,
          })),
          (obj) => obj.mobileNumber
        )
      );
    },
  });

  function createChain(mobileNumbers, cards, services) {
    let result = chain.filter(
      (item) => !mobileNumbers.map((e) => e.mobileNumber).includes(item.mobileNumber)
    );

    for (let i = 0; i < mobileNumbers.length; i++) {
      for (let j = 0; j < cards.length; j++) {
        for (let k = 0; k < services.length; k++) {
          const obj = {
            id: mobileNumbers[i].id,
            mobileNumber: mobileNumbers[i].mobileNumber,
            card: cards[j].id,
            service: String(services[k].id),
          };
          result.push(obj);
        }
      }
    }

    return result;
  }

  const checkStatus = (id) => {
    const fetchInterval = setInterval(async () => {
      const res = await getBulkStatus(id);
      if (res.status === 200) {
        setSuccess(8001);
        setSelectedMobileNumbers([]);
        setSelectedCards([]);
        setSelectedTaxis([]);
      } else if (res.status === 500) {
        setError(7002);
      }

      if (res.status === 200 || res.status === 500) {
        clearInterval(fetchInterval);
        queryClient.refetchQueries();
        setSaveLoading(false);
      }
    }, 1000);
  };

  const { mutate } = useMutation((data) => connectBulkOrg(data), {
    onError: (err) => console.error(err),
    onSuccess: async ({ data }) => {
      if (data.processUuid) {
        checkStatus(data.processUuid);
        logger('Taxi Connect Bulk, Checking Status', {
          userPin: pin,
          processUUID: data.processUuid,
        });
      } else {
        await sleep(3000);
        logger('Taxi Connect Bulk, Success', {
          userPin: pin,
          selectedMobileNumbers,
          selectedCards,
          selectedTaxis,
        });
        setSuccess(8001);
        setSelectedMobileNumbers([]);
        setSelectedCards([]);
        setSelectedTaxis([]);
        queryClient.refetchQueries();
        setSaveLoading(false);
      }
    },
  });

  const handleSave = () => {
    const serviceId = taxiServiceId.toString();
    const serviceData = uniq(selectedMobileNumbers.map((item) => item.mobileNumber));
    const cardId = uniq(selectedCards.map((item) => item.id));
    const services = uniqBy(
      selectedTaxis.map((item) => ({ organizationId: item.id.toString() })),
      (obj) => obj.organizationId
    );

    setSaveLoading(true);

    mutate({
      serviceId,
      email,
      serviceData,
      cardId,
      services: services,
    });
    logger('Taxi connect started', {
      serviceId,
      email,
      serviceData,
      cardId,
      services: services,
    });
  };

  const mobileNumberColumns = [
    {
      field: 'mobileNumber',
      headerName: t('mobile_numbers'),
      width: 250,
      valueFormatter: ({ value }) => formatMobileNumber(value),
    },
    {
      field: 'service',
      hide: true,
    },
    {
      field: 'new',
      hide: true,
    },
  ];
  const cardColumns = [
    {
      field: 'cardNumber',
      headerName: t('my_cards'),
      width: 200,
      valueFormatter: ({ value }) => formatCardNumber(value),
    },
    {
      field: 'type',
      headerName: t('type'),
      width: 160,
    },
  ];

  const taxiColumns = [
    {
      field: 'taxi',
      headerName: t('taxi_services'),
      width: 200,
    },
  ];

  const handleMobileNumberSelect = (ids) => {
    setSelectedMobileNumbers(mobileNumbers.filter((item) => ids.includes(item.id)));
  };
  const handleCardSelect = (ids) => {
    const newCards = cards.filter((item) => ids.includes(item.id));
    setSelectedCards(newCards);
    if (selectedMobileNumbers.length && newCards.length) {
      setChain(createChain(selectedMobileNumbers, newCards, selectedTaxis));
    }
  };
  const handleTaxiSelect = (ids) => {
    const newTaxis = taxis.filter((item) => ids.includes(item.id));
    setSelectedTaxis(newTaxis);
    if (selectedMobileNumbers.length && selectedCards.length) {
      setChain(createChain(selectedMobileNumbers, selectedCards, newTaxis));
    }
  };
  const handleAddMobileNumberBtn = () => {
    setAddMode({ open: true, value: '' });
  };
  const handleCloseAddMobileNumber = () => {
    setAddMode({ open: false, value: '' });
  };
  const handleAddMobileNumber = (e) => {
    e.preventDefault();
    const newMobileNumber = {
      id: new Date().valueOf(),
      mobileNumber: addMode.value.trim(),
      cards: [],
    };
    setMobileNumbers((prev) => [...prev, newMobileNumber]);
    setAddMode({ open: false, value: '' });
    setSuccess(8003);
  };

  useEffect(() => {
    if (selectedMobileNumbers.length === 0) {
      setSelectedCards([]);
      setSelectedTaxis([]);
    } else {
      const numbers = selectedMobileNumbers.map((item) => item.mobileNumber);
      let cardIds = uniq(
        chain.filter((item) => numbers.includes(item.mobileNumber)).map((item) => item.card)
      );
      setSelectedCards(cards.filter((item) => cardIds.includes(item.id)));
    }
  }, [selectedMobileNumbers]);

  useEffect(() => {
    let cards = [];
    if (selectedCards.length === 0) {
      setSelectedTaxis([]);
    } else {
      cards = selectedCards.map((item) => item.id);
      let orgIds = uniq(
        chain.filter((item) => cards.includes(item.card)).map((item) => +item.service)
      );

      setSelectedTaxis(taxis.filter((item) => orgIds.includes(item.id)));
    }
  }, [selectedCards]);

  return (
    <Container>
      <ThemeProvider theme={theme}>
        <Left>
          <Wrapper>
            <DataGrid
              localeText={lang}
              sx={tableStyles}
              rows={mobileNumbers}
              columns={mobileNumberColumns}
              checkboxSelection
              disableColumnFilter
              disableColumnSelector
              hideFooter
              onSelectionModelChange={handleMobileNumberSelect}
              selectionModel={selectedMobileNumbers.map((item) => item.id)}
            />
            {addMode.open ? (
              <AddContainer onSubmit={handleAddMobileNumber}>
                <Button
                  bg={COLORS.error_d}
                  bgh={COLORS.error}
                  onClick={handleCloseAddMobileNumber}
                  width='3rem'
                  radius='5px 0 0 5px'
                  margin='0'
                  type='button'
                >
                  X
                </Button>
                <StaticBox length='small'>+{MOBILE_CODE}</StaticBox>
                <NumberInput
                  format={'## ### ## ##'}
                  placeholder={'50 123 45 67'}
                  value={addMode.value}
                  onValueChange={(values) => {
                    return setAddMode((prev) => ({
                      ...prev,
                      value: validateMobileNumberPrefix(values.value),
                    }));
                  }}
                />
                {addMode.value && (
                  <Button margin='0' type='submit' width='10rem' radius='0 5px 5px 0'>
                    {t('confirm')}
                  </Button>
                )}
              </AddContainer>
            ) : (
              <Button margin='0' radius='5px' onClick={handleAddMobileNumberBtn}>
                {t('add_new_mobile_number')}
              </Button>
            )}
          </Wrapper>
          <Wrapper>
            <DataGrid
              localeText={lang}
              sx={tableStyles}
              isRowSelectable={() => selectedMobileNumbers.length}
              rows={cardsLoading ? [] : cards}
              columns={cardColumns}
              checkboxSelection
              hideFooter
              onSelectionModelChange={handleCardSelect}
              selectionModel={selectedCards.map((item) => item.id)}
            />
          </Wrapper>
        </Left>
        <Right>
          <ServicesWrapper>
            <DataGrid
              localeText={lang}
              sx={tableStyles}
              rows={taxis}
              columns={taxiColumns}
              checkboxSelection
              isRowSelectable={() => selectedCards.length}
              onSelectionModelChange={handleTaxiSelect}
              selectionModel={selectedTaxis.length ? selectedTaxis.map((item) => item.id) : []}
              hideFooter
            />
          </ServicesWrapper>
          <Button
            style={{ marginBottom: '3rem' }}
            isdisabled={
              !selectedMobileNumbers.length || !selectedCards.length || saveLoading
                ? 'true'
                : 'false'
            }
            radius='5px'
            onClick={
              !selectedMobileNumbers.length || !selectedCards.length || saveLoading
                ? () => {}
                : handleSave
            }
          >
            {t('save')}
          </Button>
        </Right>
      </ThemeProvider>
      {saveLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={saveLoading}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
    </Container>
  );
};

export default TaxiView;
