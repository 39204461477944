import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useState } from 'react'
import i18n from 'i18next'
import { languages } from '../../../../data/resources/lists'
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'

const NavbarLang = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  // eslint-disable-next-line no-unused-vars
  const [lang, setLang] = useState(
    i18n.language ? languages.find((l) => i18n.language.toString() === l.code) : languages[0]
  )

  const handleClick = (event, value) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLangSelect = (code) => {
    setLang(languages.find((l) => code === l.code))
    i18n.changeLanguage(code)
    document.documentElement.setAttribute('lang', code)
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      >
        <LanguageOutlinedIcon />
      </IconButton>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {languages.map((language) => (
          <MenuItem key={language.code} onClick={() => handleLangSelect(language.code)}>
            {language.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default NavbarLang
