import { useLayoutEffect, useState } from 'react'

/**
 * @typedef SizeObject
 * @type {object}
 * @property {number} width - current width of the screen
 * @property {number} height - current height of the screen
 */
/**
 *
 * @returns {SizeObject} - it is current width and height of the browser screen
 */
export const useWindow = () => {
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 })

  const handleSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  useLayoutEffect(() => {
    handleSize()

    window.addEventListener('resize', handleSize)

    return () => window.removeEventListener('resize', handleSize)
  }, [])

  return { width: windowSize.width, height: windowSize.height }
}
