import { motion } from 'framer-motion';
import styled from 'styled-components';
import { BREAK_POINTS, COLORS, SHADOWS } from '../../../assets/styles/theme';
import { SIDEBAR_WIDTH } from '../../components/layout/sidebar/style';

export const Container = styled.main`
  margin-left: ${SIDEBAR_WIDTH};
  width: calc(100% - ${SIDEBAR_WIDTH});
  @media (${BREAK_POINTS.m}) {
    margin-left: 0;
    width: 100%;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Wrapper = styled.div`
  width: 40rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
`;
export const Title = styled.h2`
  text-align: center;
`;
export const Form = styled.form`
  border-radius: 10px;
  width: 100%;
  min-height: 10rem;
  box-shadow: ${SHADOWS.mid};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
`;
export const UploadSection = styled.div`
  position: relative;
  width: 100%;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-inline: 2rem;
  background-color: ${COLORS.gray_l};
  border-radius: 8px;
`;
const ImgBtn = styled(motion.label)`
  position: absolute;
  height: 3rem;
  aspect-ratio: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  @media (${BREAK_POINTS.m}) {
    height: 2.5rem;
  }
`;
export const Background = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  object-fit: cover;
  border-radius: 8px;
  filter: blur(1px) brightness(50%);
`;
export const ErrorModal = styled.div`
  height: 3rem;
  width: 15rem;
  border-radius: 4px;
  box-shadow: ${SHADOWS.mid};
  background-color: ${COLORS.error_ll};
  color: ${COLORS.error_d};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 20;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const DeleteBtn = styled(ImgBtn)`
  bottom: 0.5rem;
  right: 4rem;
  background-color: ${COLORS.error_ll};
  border: 1px solid ${COLORS.error_d};
  color: ${COLORS.error_d};
  &:hover {
    background-color: ${COLORS.error_d};
    border: 1px solid ${COLORS.error_d};
    color: ${COLORS.error_ll};
  }
`;
export const AddImgBtn = styled(ImgBtn)`
  bottom: 0.5rem;
  right: 0.5rem;
  background-color: ${COLORS.primary_ll};
  border: 1px solid ${COLORS.primary_d};
  color: ${COLORS.primary_d};
  &:hover {
    background-color: ${COLORS.primary_d};
    border: 1px solid ${COLORS.primary_d};
    color: ${COLORS.primary_ll};
  }
`;
export const Image = styled(motion.img)`
  height: ${({ isfull }) => (isfull ? '50vh' : '80%')};
  aspect-ratio: 1;
  object-fit: cover;
  box-shadow: ${SHADOWS.mid};
  cursor: pointer;
  border-radius: 4px;
  z-index: 1;
`;
export const FullImageContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const TryAgain = styled.button`
  margin-top: 1rem;
  height: 2rem;
  width: 10rem;
  border-radius: 4px;
  background-color: ${COLORS.primary};
  color: ${COLORS.background_d};
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.primary_d};
  }
`;
export const ChipContainer = styled.div`
  height: 2.5rem;
  font-size: 0.8rem;
  padding-block: 0.5rem;
  width: 10rem;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.1rem;
  background-color: ${COLORS.background_l};
  box-shadow: ${SHADOWS.b_mid};
  color: ${COLORS.black};
  @media (${BREAK_POINTS.m}) {
    flex-direction: row;
    width: 8rem;
    height: 4rem;
    padding-inline: 0.6rem;
    gap: 0.5rem;
    justify-content: space-between;
  }
`;
export const ChipName = styled.span``;
export const ChipValue = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
export const IconContainer = styled.div`
  height: 1rem;
  width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Icon = styled.img`
  height: 80%;
`;
export const ChipsContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  font-size: 0.8rem;
  padding-block: 0.5rem;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  @media (${BREAK_POINTS.m}) {
    flex-direction: column;
    right: 0.5rem;
  }
`;
export const Label = styled.label`
  width: 100%;

  margin-bottom: -0.75rem;
  margin-top: -0.5rem;
`;
export const FormFooter = styled.footer`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ErrorText = styled.p`
  margin-right: auto;
  margin-top: -0.9rem;
  font-size: 12px;
  color: ${COLORS.error_d};
`;
