import styled from 'styled-components'
import { COLORS } from '../../../../assets/styles/theme'
import { motion } from 'framer-motion'

const bottomArrowBorders = (col) => `
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-bottom: 0.5rem solid ${col};
`
const topArrowBorders = (col) => `
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 0.5rem solid ${col};
`
const rightArrowBorders = (col) => `
  border-top: 0.5rem solid transparent;
  border-bottom: 0.5rem solid transparent;
  border-right: 0.5rem solid ${col};
`
const leftArrowBorders = (col) => `
  border-top: 0.5rem solid transparent;
  border-bottom: 0.5rem solid transparent;
  border-left: 0.5rem solid ${col};
`

export const Container = styled(motion.div)`
  position: absolute;
  bottom: ${({ bot }) => (bot ? bot : '-2rem')};
  left: ${({ left }) => (left ? left : '4rem')};
  display: ${({ hastext }) => (hastext ? 'flex' : 'none')};
  flex-direction: ${({ dir }) =>
    dir === 'bottom'
      ? 'column'
      : dir === 'top'
      ? 'column-reverse'
      : dir === 'right'
      ? 'row'
      : 'row-reverse'};
  align-items: center;
  z-index: 10;
`

export const Arrow = styled.div`
  width: 0;
  height: 0;
  ${({ dir, bg }) =>
    dir === 'bottom'
      ? bottomArrowBorders(bg)
      : dir === 'top'
      ? topArrowBorders(bg)
      : dir === 'right'
      ? rightArrowBorders(bg)
      : leftArrowBorders(bg)}
`

export const Content = styled.div`
  min-height: 1.6rem;
  font-size: 0.8rem;
  min-width: ${({ wid }) => (wid ? wid : '6rem')};
  padding: 0.2rem 1rem;
  background-color: ${({ bg }) => (bg ? bg : COLORS.gray_d)};
  color: ${COLORS.background_d};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  opacity: 0.99;
  transition: all 0.3s ease;
`
