import {
  abroadCitizenPlateRegex,
  diplomaticPlateRegex,
  fullPlateRegex,
  militaryPlateRegex,
  nonNumberRegex,
  plateRegex,
} from '../../data/resources/regex';

export const validatePlateField = (e) => {
  const { value } = e.target;

  return value.toUpperCase().replace(/ /g, '').trim();
};

export const validateMarketBonus = (e) => {
  const { value } = e.target;
  return value.replace(nonNumberRegex, '').trim();
};

export const validatePlate = (plate, cars) => {
  plate = plate.trim().toUpperCase();

  if (cars.find((car) => car.plate === plate)) return [false, 7005];
  return [true];
};

function addStringAtIndexes(str, indexes, addStr = '-') {
  let result = str;
  for (let i = indexes.length - 1; i >= 0; i--) {
    const index = indexes[i];
    if (index >= 0 && index <= str.length) {
      result = result.slice(0, index) + addStr + result.slice(index);
    }
  }
  return result;
}

export const formatPlate = (plate) => {
  if (plate.match(plateRegex)) {
    if (plate.length === 6) {
      return addStringAtIndexes(plate, [2, 3]);
    }
    return addStringAtIndexes(plate, [2, 4]);
  }

  if (plate.match(abroadCitizenPlateRegex)) {
    return addStringAtIndexes(plate, [1, 4]);
  }

  if (plate.match(diplomaticPlateRegex)) {
    if (plate.length === 7) {
      return addStringAtIndexes(plate, [3, 4]);
    }
    return addStringAtIndexes(plate, [3, 5]);
  }

  if (plate.match(militaryPlateRegex)) {
    return addStringAtIndexes(plate, [1, 4]);
  }

  return plate;
};

export const formatBonusCard = (str) => {
  const formattedStr = str.replace(/(.{4})(.{4})(.{4})(.{4})/, '$1-$2-$3-$4');
  return formattedStr;
};
