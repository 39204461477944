import React, { useEffect, useState } from 'react';
import {
  Container,
  Wrapper,
  Logo,
  NavList,
  AuthList,
  Item,
  LogoContainer,
  MenuList,
  MenuItem,
  MenuBtn,
  MAuthList,
  Icon,
  NavbarLangContainer,
} from './style';
import { attaLogoSvg } from '../../../../assets/images';
import { useTranslation } from 'react-i18next';
import { navList } from '../../../../data/resources/lists';
import { AnimatePresence } from 'framer-motion';
import BackDrop from '../backdrop';
import { UserSection } from '../../../components';
import { useAuth } from '../../../../utils/hooks/useAuth';
import { isMobile } from 'react-device-detect';
import Annoc from './Annoc';
import NavbarLang from '../../features/lang-menu/NavbarLang';
import { useLocation, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const { t } = useTranslation();

  const { user } = useAuth();

  const [showAnnoc, setShowAnnoc] = useState(true);
  const [showBg, setShowBg] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 50) {
        setShowBg(true);
      } else {
        setShowBg(false);
      }
    });
  }, []);

  useEffect(() => {
    const hasAnnoc = localStorage.getItem('tezode-annoc');

    if (!hasAnnoc) {
      localStorage.setItem('tezode-annoc', 'not-closed');
      setShowAnnoc(true);
    } else if (hasAnnoc === 'closed') {
      setShowAnnoc(false);
    } else {
      setShowAnnoc(true);
    }
  }, []);

  const handleGo = (pos) => {
    if (pathname === '/partners') {
      window.location.href = pos;
      navigate('/' + pos);
    } else {
      window.location.href = pos;
    }
  };

  const handleAnnocClose = () => {
    localStorage.setItem('tezode-annoc', 'closed');
    setShowAnnoc(false);
  };

  return (
    <Container>
      {showAnnoc && <Annoc close={handleAnnocClose} />}
      <Wrapper activebg={showBg ? 'true' : 'false'}>
        <LogoContainer onClick={() => navigate('/')}>
          <Logo src={attaLogoSvg} alt='Logo' />
        </LogoContainer>
        <NavList>
          {navList.map((n, i) => (
            <Item key={i} onClick={() => handleGo(n.link)}>
              {t(n.name)}
            </Item>
          ))}
        </NavList>
        <NavbarLangContainer>
          <NavbarLang />
        </NavbarLangContainer>
        {!user?.accessToken ? null : (
          <AuthList>
            <UserSection isLanding={true} />
          </AuthList>
        )}
        <MenuBtn>
          <Icon onClick={() => setIsMenuOpen(true)} />
        </MenuBtn>
        <AnimatePresence>
          {isMenuOpen && (
            <BackDrop onClick={() => setIsMenuOpen(false)}>
              <MenuList
                ismobile={+isMobile}
                initial={{ x: 300, opacity: 0 }}
                transition={{ type: 'tween' }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 300, opacity: 0 }}
              >
                {navList.map((n, i) => (
                  <MenuItem key={i} onClick={() => handleGo(n.link)}>
                    {t(n.name)}
                  </MenuItem>
                ))}
                {!user?.accessToken ? null : (
                  <MAuthList>
                    <UserSection isMenu={true} />
                  </MAuthList>
                )}
              </MenuList>
            </BackDrop>
          )}
        </AnimatePresence>
      </Wrapper>
    </Container>
  );
};

export default Navbar;
