import { motion } from 'framer-motion'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import styled from 'styled-components'
import { BREAK_POINTS, COLORS, SHADOWS } from '../../../../assets/styles/theme'

export const Container = styled.section`
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: 5rem;

  @media (${BREAK_POINTS.m}) {
    gap: 2rem;
  }

  align-items: center;

  margin-top: 6rem;
`
export const Background = styled(LazyLoadImage)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  object-fit: cover;
  z-index: -100;
`
export const Deco = styled.img`
  z-index: -99;
  position: absolute;
  left: 0;
  bottom: -0.5rem;
  width: 100vw;
`

export const Wave = styled.img`
  position: absolute;
  bottom: 0;
  z-index: -1;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
  @media (${BREAK_POINTS.l}) {
    width: 85%;
  }
  @media (${BREAK_POINTS.m}) {
    width: 95%;
  }
`
export const Content = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: ${COLORS.black};
  @media (${BREAK_POINTS.l}) {
    width: 80%;
    align-items: center;
  }
`
export const ImgContainer = styled.div``
export const Img = styled(LazyLoadImage)`
  @media (${BREAK_POINTS.l}) {
    height: 15rem;
  }
  @media (${BREAK_POINTS.m}) {
    height: 12.5rem;
  }
  @media (${BREAK_POINTS.s}) {
    height: 10rem;
  }
`
export const Title = styled.h1`
  font-size: 2rem;
  text-align: center;
  @media (${BREAK_POINTS.l}) {
    font-size: 1.9rem;
  }
  @media (${BREAK_POINTS.m}) {
    font-size: 1.8rem;
  }
`
export const Subtitle = styled.h3`
  text-align: center;
  @media (${BREAK_POINTS.l}) {
    font-size: 0.85rem;
  }
  @media (${BREAK_POINTS.m}) {
    font-size: 0.7rem;
  }
`
export const Btn = styled(motion.button)`
  height: 3rem;
  width: 10rem;
  border-radius: 5px;

  background-color: ${COLORS.primary_l};
  color: ${COLORS.background_d};

  &:hover {
    background-color: ${COLORS.primary_d};
  }
`
export const Tabs = styled.div`
  display: flex;
  width: 60%;
  @media (${BREAK_POINTS.l}) {
    width: 100%;
  }
`
export const Tab = styled.div`
  height: 4rem;
  ${({ isAuth }) => (!isAuth ? 'flex: 1;' : 'width: 20rem;')}

  cursor: pointer;
  transition: all 0.3s ease;

  background-color: ${({ isSelected }) => (isSelected ? COLORS.accent : COLORS.background_l)};
  color: ${({ isSelected }) => (isSelected ? COLORS.background_l : COLORS.accent_d)};

  &:hover {
    background-color: ${({ isSelected }) => (isSelected ? COLORS.accent_l : COLORS.background_d)};
  }

  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    border-radius: 20px 0 0 0;
  }
  &:last-child {
    border-radius: 0 20px 0 0;
  }
`
export const Body = styled.div`
  width: 100%;
  box-shadow: ${SHADOWS.low};
`
export const RegisterForm = styled.form``
export const LoginForm = styled.form``
export const PartnerForm = styled.form``
export const ErrorButton = styled.button`
  margin-left: auto;
  margin-right: 1rem;
  background-color: ${COLORS.error_l};
  color: ${COLORS.error_d};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
export const SuccessButton = styled.button`
  margin-left: auto;
  margin-right: 1rem;
  background-color: ${COLORS.primary_ll};
  color: ${COLORS.primary_d};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
