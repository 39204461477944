import { emailRegex } from '../../data/resources/regex'

export const getIsRecoveryBtnDisabled = (type, data) => {
  if (!type) return [true, 'error_select_recovery_type']
  if (!data[type]) return [true, `error_recovery_${type}_empty`]
  if (type === 'mobileNumber' && data.mobileNumber.length !== 9)
    return [true, 'error_msg_mobile_phone']
  if (type === 'email' && !data.email.match(emailRegex)) return [true, 'error_msg_email_valid']
  return [false]
}
