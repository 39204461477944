import { useQuery } from 'react-query';
import { getServiceId } from '../../services/api/commonApi';

/**
 * Name can be like this:
 * asan, Asan, ASAN, asan_service_id, asan_service, ASAN_SERVICE etc.
 *
 * @param {string} name
 * @returns {string}
 */
const convertServiceName = (name) => {
  name = name.toUpperCase();

  if (name.endsWith('ID')) {
    return name;
  }

  if (name.includes('_')) {
    return name + '_ID';
  }

  return name + '_SERVICE_ID';
};

/**
 *
 * @param {string} [serviceName]
 * @param {boolean} str
 */
export const useServiceId = () => {
  const { data, isLoading, isFetching, isError } = useQuery('constants', getServiceId, {
    retry: false,
  });

  const result = data?.data ? data.data : null;

  return { result, isLoading, isFetching, isError };
};
