import { motion } from 'framer-motion'
import styled from 'styled-components'
import { BREAK_POINTS, COLORS, SHADOWS } from '../../../../assets/styles/theme'

export const Container = styled.form`
  padding: 1rem;
  padding-bottom: 2.5rem;
  background-color: ${COLORS.background_l};

  border-radius: 0 10px 10px 10px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media (${BREAK_POINTS.l}) {
    border-radius: 0 0 10px 10px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (${BREAK_POINTS.m}) {
    grid-template-columns: 1fr;
  }
`
export const Field = styled.div`
  position: relative;
`
export const Cell = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  justify-content: end;
`

export const Button = styled(motion.button)`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '2.75rem'};

  border-radius: ${(props) => props.radius || '15px'};
  background-color: ${(props) => props.bg || COLORS.accent};
  color: ${COLORS.background};
  font-weight: bold;

  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => props.bgh || COLORS.accent_l};
  }

  ${({ isdisabled }) =>
    isdisabled === 'true' &&
    `
    cursor: default;
    background-color: ${COLORS.gray_l};
    color: ${COLORS.gray_d};
    pointer-event: none;

    &:hover {
        background-color: ${COLORS.gray_l};
      }
  `}

  @media (${BREAK_POINTS.m}) {
    margin-top: ${(props) => props.margin || '1rem'};
  }
`
export const Label = styled.label``
export const ForgetPassword = styled.span`
  position: absolute;
  top: 105%;
  right: 0;

  color: ${COLORS.accent};
  font-style: italic;

  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    text-decoration: underline;
    color: ${COLORS.accent_d};
  }
`
export const LoginPopup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 22rem;
  min-width: 30rem;
  border-radius: 4px;
  box-shadow: ${SHADOWS.low};
  padding: 2rem 1rem;
  background-color: ${COLORS.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
`
export const PPCell = styled(Cell)`
  width: 90%;
`
export const PPTitle = styled.h3`
  text-align: center;
`
export const ButtonCell = styled(Cell)`
  @media (${BREAK_POINTS.l_min}) {
    grid-column-start: 3;
  }
`
export const BoxCell = styled(Cell)`
  @media (${BREAK_POINTS.l_min}) {
    grid-column: 1/2;
  }
  @media (${BREAK_POINTS.m_min}) {
    grid-column: 1/3;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
`
export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  cursor: pointer;
`
export const Text = styled.p``
export const PDFLink = styled.a`
  margin-left: 0.2rem;
  position: relative;
  font-size: ${(props) => (props.profile ? '0.8rem' : '1rem')};
  color: ${(props) => (props.profile ? COLORS.gray_d : 'black')};
  &:before {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    bottom: 0;
    background-color: ${COLORS.gray_d};
    transition: all 0.2s ease;
    transform: scale(0);
    z-index: 2;
  }
  &:hover {
    color: ${COLORS.text_l};
    &:before {
      transform: scale(1);
      background-color: ${COLORS.text_l};
    }
  }
`
