import { motion } from 'framer-motion'
import styled from 'styled-components'
import { BREAK_POINTS, COLORS, SHADOWS } from '../../../../assets/styles/theme'
import { Input } from '../../features/login-wrapper/style'

export const Container = styled(motion.div)`
  position: absolute;
  left: 0;
  bottom: 100%;

  z-index: 100;
  min-height: 20rem;
  width: 30rem;
  border-radius: 4px;
  box-shadow: ${SHADOWS.mid};
  background-color: ${COLORS.background};

  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  align-items: center;
  justify-content: space-around;

  @media (${BREAK_POINTS.m}) {
    width: 95%;
  }
`
export const Title = styled.h3``
export const Desc = styled.span`
  text-align: center;
  font-size: 1rem;
  @media (${BREAK_POINTS.m}) {
    font-size: 0.8rem;
  }
`
export const Field = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (${BREAK_POINTS.m}) {
    gap: 1rem;
  }
`
export const Name = styled.span`
  font-style: italic;
  @media (${BREAK_POINTS.m}) {
    font-size: 0.7rem;
    width: 45%;
  }
`
export const InputField = styled(Input)`
  width: 50%;
  @media (${BREAK_POINTS.m}) {
    width: 55%;
    height: 2rem;
  }
`
