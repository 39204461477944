import { emailRegex } from '../../data/resources/regex'

export const formatName = (value) => {
  const noNumberValue = value.replace(/[^a-zA-Z, ]/g, '')
  const words = noNumberValue.split(' ')
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i] ? words[i][0].toUpperCase() + words[i].substr(1) : ''
  }
  return words.join(' ')
}

export const formatEmail = (value) => {
  const allLowerCase = value.toLowerCase()
  return allLowerCase.replace(/ /g, '')
}

export const validateEmail = (value) => {
  if (value.match(emailRegex)) {
    return false
  }

  return true
}
