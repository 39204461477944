export const steps = {
  dashboard: [
    {
      target: '.intro_dashboard_1',
      content: 'intro_dashboard_1',
    },
    {
      target: '.intro_dashboard_2',
      content: 'intro_dashboard_2',
    },
  ],
  cards_initial: [
    {
      target: '.intro_cards_initial_1',
      content: 'intro_cards_initial_1',
    },
  ],
  cards: [],
  cards_services: [],
  transactions: [],
  profile: [
    {
      target: '.intro_profile_1',
      content: 'intro_profile_1',
      placement: 'top-start',
    },
    {
      target: '.intro_profile_2',
      content: 'intro_profile_2',
    },
  ],
};
