import React, { useState } from 'react'
import { Container, MenuBtn, LogoContainer } from './style'
import { Logo } from '../sidebar/style'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import { attaLogoSmallPng } from '../../../../assets/images'
import { AnimatePresence } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { BackDrop, MenuList } from '../../../components'
import { UserSection } from './../../../components'

const Topbar = () => {
  const navigate = useNavigate()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <AnimatePresence>
      <Container>
        <MenuBtn onClick={() => setIsMenuOpen(true)}>
          <MenuRoundedIcon />
        </MenuBtn>
        <LogoContainer>
          <Logo onClick={() => navigate('/')} src={attaLogoSmallPng} alt='Azericard Logo' />
        </LogoContainer>
        <UserSection />
        <AnimatePresence>
          {isMenuOpen && (
            <BackDrop onClick={() => setIsMenuOpen(false)}>
              <MenuList setIsMenuOpen={setIsMenuOpen} />
            </BackDrop>
          )}
        </AnimatePresence>
      </Container>
    </AnimatePresence>
  )
}

export default Topbar
