import { useEffect } from 'react'
import { useState } from 'react'
import {
  initialPasswordValidation,
  initialRegisterFormData
} from '../../data/resources/initialStates'
import { emailRegex, mobNumRegex, pinRegex } from '../../data/resources/regex'
import { formatFields } from '../helpers/formatFields'
import { setSession } from '../helpers/session'
import { validatePassword } from '../helpers/validatePassword'

const sessionFields = ['firstName', 'lastName', 'email', 'pin', 'mobileNumber', 'middleName']

export const useRegister = () => {
  const [formData, setFormData] = useState(initialRegisterFormData)
  const [isLicenseAccepted, setIsLicenseAccepted] = useState(false)
  const [isBtnDisabled, setIsBtnDisabled] = useState({
    value: true,
    msg: 'fill_the_gaps'
  })
  const [passwordValidation, setPasswordValidation] = useState(initialPasswordValidation)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const getIsBtnDisabled = () => {
    if (
      !formData.email ||
      !formData.password ||
      !formData.firstName ||
      !formData.lastName ||
      !formData.pin ||
      !formData.mobileNumber ||
      !formData.confirmPassword ||
      !formData.middleName
    ) {
      setIsBtnDisabled({ value: true, msg: 'fill_the_gaps' })
    } else if (formData.firstName.length > 0 && formData.firstName.length < 3) {
      setIsBtnDisabled({ value: true, msg: 'invalid_first_name' })
    } else if (formData.lastName.length > 0 && formData.lastName.length < 3) {
      setIsBtnDisabled({ value: true, msg: 'invalid_last_name' })
    } else if (formData.middleName.length > 0 && formData.middleName.length < 3) {
      setIsBtnDisabled({ value: true, msg: 'invalid_paternal_name' })
    } else if (!formData.email.match(emailRegex)) {
      setIsBtnDisabled({ value: true, msg: 'error_msg_email_valid' })
    } else if (formData.pin.length < 5) {
      setIsBtnDisabled({ value: true, msg: 'error_msg_pin_min' })
    } else if (formData.pin.length > 7) {
      setIsBtnDisabled({ value: true, msg: 'error_msg_pin_max' })
    } else if (!formData.pin.match(pinRegex)) {
      setIsBtnDisabled({ value: true, msg: 'error_pin_regex' })
    } else if (formData.mobileNumber.length < 9 || !formData.mobileNumber.match(mobNumRegex)) {
      setIsBtnDisabled({ value: true, msg: 'error_msg_mobile_phone' })
    } else if (Object.values(validatePassword(formData.password)).some((i) => !i)) {
      setIsBtnDisabled({ value: true, msg: 'error_msg_password' })
    } else if (formData.confirmPassword !== formData.password) {
      setIsBtnDisabled({ value: true, msg: 'error_msg_confirm_password' })
    } else if (!isLicenseAccepted) {
      setIsBtnDisabled({ value: true, msg: 'error_msg_licese_not_accepted' })
    } else {
      setIsBtnDisabled({ value: false })
    }
  }

  const handleOnChange = (e) => {
    const { name } = e.target
    if (name === 'password') {
      setPasswordValidation(validatePassword(formatFields(e)))
    }

    if (sessionFields.includes(name)) {
      setSession(`tezode-field-${name}`, formatFields(e))
    }

    setFormData((prev) => ({ ...prev, [name]: formatFields(e) }))
  }

  useEffect(() => {
    getIsBtnDisabled()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, isLicenseAccepted])

  return {
    formData,
    setFormData,
    handleOnChange,
    showPassword,
    setShowPassword,
    showConfirmPassword,
    setShowConfirmPassword,
    passwordValidation,
    isBtnDisabled,
    isLicenseAccepted,
    setIsLicenseAccepted
  }
}
