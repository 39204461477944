import React from 'react'
import { AddNew, Icon, Text } from './style'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import { useTranslation } from 'react-i18next'

const AddNewCard = ({ onClick, text }) => {
  const { t } = useTranslation()

  return (
    <AddNew onClick={onClick} className='intro_cards_initial_1'>
      <Icon>
        <AddCircleOutlineRoundedIcon />
      </Icon>
      <Text>{t(text)}</Text>
    </AddNew>
  )
}

export default AddNewCard
