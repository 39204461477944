import styled from 'styled-components';
import { BREAK_POINTS, COLORS, SHADOWS } from '../../../assets/styles/theme';
import { NAVBAR_HEIGHT } from '../../components/layout/navbar/style';

export const Container = styled.main`
  margin-top: ${NAVBAR_HEIGHT + 3}rem;
  margin-bottom: 4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 2rem;

  @media (${BREAK_POINTS.l}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (${BREAK_POINTS.m}) {
    grid-template-columns: 1fr;
  }
`;

export const Card = styled.section`
  display: flex;
  margin-inline: auto;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  box-shadow: ${SHADOWS.low};
  padding-inline: 1rem;
  padding-block: 2rem;
  width: 400px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.h2`
  text-align: center;
  color: ${COLORS.accent_l};
`;
export const Badge = styled.div``;
export const BadgeDot = styled.div``;
export const ImgContainer = styled.div`
  border-radius: 50%;
  height: 6rem;
  width: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Img = styled.img`
  height: 90%;
  width: 90%;
  object-fit: contain;
`;
export const Desc = styled.p`
  font-size: 14px;
  text-align: center;
  color: ${COLORS.gray_d};
`;
export const TextPanel = styled.div`
  padding: 0.5rem;
  border-radius: 6px;
  border: 1px solid ${COLORS.gray_l};
  font-size: 13px;
`;
export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${COLORS.gray_l};
`;
export const Label = styled.p`
  margin-bottom: -0.5rem;
`;
export const Value = styled.p``;
export const Button = styled.button``;
export const ErrorPanel = styled.div``;
