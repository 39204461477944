import { useContext } from 'react'
import AuthContext from '../../context/AuthProvider'

/**
 * @typedef ContextObject
 * @type {object}
 * @property {string} email - email of the logged in user
 * @property {string} pin - pin of the logged in user
 * @property {string} accessToken - access token of the logged in user
 */
/**
 * Custom Hook to prevent writing 'useContext(AuthContext)' everytime
 * @returns {ContextObject}
 */
export const useAuth = () => useContext(AuthContext)
