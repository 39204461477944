import { createContext, useContext } from 'react';
import { useServiceId } from '../utils/hooks/useServiceId';
import { Spinner } from '../assets/styles/style/styled';
import { attaLogoBigPng } from '../assets/images';
import { useAuth } from '../utils/hooks/useAuth';
import { useLocation } from 'react-router-dom';

const ServiceIdContext = createContext({});

export const ServiceIdProvider = ({ children }) => {
  const auth = useAuth();
  const { result, isLoading } = useServiceId();
  const { pathname } = useLocation();

  return (
    <ServiceIdContext.Provider value={result}>
      {isLoading && auth.user?.accessToken && pathname !== '/' ? (
        <main
          style={{
            position: 'fixed',
            top: '0px',
            left: '0px',
            width: '100vw',
            height: '100vh',
            zIndex: 30,
          }}
        >
          <div
            style={{
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <img src={attaLogoBigPng} alt='tezode loqo' style={{ height: '2rem' }} />
            <Spinner />
          </div>
        </main>
      ) : (
        children
      )}
    </ServiceIdContext.Provider>
  );
};

export const useServiceStore = () => useContext(ServiceIdContext);

export default ServiceIdContext;
