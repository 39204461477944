import {
  upperCaseRegex,
  lowerCaseRegex,
  numberRegex,
  specialCharRegex
} from '../../data/resources/regex'

export const validatePassword = (password) => {
  const validation = {
    isLenghtTrue: false,
    isNumberTrue: false,
    isSymbolTrue: false,
    isCapitalTrue: false,
    isLetterTrue: false,
    isEverythingTrue: false
  }

  if (password.length > 7) validation.isLenghtTrue = true
  if (password.match(upperCaseRegex)) validation.isCapitalTrue = true
  if (password.match(lowerCaseRegex)) validation.isLetterTrue = true
  if (password.match(numberRegex)) validation.isNumberTrue = true
  if (password.match(specialCharRegex)) validation.isSymbolTrue = true

  if (
    validation.isCapitalTrue &&
    validation.isLetterTrue &&
    validation.isNumberTrue &&
    validation.isLenghtTrue &&
    validation.isSymbolTrue
  ) {
    validation.isEverythingTrue = true
  } else validation.isEverythingTrue = false

  return validation
}
