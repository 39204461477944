import React from 'react'
import { LangMenu } from '../../components'
import { Container, Img, Footer, Redirect } from './style'

import { attaLogoSmallPng } from '../../../assets/images'
import { Outlet, useNavigate } from 'react-router-dom'
import { AZERICARD_URL } from '../../../data/resources/constants'

const AuthPage = () => {
  const navigate = useNavigate()
  const date = new Date()

  return (
    <Container>
      <LangMenu />
      <Img src={attaLogoSmallPng} alt='tezödə Logo' onClick={() => navigate('/')} />
      <Outlet />
      <Footer>
        Copyright 2008 - {date.getFullYear()} 3D-Secure payment services provided by
        <Redirect>
          <a href={AZERICARD_URL} target='_blank' rel='noreferrer noopener'>
            AzeriCard
          </a>
        </Redirect>
        .
      </Footer>
    </Container>
  )
}

export default AuthPage
