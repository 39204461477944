import React from 'react';
import { Container, Wrapper, Logo, Copyright } from './style';

import { attaLogoWhiteSvg } from '../../../../assets/images';
import Contact from '../contact';

const Footer = () => {
  const date = new Date();

  return (
    <Container>
      <Wrapper>
        <Logo src={attaLogoWhiteSvg} alt='Logo' />
        <Copyright>© tezödə {date.getFullYear()}</Copyright>
      </Wrapper>
      <Contact />
    </Container>
  );
};

export default Footer;
