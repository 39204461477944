import { BsFacebook, BsInstagram, BsTwitter } from 'react-icons/bs';
import styled from 'styled-components';
import { BREAK_POINTS, COLORS } from '../../../../assets/styles/theme';

export const Container = styled.footer`
  width: 100vw;
  background-image: linear-gradient(
    to right top,
    #2d56b4,
    #315ab9,
    #355ebd,
    #3862c2,
    #3c66c7,
    #3a64c6,
    #3862c4,
    #3660c3,
    #2d57bc,
    #244db4,
    #1944ad,
    #0c3ba5
  );
  position: relative;
  padding: 4rem 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5rem;
  @media (${BREAK_POINTS.m}) {
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
  }
`;
export const Background = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -20;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: ${({ notitle }) => (notitle ? '20rem' : '100%')};
  @media (${BREAK_POINTS.l}) {
    gap: 2rem;
    justify-content: center;
    height: 40%;
  }
`;
export const Logo = styled.img`
  height: 3rem;
`;
export const Copyright = styled.span`
  color: ${COLORS.background_d};
`;
export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: ${COLORS.background_d};
`;
export const ContactItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
export const Text = styled.span`
  ${({ isLink }) =>
    isLink &&
    `
    &:hover {
      color: ${COLORS.accent};
      background-color: ${COLORS.background};
    }
  `}
`;
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Social = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 2rem;
  font-size: 1.5rem;
  color: ${COLORS.background_d};
`;
export const ContactHeader = styled.h3``;
export const FacebookIcon = styled(BsFacebook)`
  cursor: pointer;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease;
  &:hover {
    color: #3088ff;
    transform: scale(1.1);
  }
`;
export const InstagramIcon = styled(BsInstagram)`
  cursor: pointer;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  border-radius: 7px;
  transition: all 0.2s ease;
  &:hover {
    background-image: linear-gradient(
      to right top,
      #f49f4f,
      #f98b4f,
      #fb7555,
      #f95f5e,
      #f4486b,
      #ee3678,
      #e42587,
      #d51a96,
      #c21ca8,
      #a728bb,
      #8135cc,
      #4142db
    );
    transform: scale(1.1);
  }
`;
export const TwitterIcon = styled(BsTwitter)`
  cursor: pointer;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease;
  &:hover {
    color: #1da1f1;
    transform: scale(1.1);
  }
`;
export const IconContainer = styled.a`
  height: 1.5rem;
  width: 1.5rem;
`;
export const XLogo = styled.img`
  cursor: pointer;
  width: 90%;
  transition: all 0.2s ease;
  height: 90%;

  &:hover {
    transform: scale(1.1);
  }
`;
