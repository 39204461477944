import styled from 'styled-components'
import { motion } from 'framer-motion'
import { BREAK_POINTS, COLORS, SHADOWS } from '../../../../assets/styles/theme'

export const Container = styled.main`
  display: flex;
  gap: 1rem;
  @media (${BREAK_POINTS.l}) {
    flex-direction: column;
  }
`
export const Section = styled.section`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (${BREAK_POINTS.l}) {
    width: 100%;
  }
`
export const Wrapper = styled.div`
  width: 100%;
  background-color: ${COLORS.background_l};
  box-shadow: ${SHADOWS.mid};
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.25rem;
`
export const Title = styled.h3``
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
export const Item = styled.label`
  cursor: ${({ isdisabled }) => (isdisabled ? 'default' : 'pointer')};
  transition: all 0.3s ease;
  height: ${({ hght }) => (hght ? hght : '3.5rem')};
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  background-color: ${({ isdisabled }) => (isdisabled ? COLORS.gray_l : COLORS.background_l)};
  border: 1px solid ${COLORS.gray_l};
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: ${({ isdisabled }) => (isdisabled ? COLORS.gray : COLORS.background)};
  }
`
export const ItemName = styled.span``
export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`
export const LabelText = styled.span``
export const Checkbox = styled.input`
  cursor: pointer;
`
export const ImgContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
`
export const Img = styled.img`
  height: 1.25rem;
`
export const AddNewItem = styled.div``
export const Part = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
  padding: 1rem;
`
export const ErrorWrapper = styled(motion.div)`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 100;
  @media (${BREAK_POINTS.m}) {
    right: 0;
    bottom: 0;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }
`
export const AddNew = styled.button`
  height: 3rem;
  width: 100%;
  border-radius: 3px;
  background-color: ${COLORS.primary};
  color: ${COLORS.background_l};
  &:hover {
    background-color: ${COLORS.primary_d};
  }
`
export const AddNewContainer = styled.div`
  height: 3rem;
  width: 100%;
  border-radius: 3px;
  border-radius: 3px;
  background-color: ${COLORS.background_l};
  border: 1px solid ${COLORS.gray_l};
  display: flex;
  padding: 0 1rem;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;
`
export const AddNewInput = styled.input`
  height: 100%;
  width: 70%;
  border: none;
  font-size: 0.9rem;
  &:focus {
    outline: none;
  }
`
export const AddNewBtn = styled.button`
  width: 15%;
  height: 70%;
  border-radius: 3px;
  background-color: ${({ iscancel }) => (iscancel ? COLORS.error : COLORS.primary)};
  color: ${COLORS.background_l};
  &:hover {
    background-color: ${({ iscancel }) => (iscancel ? COLORS.error_d : COLORS.primary_d)};
  }
`
export const DefaultBtn = styled.button`
  width: 50%;
  height: 2rem;
  border-radius: 4px;
  margin-inline: auto;
  background-color: ${COLORS.error_ll};
  border: 0.5px solid ${COLORS.error_d};
  color: ${COLORS.error_d};
  &:hover {
    background-color: ${COLORS.error_d};
    border: 0.5px solid ${COLORS.error_d};
    color: ${COLORS.background_d};
  }
  &:disabled {
    background-color: ${COLORS.gray_l};
    color: ${COLORS.gray_d};
    cursor: default;
    border: none;
  }
`
