import styled from 'styled-components'
import { BREAK_POINTS } from '../../../../assets/styles/theme'

export const Container = styled.main`
  width: 100vw;
  height: 40vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
  @media (${BREAK_POINTS.m}) {
    gap: 2rem;
  }
  @media (${BREAK_POINTS.l}) {
    padding: 0 1rem;
  }
`
export const Title = styled.h2``
export const Content = styled.span`
  width: 75%;
  @media (${BREAK_POINTS.l}) {
    width: 90%;
  }
  @media (${BREAK_POINTS.m}) {
    width: 100%;
  }
  text-align: center;
`
