import React, { useState } from 'react'
import {
  Container,
  Slide,
  Content,
  Title,
  Desc,
  Deco,
  Btn,
  Modal,
  ModalTitle,
  ModalDesc
} from './style'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, A11y, Pagination} from 'swiper'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

import { dots2, ellipse1, ellipse2 } from '../../../../assets/images'
import { productList } from '../../../../data/resources/lists'
import { Trans, useTranslation } from 'react-i18next'
import { useWindow } from '../../../../utils/hooks/useWindow'
import { MOBILE_BREAKPOINT } from '../../../../assets/styles/theme'
import { AnimatePresence } from 'framer-motion'
import BackDrop from '../../layout/backdrop'

const Products = () => {
  const { t } = useTranslation()
  const { width } = useWindow()

  const [modal, setModal] = useState(null)

  return (
    <Container>
      <Swiper
        spaceBetween={10}
        modules={[Navigation, A11y, Pagination]}
        navigation={MOBILE_BREAKPOINT > width ? false : true}
        slidesPerView={1}
        pagination={
          MOBILE_BREAKPOINT > width
            ? {
                clickable: true
              }
            : false
        }
      >
        {productList.map((p) => (
          <SwiperSlide key={p.key}>
            <Slide>
              {width > MOBILE_BREAKPOINT && (
                <>
                  <Deco src={ellipse1} pos={p.deco1} alt='Decoration' />
                  <Deco src={ellipse2} pos={p.deco2} alt='Decoration' />
                </>
              )}
              <Deco src={dots2} pos={p.deco3} alt='Decoration' />
              {p.order && p.img}
              <Content>
                <Title>{t(p.title)}</Title>
                <Trans>
                  <Desc>{width > MOBILE_BREAKPOINT ? t(p.desc) : t(p.short)}</Desc>
                </Trans>
                {width < MOBILE_BREAKPOINT && (
                  <Btn onClick={() => setModal(p)}>{t('learn_more')}</Btn>
                )}
              </Content>
              {!p.order && p.img}
            </Slide>
          </SwiperSlide>
        ))}
      </Swiper>
      <AnimatePresence>
        {modal && (
          <BackDrop onClick={() => setModal(null)}>
            <Modal initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              {modal.modalImg}
              <ModalTitle>{t(modal.title)}</ModalTitle>
              <Trans>
                <ModalDesc>{t(modal.desc)}</ModalDesc>
              </Trans>
              <Btn onClick={() => setModal(null)}>{t('close')}</Btn>
            </Modal>
          </BackDrop>
        )}
      </AnimatePresence>
    </Container>
  )
}

export default Products
