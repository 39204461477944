import React from 'react'
import { MOBILE_CODE } from '../../../../data/resources/constants'
import { validateMobileNumberPrefix } from '../../../../utils/helpers/validateMobileNumber'
import { NumberField, NumberInput, StaticBox } from '../register-wrapper/style'

const MobileField = ({ number, setNumber, ...rest }) => {
  return (
    <NumberField>
      <StaticBox>+{MOBILE_CODE}</StaticBox>
      <NumberInput
        format='## ### ## ##'
        placeholder={'50 123 45 67'}
        value={number}
        name='mobileNumber'
        onValueChange={(values) => {
          return setNumber((prev) => ({
            ...prev,
            mobileNumber: validateMobileNumberPrefix(values.value)
          }))
        }}
        {...rest}
      />
    </NumberField>
  )
}

export default MobileField
