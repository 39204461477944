import styled from 'styled-components'
import { COLORS, SHADOWS, BREAK_POINTS } from '../../../../assets/styles/theme'
import { motion } from 'framer-motion'

export const Container = styled.div`
  position: ${({ pos }) => (pos ? pos : 'absolute')};
  top: 1rem;
  right: 1rem;
  height: 2.5rem;
  width: 10rem;
  @media (${BREAK_POINTS.m}) {
    width: 6rem;
    font-size: 0.8rem;
    height: 2.25rem;
  }
  @media (${BREAK_POINTS.s}) {
    width: 5.5rem;
    font-size: 0.75rem;
    height: 2rem;
  }
  border-radius: 5px;
  cursor: pointer;
  box-shadow: ${SHADOWS.low};
  background-color: ${COLORS.background_l};
`
export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;

  align-items: center;
  font-size: 0.8rem;
`
export const Item = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`
export const Icon = styled.div`
  height: 100%;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ItemImg = styled.span``
export const ItemName = styled.span`
  font-size: ${(props) => (props.langLen > 10 ? '0.65rem' : '0.75rem')};
`
export const Menu = styled(motion.div)`
  font-size: 0.8rem;
  box-shadow: ${SHADOWS.low};
`
export const MenuItem = styled.div`
  height: 2.5rem;
  @media (${BREAK_POINTS.m}) {
    height: 2.25rem;
  }
  @media (${BREAK_POINTS.s}) {
    height: 2rem;
  }
  padding: 0.5rem;
  background-color: ${(props) => (props.isActive ? COLORS.primary_d : COLORS.primary)};
  color: ${COLORS.background};
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
  cursor: ${(props) => (props.isActive ? 'default' : 'cursor')};
  &:hover {
    background-color: ${COLORS.primary_l};
  }
  &:last-child {
    border-radius: 0 0 5px 5px;
  }
`
