export const setSession = (key, value, json = true) => {
  const result = json ? JSON.stringify(value) : value
  sessionStorage.setItem(key, result)
  return `${key}: ${result} >> added to the session`
}
export const getSession = (key, json = true) => {
  const value = sessionStorage.getItem(key)
  return json ? JSON.parse(value) : value
}
export const removeSession = (key) => {
  sessionStorage.removeItem(key)
  return `${key} >> removed from the session`
}
export const clearSession = () => {
  sessionStorage.clear()
  return `Session cleared`
}
