import React, { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useMutation } from 'react-query';
import { Outlet, useNavigate } from 'react-router-dom';
import { LOGOUT } from '../../../../data/resources/actionTypes';
import { signOut } from '../../../../services/api/authApi';
import { useAuth } from '../../../../utils/hooks/useAuth';
import { useLogger } from '../../../../utils/hooks/useLogger';
import { Sidebar, Topbar } from '../../../components';
import { Container } from './style';

const DashboardLayout = () => {
  const navigate = useNavigate();

  const {
    dispatch,
    user: { accessToken, pin },
  } = useAuth();
  const logger = useLogger();

  const { mutate } = useMutation(signOut, {
    onError: (err) => console.error(err),
    onSuccess: () => {
      dispatch({ type: LOGOUT });
      logger('User signed out (Because of IDLE timer)', { userPin: pin });
      navigate('/');
    },
  });

  function handleOnIdle() {
    mutate(accessToken);
  }

  useIdleTimer({
    onIdle: handleOnIdle,
    timeout: 1000 * 60 * 15,
  });

  const [activePage, setActivePage] = useState('services');
  return (
    <Container>
      <Topbar />
      <Sidebar activePage={activePage} setActivePage={setActivePage} />
      <Outlet />
    </Container>
  );
};

export default DashboardLayout;
