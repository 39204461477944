export const makeDate = (str) => {
  // eslint-disable-next-line no-unused-vars
  const [_, dd, mm, yyyy, hh, min, ss] = str.match(
    /(\d{2}).(\d{2}).(\d{4}) (\d{2}):(\d{2}):(\d{2})/
  )

  return new Date(yyyy, mm - 1, dd, hh, min, ss)
}

export const compareStrings = (a, b, asc) => {
  if (a < b) {
    return asc ? -1 : 1
  }
  if (a > b) {
    return asc ? 1 : -1
  } else return 0
}

export const compareNumbers = (a, b, asc) => {
  return asc ? a - b : b - a
}
