import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { menuList } from '../../../../data/resources/lists'
import { Container, MenuItem, ItemName } from './style'

const MenuList = ({ setIsMenuOpen }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const url = useLocation()

  return (
    <Container
      initial={{ x: 200 }}
      transition={{ type: 'tween' }}
      animate={{ x: 0 }}
      exit={{ x: 200, opacity: 0 }}
      onClick={(e) => e.stopPropagation()}
    >
      {menuList.map((item) => (
        <MenuItem
          isactivePage={url.pathname === item.link}
          onClick={() => {
            setIsMenuOpen(false)
            navigate(item.link)
          }}
          key={item.id + item.name}
        >
          {item.icon()}
          <ItemName>{t(item.name)}</ItemName>
        </MenuItem>
      ))}
    </Container>
  )
}

export default MenuList
