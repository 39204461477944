import React from 'react'
import { CatName, Service, Wrapper } from './style'

const Categories = ({ handleCategoryClick, cats }) => {
  return (
    <Wrapper>
      {cats.map((cat) => (
        <Service key={cat} onClick={() => handleCategoryClick(cat)}>
          <CatName>{cat}</CatName>
        </Service>
      ))}
    </Wrapper>
  )
}

export default Categories
