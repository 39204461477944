import React from 'react';
import { Container } from './style';

const BackDrop = ({ children, onClick }) => {
  return (
    <Container
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </Container>
  );
};

export default BackDrop;
