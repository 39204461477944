export const EXP = 86400000;

export const PATHS = {
  landing: '/',
  partners: 'partners',
  auth: '/auth',
  register: 'register',
  recovery: 'recovery',
  dashboard: '/dashboard',
  cards: 'cards',
  history: 'history',
  profile: 'profile',
  otp: 'otp',
  historyTest: 'historytest',
  notfound: '*',
};

export const LOADPATH = '/assets/locales/{{lng}}/translation.json';
export const BASE = process.env.REACT_APP_API_BASE;

export const URLS = {
  base: BASE + '/onboard',
  commonBase: BASE + '/onboard/common',
  mail_send: '/mail/send',
  api: '/api',
  card_base: '/cards',
  merchant_base: '/merchants',
  auth: '/auth',
  auth_signup: '/onboard/signup',
  auth_signup_confirm: '/onboard/signup/confirm',
  auth_edit: '/edit/email',
  auth_signin: '/signin',
  auth_signin_new: '/signin-new',
  auth_signout: '/signout',
  auth_check_ref: '/referral',
  auth_pwd_check: '/onboard/pass-recovery/check',
  auth_pwd_confirm: '/onboard/pass-recovery/otp-confirm',
  auth_pwd_update: '/onboard/pass-recovery/update',
  card_get_connected_payments: '/get-connected-payments/by-cardId/', // + id
  card_get_connected_byemail: '/get-connected-payments/', // + email
  card_deactivate: '/deactivate/by-cardId/', // + id
  card_get_cards_byemail: '/', // + email
  card_delete_service: '/connect/cancel/by-xref/', // + xref
  card_add_card: '/', // params: email
  card_connect_service: '/connect',
  card_connect_bulk: '/connect-bulk',
  card_connect_bulk_org: '/connect-bulk-organization',
  card_get_bulk_status: '/get-bulk-status/', // + uuid
  card_change_status: '/duplicate/changeStatus/', // + id
  merchant_get: '/all',
  user: '/user',
  user_get_info: '/', // + email
  user_get_bonus: '/onboard/refer-current-cnt/', // + referralCode
  user_get_count: '/onboard/referral-cnt/', // + pin,
  user_get_transactions: '/transactions-by-fin/page/0/element/100000', // + pin + user_get_transactions_2
  // user_get_transactions_1: '/transactions-by-fin/', // + pin + user_get_transactions_2
  // user_get_transactions_2: '/page/0/element/100000',
  user_edit_user: '/edit/onboard',
  user_add_image: '/edit/profile-picture',
  user_delete_image: '/delete/profile-picture/', // + email
  user_send_otp: '/edit/onboard/confirm',
  log: '/log',
};

export const requestHeaders = {
  'Content-Type': 'application/json',
  Accept: '*/*',
};

export const HOMEPAGE = '';
export const FALLBACKLANG = 'az';

export const STORAGE_KEYS = {
  USER: 'user',
  POPUP: 'tezode-popup',
  THEME: 'tezode-theme',
};

export const STORAGE_VALUES = {
  SEEN: 'have-seen',
  NOTSEEN: 'have-not-seen',
};

export const SOURCE = 'Onboard';
export const MOBILE_CODE = '994';
export const ROLE_USER = 'ROLE_USER';

export const TRANSACTION_INITIAL = 'all';

export const CARD_TYPE = {
  VISA: 'VISA',
  MASTERCARD: 'MasterCard',
  DISCOVER: 'DISCOVER',
  AMERICANEXPRESS: 'AMERICANEXPRESS',
  MAESTRO: 'MAESTRO',
};

export const AZERICARD_URL = 'https://www.azericard.com/';
export const AZERICARD_NUMBERS = ['+99412 598 43 76', '+99412 598 46 97', '*0001'];
export const AZERICARD_EMAIL = 'tezodesupport@azericard.com';

export const AZERICARD_MAP_LINK =
  'https://www.google.com/maps/place/Azericard/@40.3788582,49.8425409,19.83z/data=!4m5!3m4!1s0x4030862ec79a71dd:0xda3e298e0fabde3c!8m2!3d40.3788492!4d49.8427231';

export const EMAIL_TO =
  process.env.NODE_ENV === 'development'
    ? ['Mehdi.Asadli@azericard.com', 'Fahmin.Hajiyev@azericard.com']
    : [process.env.REACT_APP_SUPPORT_EMAIL_1, process.env.REACT_APP_SUPPORT_EMAIL_2];
export const MAIL_SUBJECT = 'Bizimlə Əlaqə Soruğusu';
export const MAIL_PARTNER_SUBJECT = 'Partnyor Ol Soruğusu';
