import styled from 'styled-components'
import { motion } from 'framer-motion'
import { BREAK_POINTS, COLORS, SHADOWS } from '../../../assets/styles/theme'
import { Input } from '../../components/features/login-wrapper/style'
import { SIDEBAR_WIDTH } from '../../components/layout/sidebar/style'

export const Container = styled.main`
  margin-left: ${SIDEBAR_WIDTH};
  width: calc(100% - ${SIDEBAR_WIDTH});
  @media (${BREAK_POINTS.m}) {
    margin-left: 0;
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
`
export const Title = styled.h2``
export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
  @media (${BREAK_POINTS.l}) {
    font-size: 0.7rem;
  }
  @media (${BREAK_POINTS.m}) {
    font-size: 0.9rem;
  }
  box-shadow: ${SHADOWS.mid};
`
export const Header = styled.thead``
export const Body = styled.tbody`
  background-color: ${COLORS.background};
`
export const HeadRow = styled.tr``
export const HeadCell = styled.th`
  height: 3rem;
  background-color: ${({ isSelected }) => (isSelected ? COLORS.primary_d : COLORS.primary)};
  color: ${COLORS.background_d};
  transition: all 0.3s ease;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? COLORS.primary_d + 'e1' : COLORS.primary + 'e1'};
  }
  &:first-child {
    border-radius: 5px 0 0 0;
    width: 15%;
  }
  &:nth-child(2) {
    width: 15%;
  }
  &:nth-child(3) {
    width: 10%;
  }
  &:nth-child(4) {
    width: 40%;
  }
  &:nth-child(5) {
    width: 10%;
  }
  &:last-child {
    border-radius: 0 5px 0 0;
    width: 10%;
  }
  @media (${BREAK_POINTS.m}) {
    &:nth-child(2) {
      display: none;
    }
    &:nth-child(3) {
      display: none;
    }
    &:nth-child(5) {
      display: none;
    }
    &:nth-child(4) {
      border-radius: 0 5px 0 0;
    }
    &:last-child {
      display: none;
    }
  }
`
export const Arrow = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.background_d};
`
export const Row = styled.tr`
  height: 3.5rem;
  &:nth-child(2n) {
    background-color: ${COLORS.background_l};
  }
  &:nth-child(2n + 1) {
    background-color: ${COLORS.background};
  }
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    transform: scale(1.01);
  }
`
export const Cell = styled.td`
  padding: 0.5rem 1rem;
  text-align: center;
  position: relative;
  &.table-body-cell-name {
    text-align: left;
  }
  @media (${BREAK_POINTS.m}) {
    &:nth-child(2) {
      display: none;
    }
    &:nth-child(3) {
      display: none;
    }
    &:nth-child(5) {
      display: none;
    }
    &:nth-child(4) {
      border-radius: 0 5px 0 0;
    }
    &:last-child {
      display: none;
    }
  }
`
export const Line = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 0.2rem;
  background-color: ${({ status }) =>
    !status || status === 'null'
      ? 'none'
      : status === 'SUCCESS'
      ? COLORS.primary_l
      : status === 'WAIT_FOR_OTP'
      ? COLORS.warning
      : status === 'CARD_NOT_FOUND'
      ? COLORS.black
      : status === 'FAILED' ||
        status === 'PAYMENT_NOT_UNIQUE' ||
        status === 'AMOUNT_BLOCKED' ||
        status === 'OTP_EXPIRED' ||
        status === 'TRANSACTION_ERROR'
      ? COLORS.error
      : COLORS.gray};
`
export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ isflexend }) => (isflexend ? 'flex-end' : 'space-between')};
  padding: 0 1rem;
  @media (${BREAK_POINTS.l}) {
    font-size: 0.7rem;
  }
  @media (${BREAK_POINTS.m}) {
    padding: 0;
    flex-direction: column;
    gap: 0.5rem;
  }
`
export const RowLimit = styled.div``
export const ResultNumber = styled.div``
export const Search = styled.div``
export const Select = styled.select`
  cursor: pointer;
  height: 2rem;
  width: 5rem;
  margin: 0 0.5rem;
  border-radius: 3px;
  border: 1px solid ${COLORS.primary};
  &:focus {
    outline: none;
    border: 2px solid ${COLORS.primary};
  }
  @media (${BREAK_POINTS.m}) {
    width: 3rem;
  }
`
export const Option = styled.option``
export const SearchInput = styled(Input)`
  height: 2.5rem;
  width: 20rem;
`
export const WrapperFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  @media (${BREAK_POINTS.l}) {
    font-size: 0.8rem;
  }
  @media (${BREAK_POINTS.m}) {
    padding: 0;
    flex-direction: column;
    gap: 0.5rem;
  }
`
export const NoResult = styled.div`
  width: 100%;
  height: 3rem;
  background-color: ${COLORS.error_l};
  color: ${COLORS.error_d};
  border-left: 5px solid ${COLORS.error_d};
  border-radius: 4px;
  margin-top: 1rem;
  box-shadow: ${SHADOWS.mid};
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Modal = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;
  background-color: ${COLORS.background};
  box-shadow: ${SHADOWS.mid};
  width: 90%;
  border-radius: 5px;
  @media (${BREAK_POINTS.m}) {
    display: flex;
  }
`
export const ModalList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`
export const ModalItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 1rem;
  &:nth-child(2n) {
    background-color: ${COLORS.background_l};
  }
`
export const ItemName = styled.span`
  font-weight: bold;
`
export const Item = styled.span`
  text-align: right;
  font-size: 0.75rem;
`
export const ModalBtn = styled.button`
  height: 2rem;
  width: 8rem;
  cursor: pointer;
  border-radius: 4px;
  background-color: ${COLORS.primary};
  color: ${COLORS.background_d};
  &:hover {
    background-color: ${COLORS.primary_d};
  }
`
