import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { faqData } from '../../../../data/resources/lists';
import { Accordion, AccordionDetails, AccordionSummary, Container, Title } from './style';

const FAQ = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState('panel1');

  const handleChange = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Container id='faq'>
      <Title>{t('faq_title')}</Title>
      {faqData.map((f) => (
        <Accordion key={f.id} expanded={expanded === f.name} onChange={handleChange(f.name)}>
          <AccordionSummary aria-controls={f.aria} id={f.id}>
            <strong>{t(f.title)}</strong>
          </AccordionSummary>
          <AccordionDetails>
            <Trans>{t(f.desc)}</Trans>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

export default FAQ;
