import React from 'react';
import {
  Container,
  LogoContainer,
  Logo,
  MenuContainer,
  MenuHeader,
  MenuList,
  MenuItem,
  ItemName,
} from './style';
import { attaLogoWhitePng } from '../../../../assets/images';
import { useTranslation } from 'react-i18next';
import { menuList } from '../../../../data/resources/lists';
import { AnimatePresence } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';

const Sidebar = () => {
  const { t } = useTranslation();
  const url = useLocation();
  const navigate = useNavigate();

  function handleDownClick() {
    const index = menuList.findIndex((item) => item.link === url.pathname);
    const nextIndex = index + 1;
    const nextItem = menuList[nextIndex];
    if (nextItem) {
      navigate(nextItem.link);
    }
  }

  function handleUpClick() {
    const index = menuList.findIndex((item) => item.link === url.pathname);
    const prevIndex = index - 1;
    const prevItem = menuList[prevIndex];
    if (prevItem) {
      navigate(prevItem.link);
    }
  }

  useHotkeys('ctrl+shift+down', handleDownClick);
  useHotkeys('ctrl+shift+up', handleUpClick);

  return (
    <AnimatePresence>
      <Container>
        <LogoContainer>
          <Logo onClick={() => navigate('/')} src={attaLogoWhitePng} alt='Azericard Logo' />
        </LogoContainer>
        <MenuContainer>
          <MenuHeader>{t('Menu')}</MenuHeader>
          <MenuList>
            {menuList.map((item) => (
              <MenuItem
                isactivePage={url.pathname === item.link}
                onClick={() => navigate(item.link)}
                key={item.id + item.name}
              >
                {item.icon()}
                <ItemName>{t(item.name)}</ItemName>
              </MenuItem>
            ))}
          </MenuList>
        </MenuContainer>
      </Container>
    </AnimatePresence>
  );
};

export default Sidebar;
