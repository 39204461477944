import { mobileNumberPrefixes } from '../../data/resources/lists';

export const validateMobileNumberPrefix = (num) => {
  if (num.length === 1) {
    if (!mobileNumberPrefixes.map((n) => n.charAt(0)).includes(num.charAt(0))) {
      return '';
    }
  }
  if (num.length === 2) {
    if (!mobileNumberPrefixes.includes(num)) {
      return `${num.charAt(0)}`;
    }
  }
  if (num.length === 3) {
    if (num[2] === '0' || num[2] === '1') {
      return num.substring(0, 2);
    }
  }

  return num;
};
