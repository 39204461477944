import OTPInput from 'otp-input-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../../../assets/styles/style/styled';
import { MOBILE_BREAKPOINT } from '../../../../assets/styles/theme';
import { MOBILE_CODE } from '../../../../data/resources/constants';
import { initialRegisterFormData } from '../../../../data/resources/initialStates';
import { passwordConfirm, signUpConfirm } from '../../../../services/api/authApi';
import { sendOtp } from '../../../../services/api/userApi';
import { useLogger } from '../../../../utils/hooks/useLogger';
import { useToast } from '../../../../utils/hooks/useToast';
import { useWindow } from '../../../../utils/hooks/useWindow';
import BackDrop from '../../layout/backdrop';
import { Btn, FooterLink } from '../login-wrapper/style';
import {
  Btns,
  Container,
  Desc,
  otpInputStyles,
  otpInputStylesMobile,
  Title,
  Wrapper,
} from './style';

const OTPWrapper = ({
  close,
  mobileNumber,
  uuid,
  fdata,
  sfdata,
  stdata,
  isPwd,
  delUuid,
  setUuid,
  setShowPwdChange,
  type,
  isRegister,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [OTP, setOTP] = useState('');
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [mainDisable, setMainDisable] = useState(false);
  const [counter, setCounter] = useState(120);
  const { width } = useWindow();
  const { setSuccess, setError } = useToast();
  const queryClient = useQueryClient();
  const logger = useLogger();

  const handleClick = () => {
    const data = {
      processUuid: uuid,
      otp: OTP.toString(),
    };
    isRegister ? register(data) : !isPwd ? mutate(data) : passwordChangeMutate(data);
    logger(isRegister ? 'Registration OTP' : !isPwd ? 'User OTP' : 'Password Change OTP', data);
  };

  const { mutate, isLoading } = useMutation((data) => sendOtp(data), {
    onSuccess: () => {
      sfdata({ ...fdata, mobileNumber });
      stdata({ ...fdata, mobileNumber });
      setSuccess('successfully_updated');
      queryClient.refetchQueries(['user']);
      logger('OTP sent', { ...fdata, mobileNumber });
      close();
    },
    onError: (err) => {
      setError(err);
    },
  });

  const { mutate: passwordChangeMutate, isLoading: isChangeLoading } = useMutation(
    (data) => passwordConfirm(data),
    {
      onSuccess: ({ data }) => {
        if (data.processUuid) {
          setUuid(data.processUuid);
          logger('Password change OTP', data);
          setShowPwdChange();
          close();
        }
      },
      onError: (err) => {
        setError(err);
      },
    }
  );

  const {
    mutate: register,
    isLoading: registerLoading,
    isFetching: registerFetching,
  } = useMutation((data) => signUpConfirm(data), {
    onError: (err) => {
      setError(err);
    },
    onSuccess: () => {
      sfdata(initialRegisterFormData);
      setSuccess('registered_successfully');
      logger('Signup confirm OTP', { email: fdata?.email, pin: fdata?.pin });
      navigate('/', { state: 'Login' });
      close();
    },
  });

  useEffect(() => {
    if (OTP.length === 6) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
    }
  }, [OTP]);

  useEffect(() => {
    let interval = setInterval(() => {
      if (counter >= 1) setCounter((prev) => prev - 1);
      if (counter === 1) setIsBtnDisabled(true);
      if (counter === 0) {
        clearInterval(interval);
        setMainDisable(true);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [counter]);

  const end = () => {
    delUuid();
    close();
  };

  return (
    <BackDrop onClick={end}>
      <Container>
        <Wrapper onClick={(e) => e.stopPropagation()}>
          <Title>{t('sms_title')}</Title>
          <OTPInput
            value={OTP}
            onChange={setOTP}
            autoFocus
            OTPLength={6}
            otpType='number'
            disabled={mainDisable}
            inputStyles={width > MOBILE_BREAKPOINT ? otpInputStyles : otpInputStylesMobile}
          />
          <Desc>
            {t('otp_has_been_send', {
              number: type ? mobileNumber : '+' + MOBILE_CODE + mobileNumber,
            })}{' '}
          </Desc>
          <Desc>{t('time_left', { counter })}</Desc>
          <Btns>
            <Btn
              disabled={
                isBtnDisabled || isLoading || isChangeLoading || registerLoading || registerFetching
              }
              onClick={handleClick}
              whileTap={{ scale: 0.95 }}
            >
              {isChangeLoading ||
              isLoading ||
              isChangeLoading ||
              registerLoading ||
              registerFetching ? (
                <Spinner />
              ) : (
                t('approve')
              )}
            </Btn>
            <FooterLink onClick={end}>{t('cancel')}</FooterLink>
          </Btns>
        </Wrapper>
      </Container>
    </BackDrop>
  );
};

export default OTPWrapper;
