import { useQuery } from 'react-query'

export const useFetch = (
  name,
  fn,
  config,
  options = { retry: false, enabled: true }
) => {
  const { retry, enabled } = options
  const {
    data,
    error,
    isError,
    isFetched,
    isFetching,
    isLoading,
    isPlaceholderData,
    isPreviousData,
    isRefetching,
    isStale,
    isSuccess,
    refetch,
    remove,
  } = useQuery(name, fn, {
    ...config,
    refetchOnWindowFocus: false,
    retry,
    enabled,
  })

  return {
    data,
    error,
    isError,
    isFetched,
    isLoading: isLoading || isFetching,
    isPlaceholderData,
    isPreviousData,
    isRefetching,
    isStale,
    isSuccess,
    refetch,
    remove,
  }
}
