import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import Skeleton from '@mui/material/Skeleton';
import 'flag-icon-css/css/flag-icons.min.css';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useMenu } from '../../../../context/MenuProvider';
import { LOGOUT } from '../../../../data/resources/actionTypes';
import { signOut } from '../../../../services/api/authApi';
import { getUserInfo } from '../../../../services/api/userApi';
import { useAuth } from '../../../../utils/hooks/useAuth';
import { useLogger } from '../../../../utils/hooks/useLogger';
import { Chevron, Container, UserName } from './style';
import { UserImageContainer } from './UserImageContainer';

const UserSection = ({ isLanding, isMenu }) => {
  const navigate = useNavigate();
  const { setMenu, isMenuOpen } = useMenu();

  const [name, setName] = useState('');
  const [img, setImg] = useState('');
  const logger = useLogger();

  const {
    dispatch,
    user: { accessToken, pin },
  } = useAuth();

  const { mutate: logOut } = useMutation(signOut, {
    onError: (err) => console.error(err),
    onSuccess: () => {
      dispatch({ type: LOGOUT });
      logger('User signed out', { userPin: pin || '' });
      navigate('/');
    },
  });

  const { isLoading, isFetching } = useQuery('user-name', getUserInfo, {
    onSuccess: ({ data }) => {
      setName(data.firstname + ' ' + data.lastname);
      setImg(data.profilePhoto ? 'data:image/png;base64,' + data.profilePhoto : '');
    },
    onError: (err) => {
      console.error(err);
      if (err.response.status === 401) {
        logOut(accessToken);
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const handleClose = () => {
    setMenu(null);
  };
  const handleClick = () => {
    setMenu(true);
  };

  return (
    <Container
      isDropOpen={isMenuOpen}
      isLanding={isLanding}
      isMenu={isMenu}
      onClick={() => navigate('/dashboard/profile')}
    >
      <UserImageContainer
        img={img}
        isLoading={isLoading}
        isFetching={isFetching}
        isLanding={isLanding}
      ></UserImageContainer>

      {isLoading || isFetching ? (
        <Skeleton
          variant='rounded'
          sx={{
            borderRadius: '4px',
            bgcolor: isLanding ? 'grey.200' : 'grey.200',
          }}
          width='7rem'
          height='1.5rem'
          animation='wave'
        />
      ) : (
        <UserName isMenu={isMenu}>{name}</UserName>
      )}

      {!isMenu && (
        <Chevron onClick={(e) => e.stopPropagation()}>
          {isMenuOpen ? (
            <ExpandLessOutlinedIcon onClick={handleClose} />
          ) : (
            <ExpandMoreOutlinedIcon onClick={handleClick} />
          )}
        </Chevron>
      )}
    </Container>
  );
};

export default UserSection;
