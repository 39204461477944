import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { background, headerDeco } from '../../../../assets/images';
import { useAuth } from '../../../../utils/hooks/useAuth';
import LoginForm from '../../features/login-form';
import PartnerWrapper from '../../features/partner-wrapper';
import RegisterForm from '../../features/register-form';
import {
  Background,
  Body,
  Container,
  Content,
  Deco,
  Subtitle,
  Tab,
  Tabs,
  Title,
  Wrapper,
} from './style';

const Header = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const location = useLocation();

  const headerTabs = useMemo(
    () => [
      {
        name: 'Register',
        label: 'register',
        component: <RegisterForm />,
        isPresentOnLogin: false,
      },
      {
        name: 'Login',
        label: 'Login',
        component: <LoginForm />,
        isPresentOnLogin: false,
      },
      {
        name: 'Become a Partner',
        label: 'become_a_partner',
        component: <PartnerWrapper />,
        isPresentOnLogin: true,
      },
    ],
    []
  );

  const [selectedTab, setSelectedTab] = useState(
    !user?.accessToken
      ? headerTabs.find((t) => t.name === 'Login')
      : headerTabs.find((t) => t.name === 'Become a Partner')
  );

  useEffect(() => {
    if (location?.state === 'Login') {
      setSelectedTab(headerTabs.find((t) => t.name === 'Login'));
    }

    if (location.state && typeof location.state === 'string') {
      location.state = '';
    }

    if (location?.search.includes('tab=register') && !user?.accessToken) {
      setSelectedTab(headerTabs.find((t) => t.name === 'Register'));
    }
  }, [location, headerTabs, user?.accessToken]);

  return (
    <Container>
      <Background src={background} alt='Background' effect='blur' />
      <Deco src={headerDeco} alt='Background' effect='blur' />
      <Content>
        <Title>{t('header_title')}</Title>
        <Subtitle>{t('header_subtitle')}</Subtitle>
      </Content>
      <Wrapper>
        <Tabs>
          {headerTabs
            .filter((tab) => (user?.accessToken ? (tab.isPresentOnLogin ? true : false) : true))
            .map((tab, i) => (
              <Tab
                key={i}
                isSelected={selectedTab.name === tab.name}
                onClick={() => setSelectedTab(tab)}
                isAuth={Boolean(user?.accessToken)}
              >
                {t(tab.label)}
              </Tab>
            ))}
        </Tabs>
        <Body>{selectedTab.component}</Body>
      </Wrapper>
    </Container>
  );
};

export default Header;
