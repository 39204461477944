import styled from 'styled-components'
import { motion } from 'framer-motion'
import { BREAK_POINTS, COLORS, SHADOWS } from '../../../../assets/styles/theme'

export const Container = styled(motion.div)`
  position: fixed;
  right: 0;
  bottom: 0;
  background-color: ${COLORS.black};
  height: 100vh;
  color: ${COLORS.background_d};
  display: none;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.5rem;
  z-index: 20;
  box-shadow: ${SHADOWS.mid};
  @media (${BREAK_POINTS.m}) {
    display: flex;
  }
`
export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  height: 3rem;
  gap: 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  background-color: ${({ isactivePage }) => (isactivePage ? COLORS.primary : 'transparent')};
  &:hover {
    background-color: ${COLORS.primary_d};
  }
`
export const ItemName = styled.span``
