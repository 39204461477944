import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { xLogo } from '../../../../assets/images';
import {
  AZERICARD_EMAIL,
  AZERICARD_MAP_LINK,
  AZERICARD_NUMBERS,
} from '../../../../data/resources/constants';
import { socialMediaLinks } from '../../../../data/resources/lists';
import {
  Contact,
  ContactHeader,
  ContactItem,
  FacebookIcon,
  IconContainer,
  InstagramIcon,
  Social,
  Text,
  TextContainer,
  Wrapper,
  XLogo,
} from './style';

const ContactWrapper = ({ noTitle }) => {
  const { t } = useTranslation();
  return (
    <Wrapper notitle={noTitle}>
      <Contact>
        {!noTitle && <ContactHeader>{t('contact')}</ContactHeader>}
        <ContactItem>
          <MapRoundedIcon />
          <Text isLink={true} as={'a'} href={AZERICARD_MAP_LINK} target='_blank'>
            {t('map_info')}
          </Text>
        </ContactItem>
        <ContactItem>
          <LocalPhoneRoundedIcon />
          <TextContainer>
            {AZERICARD_NUMBERS.map((contact) => (
              <Text key={contact}>{contact}</Text>
            ))}
          </TextContainer>
        </ContactItem>
        <ContactItem>
          <MailOutlineRoundedIcon />
          <Text>{AZERICARD_EMAIL}</Text>
        </ContactItem>
      </Contact>
      <Social>
        <IconContainer href={socialMediaLinks.facebook} target='_blank' rel='noreferrer noopener'>
          <FacebookIcon />
        </IconContainer>
        <IconContainer href={socialMediaLinks.instagram} target='_blank' rel='noreferrer noopener'>
          <InstagramIcon />
        </IconContainer>
        <IconContainer href={socialMediaLinks.twitter} target='_blank' rel='noreferrer noopener'>
          <XLogo src={xLogo} alt='Twitter Logo' />
        </IconContainer>
      </Social>
    </Wrapper>
  );
};

export default ContactWrapper;
