import styled from 'styled-components'
import { BREAK_POINTS, COLORS } from '../../../../assets/styles/theme'

export const Container = styled.div`
  width: 100%;
`
export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 3rem;
`
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 3rem;
  gap: 2px;
`
export const Cell = styled.div`
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  background-color: ${COLORS.primary};
  color: ${COLORS.background_d};
  transition: all 0.3s ease;
  position: relative;
  cursor: pointer;
  &:first-child {
    border-radius: 5px 0 0 0;
    width: 15%;
  }
  &:nth-child(2) {
    width: 15%;
  }
  &:nth-child(3) {
    width: 10%;
  }
  &:nth-child(4) {
    width: 40%;
  }
  &:nth-child(5) {
    width: 10%;
  }
  &:last-child {
    border-radius: 0 5px 0 0;
    width: 10%;
  }
  @media (${BREAK_POINTS.m}) {
    &:first-child {
      width: 40%;
    }
    &:nth-child(2) {
      display: none;
    }
    &:nth-child(3) {
      display: none;
    }
    &:nth-child(4) {
      border-radius: 0 5px 0 0;
      width: 60%;
    }
    &:nth-child(5) {
      display: none;
    }
    &:last-child {
      display: none;
    }
  }
`
export const Cols = styled.div`
  display: flex;
  flex-direction: column;
`
