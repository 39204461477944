import axios from 'axios';
import { requestHeaders, URLS } from '../../data/resources/constants';

const commonApi = axios.create({
  baseURL: URLS.base,
  headers: requestHeaders,
});

export const mailSend = async (data) => await commonApi.post('/common/mail', data);
export const partnerSend = async (data) => await commonApi.post('/common/partner', data);
export const log = async (data) => await commonApi.post('/log', data);
export const getServiceId = async () => await commonApi.get('/common/constants');
