import React, { useState } from 'react';
import { Title, Container } from './style';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { COLORS } from '../../../assets/styles/theme';

import { useQuery } from 'react-query';
import { getTransactions } from '../../../services/api/userApi';

import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useAuth } from '../../../utils/hooks/useAuth';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { parse, compareAsc } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import './tr.css';
import { useToast } from '../../../utils/hooks/useToast';
import { useWindow } from '../../../utils/hooks/useWindow';
// import PdfModal from '../../components/modals/pdf-modal';

const dateComparator = (v1, v2) => {
  const date1 = parse(v1, 'dd.MM.yyyy HH:mm:ss', new Date());
  const date2 = parse(v2, 'dd.MM.yyyy HH:mm:ss', new Date());
  return compareAsc(date1, date2);
};

const formatCardNum = (cardNum) => {
  if (!cardNum) {
    return cardNum;
  }

  const prefix = '**** **** ****';
  return prefix + ' ' + cardNum;
};

const statusMap = {
  SUCCESS: 'success',
  PAYMENT_NOT_UNIQUE: 'not-unique',
  CARD_NOT_FOUND: 'not-found',
  AMOUNT_BLOCKED: 'blocked',
  OTP_EXPIRED: 'expired',
  TRANSACTION_ERROR: 'error',
  AUTHORIZE_ERROR: 'auth-error',
  USER_NOT_ACTIVE: 'not-active',
  VALIDATED: 'validated',
  WAIT_FOR_OTP: 'wait',
  'Transaction Reversed': 'reversed',
  null: 'null',
};

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.accent,
      light: COLORS.accent_l,
      dark: COLORS.accent_d,
    },
  },
});

const HistoryPage = () => {
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const { t } = useTranslation();
  const { setError } = useToast();
  const { width } = useWindow();

  const columns = [
    {
      field: t('date'),
      headerName: t('date'),
      minWidth: 150,
      sortComparator: dateComparator,
    },
    { field: t('transactionId'), headerName: t('transactionId'), width: 140 },
    { field: t('amount'), headerName: t('amount'), minWidth: 60, type: 'number' },
    { field: t('operationName'), headerName: t('operationName'), minWidth: 360 },
    { field: t('organizationName'), headerName: t('organizationName'), width: 190 },
    {
      field: 'rrn',
      headerName: 'RRN',
      width: 180,
    },
    {
      field: 'cardNum',
      headerName: t('cardNum'),
      width: 160,
    },
    {
      field: t('status'),
      headerName: t('status'),
      width: 180,
      cellClassName: (params) => {
        return 'tr-status st-' + statusMap[params.row.st];
      },
    },
  ];

  const { refetch, isLoading } = useQuery('transactions', () => getTransactions(pin), {
    onError: (err) => {
      setError(err);
    },
    onSuccess: ({ data }) => {
      setRows(
        data.map((item) => ({
          id: item.id,
          [t('date')]: item.date,
          operationId: item.operationId,
          [t('operationName')]: item.operationName,
          serviceData: item.serviceData,
          serviceId: item.serviceId,
          [t('status')]: t(item.status),
          [t('transactionId')]: item.serviceData,
          [t('amount')]: item.amount,
          [t('organizationName')]: item.organizationName,
          st: item.status,
          rrn: item.rrn,
          cardNum: formatCardNum(item.cardNum),
        }))
      );
    },
    retry: false,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.documentElement.lang]);

  const lang = {
    // Root
    noRowsLabel: t('no_result_table'),
    noResultsOverlayLabel: t('no_result_table'),
    errorOverlayDefaultLabel: t('error_something_went_wrong'),

    // Columns selector toolbar button text
    toolbarColumns: t('toolbar_columns'),
    toolbarColumnsLabel: t('toolbar_columns'),

    // Filters toolbar button text
    toolbarFilters: t('toolbar_filters'),
    toolbarFiltersLabel: t('toolbar_filters'),
    toolbarFiltersTooltipHide: t('toolbar_filters_hide'),
    toolbarFiltersTooltipShow: t('toolbar_filters_show'),
    toolbarFiltersTooltipActive: (count) => t('toolbar_filters_active', { count }),

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: t('search'),
    toolbarQuickFilterLabel: t('search'),
    toolbarQuickFilterDeleteIconLabel: t('search_clear'),

    // Export selector toolbar button text
    toolbarExport: t('toolbar_export'),
    toolbarExportLabel: t('toolbar_export'),
    toolbarExportCSV: t('toolbar_export_csv'),

    // Columns panel text
    columnsPanelTextFieldLabel: t('toolbar_search_column'),
    columnsPanelTextFieldPlaceholder: t('search'),
    columnsPanelDragIconLabel: t('toolbar_search_column'),
    columnsPanelShowAllButton: t('toolbar_search_column_show'),
    columnsPanelHideAllButton: t('toolbar_search_column_hide'),

    // Filter panel text
    filterPanelAddFilter: t('toolbar_filter_add'),
    filterPanelDeleteIconLabel: t('toolbar_filter_add'),
    filterPanelLinkOperator: t('toolbar_filter_link_operators'),
    filterPanelOperators: t('toolbar_filter_operators'),

    // TODO v6: rename to filterPanelOperator
    filterPanelOperatorAnd: t('toolbar_op_and'),
    filterPanelOperatorOr: t('toolbar_op_or'),
    filterPanelColumns: t('toolbar_op_cols'),
    filterPanelInputLabel: t('toolbar_op_cols_input'),
    filterPanelInputPlaceholder: t('toolbar_op_value'),

    // Filter operators text
    filterOperatorContains: t('toolbar_val_contains'),
    filterOperatorEquals: t('toolbar_val_equals'),
    filterOperatorStartsWith: t('toolbar_val_stw'),
    filterOperatorEndsWith: t('toolbar_val_enw'),
    filterOperatorIs: t('toolbar_val_eq'),
    filterOperatorNot: t('toolbar_val_neq'),
    filterOperatorAfter: t('toolbar_val_after'),
    filterOperatorOnOrAfter: t('toolbar_val_on_after'),
    filterOperatorBefore: t('toolbar_val_before'),
    filterOperatorOnOrBefore: t('toolbar_val_on_before'),
    filterOperatorIsEmpty: t('toolbar_val_emp'),
    filterOperatorIsNotEmpty: t('toolbar_val_nemp'),

    // Column menu text
    columnMenuLabel: t('toolbar_col_menu'),
    columnMenuShowColumns: t('toolbar_col_menu_show'),
    columnMenuFilter: t('toolbar_col_add_filter'),
    columnMenuHideColumn: t('toolbar_col_hide'),
    columnMenuUnsort: t('toolbar_col_unsort'),
    columnMenuSortAsc: t('toolbar_col_sort_asc'),
    columnMenuSortDesc: t('toolbar_col_sort_desc'),

    // Column header text
    columnHeaderFiltersTooltipActive: (count) => t('toolbar_columns_active', { count }),
    columnHeaderFiltersLabel: t('toolbar_col_filters_show'),
    columnHeaderSortIconLabel: t('toolbar_col_filters_sort'),
  };

  const {
    user: { pin },
  } = useAuth();

  // const [openModal, setOpenModal] = useState(null);

  // function onModalClose() {
  //   setOpenModal(null);
  // }

  return (
    <>
      <Container>
        <Helmet>
          <title>{t('pt_history')}</title>
        </Helmet>
        <Title>{t('history')}</Title>
        <ThemeProvider theme={theme}>
          <Box sx={{ height: 800, width: '100%' }}>
            {isLoading ? (
              t('loading')
            ) : (
              <DataGrid
                localeText={{
                  ...lang,
                  MuiTablePagination: {
                    labelRowsPerPage: t('rows_per_page'),
                    labelDisplayedRows: ({ count, from, to }) =>
                      t('showing_rows', { count, from, to }),
                  },
                }}
                sx={{
                  p: 1,
                  boxShadow: 1,
                  '& .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter':
                    {
                      width: 350,
                    },
                  '& .MuiDataGrid-cell': {
                    padding: 1,
                  },
                  '& .MuiDataGrid-cell:hover': {
                    color: COLORS.accent,
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outlineColor: COLORS.accent_d,
                  },
                  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                    width: '0.3em',
                    height: '0.3em',
                  },
                }}
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(value) => setPageSize(value)}
                rowsPerPageOptions={[5, 10, 25, 100]}
                disableSelectionOnClick
                disableDensitySelector
                getRowHeight={() => 'auto'}
                initialState={{
                  sorting: {
                    sortModel: [{ field: t('date'), sort: 'desc' }],
                  },
                  columns: {
                    columnVisibilityModel: {
                      [t('amount')]: width < 525 ? false : true,
                      [t('transactionId')]: width < 820 ? false : true,
                      [t('organizationName')]: width < 600 ? false : true,
                      [t('status')]: width < 760 ? false : true,
                    },
                  },
                }}
                components={{ Toolbar: GridToolbar }}
                experimentalFeatures={{ newEditingApi: true }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                  },
                }}
              />
            )}
          </Box>
        </ThemeProvider>
      </Container>

      {/* <PdfModal isOpen={openModal !== null} onClose={onModalClose} data={openModal ?? {}} /> */}
    </>
  );
};

export default HistoryPage;
