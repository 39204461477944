/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { initialLoginFormData } from '../../data/resources/initialStates'

export const useLogin = () => {
  const [formData, setFormData] = useState(initialLoginFormData)
  const [ppData, setPPData] = useState(initialLoginFormData)
  const [isBtnDisabled, setIsBtnDisabled] = useState({ value: true, msg: 'fill_the_gaps' })
  const [isPPBtnDisabled, setIsPPBtnDisabled] = useState({ value: true, msg: 'fill_the_gaps' })
  const [showPassword, setShowPassword] = useState(false)

  const getIsBtnDisabled = () => {
    if (!formData.email || !formData.password) {
      setIsBtnDisabled({ value: true, msg: 'fill_the_gaps' })
    } else if (formData.email.length > 50) {
      setIsBtnDisabled({ value: true, msg: 'invalid_username' })
    } else {
      setIsBtnDisabled({ value: false })
    }
  }

  const getIsPPBtnDisabled = () => {
    if (!ppData.email || !ppData.password) {
      setIsPPBtnDisabled({ value: true, msg: 'fill_the_gaps' })
    } else {
      setIsPPBtnDisabled({ value: false })
    }
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handlePPChange = (e) => {
    const { name, value } = e.target
    setPPData((prev) => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    getIsBtnDisabled()
  }, [formData])

  useEffect(() => {
    getIsPPBtnDisabled()
  }, [ppData])

  return {
    formData,
    ppData,
    handleOnChange,
    handlePPChange,
    showPassword,
    setShowPassword,
    isBtnDisabled,
    isPPBtnDisabled
  }
}
