import React from 'react'
import { useTranslation } from 'react-i18next'
import { COLORS } from '../../../../assets/styles/theme'
import { passwordValidationFields } from '../../../../data/resources/lists'
import ToolTip from '../../modals/tooltip'
import {
  NotVerifiedIcon,
  PwdContainer,
  PwdHeader,
  PwdIcon,
  PwdInfo,
  PwdItem,
  PwdList,
  VerifiedIcon
} from './style'

const PasswordTooltip = ({ show, validation }) => {
  const { t } = useTranslation()

  return show ? (
    <ToolTip
      dir='top'
      bot='110%'
      bg={validation.isEverythingTrue ? COLORS.primary_d : COLORS.gray_d}
    >
      <PwdContainer>
        <PwdHeader>{t('password_info_header')}</PwdHeader>
        <PwdList>
          {passwordValidationFields.map((field) => (
            <PwdItem key={field.name}>
              <PwdIcon>{validation[field.name] ? <VerifiedIcon /> : <NotVerifiedIcon />}</PwdIcon>
              <PwdInfo>{t(field.msg)}</PwdInfo>
            </PwdItem>
          ))}
        </PwdList>
      </PwdContainer>
    </ToolTip>
  ) : null
}

export default PasswordTooltip
