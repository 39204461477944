import { useMutation } from 'react-query';
import { addCard } from '../../services/api/cardApi';
import { useAuth } from './useAuth';
import { useTranslation } from 'react-i18next';

export const useAddCard = () => {
  const {
    user: { email },
  } = useAuth();
  const { i18n } = useTranslation();

  const { mutate } = useMutation(() => addCard(email, i18n.language ?? 'en'), {
    onSuccess: ({ data }) => {
      window.location.href = data?.redirectUrl;
    },
    onError: () => {
      window.location.reload();
    },
  });

  return { mutate };
};
