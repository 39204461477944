import styled, { keyframes } from 'styled-components'
import { COLORS } from '../theme'

const SPINNER_KEYFRAMES = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
`

export const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: ' ';
    display: block;
    width: ${({ wid }) => (wid ? wid : '2rem')};
    aspect-ratio: 1;
    border-radius: 50%;
    border: ${({ col }) =>
      col === 'acc'
        ? `2px solid ${COLORS.accent}`
        : col === 'err'
        ? `2px solid ${COLORS.error}`
        : `4px solid ${COLORS.primary}`};
    border-color: ${({ col }) =>
      col === 'acc'
        ? `${COLORS.accent} transparent ${COLORS.accent} transparent`
        : col === 'err'
        ? `${COLORS.error} transparent ${COLORS.error} transparent`
        : `${COLORS.primary} transparent ${COLORS.primary} transparent`};
    animation: ${SPINNER_KEYFRAMES} 1.2s linear infinite;
  }
`
