import AddPhotoAlternateRoundedIcon from '@mui/icons-material/AddPhotoAlternateRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { Skeleton } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { manat, placeholder } from '../../../assets/images';
import license from '../../../assets/pdf/license-agreement.pdf';
import { Spinner } from '../../../assets/styles/style/styled';
import { COLORS, MOBILE_BREAKPOINT } from '../../../assets/styles/theme';
import { MOBILE_CODE, SOURCE } from '../../../data/resources/constants';
import { errors } from '../../../data/resources/errorMessages';
import { initialUserUpdateFormData } from '../../../data/resources/initialStates';
import { steps } from '../../../data/resources/intro';
import { allowedImgTypes } from '../../../data/resources/lists';
import { emailRegex } from '../../../data/resources/regex';
import { passwordCheck } from '../../../services/api/authApi';
import {
  addImage,
  deleteImage,
  editUser,
  getUserCount,
  getUserInfo,
} from '../../../services/api/userApi';
import { getBase64 } from '../../../utils/helpers/helpers';
import { validateAge } from '../../../utils/helpers/validateAge';
import { validateFields } from '../../../utils/helpers/validateFields';
import { validateMobileNumberPrefix } from '../../../utils/helpers/validateMobileNumber';
import { useAuth } from '../../../utils/hooks/useAuth';
import { useIntro } from '../../../utils/hooks/useIntro';
import { useLogger } from '../../../utils/hooks/useLogger';
import { useToast } from '../../../utils/hooks/useToast';
import { useWindow } from '../../../utils/hooks/useWindow';
import { BackDrop, OTPWrapper, PwdChangeWrapper } from '../../components';
import { PDFLink } from '../../components/features/login-form/style';
import { Btn, ErrorBlock, FooterLink, Input } from '../../components/features/login-wrapper/style';
import {
  NumberField,
  NumberInput,
  StaticBox,
} from '../../components/features/register-wrapper/style';
import ProfileChip from './ProfileChip';
import {
  AddImgBtn,
  ChipsContainer,
  Container,
  DeleteBtn,
  ErrorText,
  Form,
  FormFooter,
  FullImageContainer,
  Icon,
  Image,
  Label,
  Title,
  TryAgain,
  UploadSection,
  Wrapper,
} from './style';

const Profile = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState(initialUserUpdateFormData);
  const [count, setCount] = useState(0);
  const [staticFormData, setStaticFormData] = useState(initialUserUpdateFormData);
  const [img, setImg] = useState('');
  const [isBtnDisabled, setIsBtnDisabled] = useState([true, '']);
  const [showOtp, setShowOtp] = useState(null);
  const [showPwdChange, setShowPwdChange] = useState(false);
  const [processUuid, setProcessUuid] = useState('');
  const [showFullImage, setShowFullImage] = useState(false);
  const [showImageButtons, setShowImageButtons] = useState(false);
  const [volunteerFlag, setVolunteerFlag] = useState(false);
  const [mobile, setMobile] = useState(null);
  const [isFormEdited, setIsFormEdited] = useState(false);
  const { setSuccess, setError } = useToast();
  const logger = useLogger();

  const JoyRide = useIntro('profile', steps.profile);

  useEffect(() => {
    const isEdited = Object.keys(formData).some((key) => formData[key] !== staticFormData[key]);
    setIsFormEdited(isEdited);
  }, [formData, staticFormData]);

  const { width } = useWindow();

  useEffect(() => {
    if (MOBILE_BREAKPOINT > width) {
      setShowImageButtons(true);
    }
  }, [width]);

  const {
    user: { email: userEmail, pin: userPin },
  } = useAuth();

  const handleImg = async (e) => {
    const img = e.target.files[0];
    const size = img.size / 1000000;
    const type = img.type;
    if (size > 2) {
      setError('img_size_too_big');
      return;
    }
    if (!allowedImgTypes.includes(type.toString())) {
      setError('img_type_not_allowed');
      return;
    }
    const base64 = await getBase64(img);
    setImg(base64);
    setFormData({ ...formData, profilePhoto: base64.split('base64,')[1] });
    setStaticFormData({ ...formData, profilePhoto: base64.split('base64,')[1] });
    addImgMutate({
      email: userEmail,
      profilePicture: base64.split('base64,')[1],
    });
  };

  const { isLoading: countLoading } = useQuery('count', () => getUserCount(), {
    onSuccess: ({ data }) => {
      setCount(data);
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const checkVolunteer = (referal) => {
    if (!referal) return false;
    if (referal.charAt(0) !== 'A') return false;
    const body = referal.substring(1);
    const numReg = /^[0-9]*$/;
    if (!body.match(numReg)) return false;
    const accept = ['1', '2', '0'];
    if (!accept.includes(body[body.length - 1])) return false;
    return true;
  };

  const { isLoading, isError, isSuccess, error, isFetching, refetch } = useQuery(
    'user',
    getUserInfo,
    {
      onSuccess: ({ data }) => {
        setVolunteerFlag(checkVolunteer(data.referralCode));
        setFormData({
          ...data,
          referal: data.referralCodeOwn,
          mobileNumber: data.mobileNumber.slice(3),
        });
        setStaticFormData({
          ...data,
          referal: data.referralCodeOwn,
          mobileNumber: data.mobileNumber.slice(3),
        });
        setMobile(data.mobileNumber.slice(3));
        setImg(data.profilePhoto ? 'data:image/png;base64,' + data.profilePhoto : '');
      },
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
    }
  );

  const btnDisable = (data) => {
    if (!data.email.length || !data.firstname || !data.lastname || !data.mobileNumber)
      return [true, 'fill_the_gaps'];
    if (!data.email.match(emailRegex)) return [true, 'error_msg_email_valid'];
    if (data.firstname.length < 3) return [true, 'invalid_first_name'];
    if (data.lastname.length < 3) return [true, 'invalid_last_name'];
    if (data.middleName && data.middleName.length < 3) return [true, 'invalid_paternal_name'];
    if (data.mobileNumber.length !== 9) return [true, 'error_msg_mobile_phone'];
    return [false];
  };

  const queryClient = useQueryClient();

  const handlePasswordCheck = () => {
    const data = {
      mobileNumber: MOBILE_CODE + mobile,
      source: SOURCE,
      phone: true,
    };
    logger('Password is checking', data);
    checkPasswordMutate(data);
  };

  const { mutate: checkPasswordMutate, isLoading: isCheckLoading } = useMutation(
    (data) => passwordCheck(data),
    {
      onSuccess: ({ data }) => {
        if (data.processUuid) {
          setProcessUuid(data.processUuid);
          logger('Password change otp wrapper opened', { userPin });
          setShowOtp('passwordChange');
        }
      },
      onError: (err) => {
        setError(err);
      },
    }
  );

  const { mutate: addImgMutate, isLoading: isAddLoading } = useMutation((data) => addImage(data), {
    onSuccess: () => {
      queryClient.refetchQueries(['user-name']);
      logger('User image updated', { userPin });
      setSuccess('image_updated_successfully');
    },
    onError: (err) => {
      setError(err);
    },
  });

  const handleDelete = () => {
    deleteImgMutate();
  };

  const { mutate: deleteImgMutate } = useMutation(deleteImage, {
    onSuccess: () => {
      queryClient.refetchQueries(['user-name']);
      setImg('');
      logger('User image deleted', { userPin });
      setFormData({ ...formData, profilePhoto: '' });
      setStaticFormData({ ...formData, profilePhoto: '' });
      setSuccess('image_deleted_successfully');
    },
    onError: (err) => {
      setError(err);
    },
  });

  const { mutate, isLoading: isEditLoading } = useMutation((data) => editUser(data), {
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries(['user', 'user-name']);
      let observers = queryClient.queryCache.queries[0].observers;
      if (observers.length) observers[0].refetch();
      logger('User updated', {
        firstName: formData?.firstname,
        lastName: formData?.lastname,
        email: formData?.email,
        middleName: formData?.middleName,
        pin: formData?.pin,
        dateOfBirth: formData?.dateOfBirth,
        referralCode: formData?.referralCode,
        referralCodeOwn: formData?.referralCodeOwn,
        roles: formData?.roles,
        referal: formData?.referal,
      });
      setStaticFormData({
        ...formData,
        mobileNumber: formData.mobileNumber,
      });
      if (data.processUuid) {
        setProcessUuid(data.processUuid);
        setShowOtp('mobileNumberChange');
      } else {
        setSuccess('saved_successfully');
      }
    },
    onError: (err) => {
      setError(err);
      return;
    },
  });

  // Handles changing the input fields
  const handleChange = (e) => {
    const { name } = e.target;
    setFormData({ ...formData, [name]: validateFields(e) });
    setIsBtnDisabled(btnDisable({ ...formData, [name]: validateFields(e) }));
  };

  // Handles submitting the form
  const handleSubmit = (e) => {
    e.preventDefault();
    logger('User edit started on profile', {
      ...formData,
      mobileNumber: MOBILE_CODE + formData.mobileNumber,
      password: '',
    });
    mutate({ ...formData, mobileNumber: MOBILE_CODE + formData.mobileNumber });
  };

  // Handles opening the profile image
  const handleImageOpen = (action = 'open') => {
    if (action === 'close') {
      document.body.style.overflowY = 'visible';
      setShowFullImage(false);
      return;
    }
    document.body.style.overflowY = 'hidden';
    setShowFullImage(true);
  };

  const handleImageButton = (open) => {
    if (MOBILE_BREAKPOINT < width) {
      setShowImageButtons(open);
    } else {
      setShowImageButtons(true);
    }
  };
  const { maxAge, thisYear } = validateAge();

  return (
    <Container>
      <Helmet>
        <title>{t('pt_profile')}</title>
      </Helmet>
      {JoyRide}
      {showPwdChange && (
        <PwdChangeWrapper
          close={() => setShowPwdChange(false)}
          delUuid={() => setProcessUuid('')}
          uuid={processUuid}
        />
      )}
      {showOtp === 'mobileNumberChange' && (
        <OTPWrapper
          uuid={processUuid}
          close={() => setShowOtp(null)}
          mobileNumber={formData.mobileNumber}
          fdata={formData}
          sfdata={setFormData}
          stdata={setStaticFormData}
          delUuid={() => setProcessUuid('')}
        />
      )}
      {showOtp === 'passwordChange' && (
        <OTPWrapper
          uuid={processUuid}
          close={() => setShowOtp(null)}
          mobileNumber={mobile}
          fdata={formData}
          sfdata={setFormData}
          isPwd={true}
          delUuid={() => setProcessUuid('')}
          setUuid={setProcessUuid}
          setShowPwdChange={() => setShowPwdChange(true)}
        />
      )}
      <Wrapper>
        <AnimatePresence>
          {showFullImage && (
            <BackDrop onClick={() => handleImageOpen('close')}>
              <FullImageContainer>
                <Image
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  isfull={1}
                  src={img || placeholder}
                  alt='Profile Image'
                />
              </FullImageContainer>
            </BackDrop>
          )}
        </AnimatePresence>
        <Title>{t('personal_info')}</Title>
        <AnimatePresence>
          <Form onSubmit={handleSubmit} className='intro_profile_1'>
            {isLoading || isFetching ? (
              <>
                <UploadSection>
                  <Skeleton
                    variant='rounded'
                    height='80%'
                    animation='wave'
                    sx={{
                      backgroundColor: COLORS.gray_l,
                      margin: '0',
                      borderRadius: '4px',
                      aspectRatio: '1',
                    }}
                  />
                </UploadSection>
                {Array.from({ length: 6 }).map((_, i) => (
                  <Skeleton
                    variant='rounded'
                    key={i}
                    height='2.75rem'
                    width='100%'
                    animation='wave'
                    sx={{
                      backgroundColor: COLORS.gray_l,
                      margin: '0',
                      borderRadius: '4px',
                    }}
                  />
                ))}
                <Btn disabled>{t('save')}</Btn>
                <Skeleton
                  variant='rounded'
                  height='1rem'
                  width='20%'
                  animation='wave'
                  sx={{
                    backgroundColor: COLORS.gray_l,
                    margin: '0',
                    borderRadius: '4px',
                  }}
                />
              </>
            ) : isError ? (
              <>
                <ErrorBlock>{t(errors[error.message])}</ErrorBlock>
                <TryAgain type='button' onClick={() => refetch()}>
                  {t('try_again')}
                </TryAgain>
              </>
            ) : isSuccess ? (
              <>
                <UploadSection
                  onMouseEnter={() => handleImageButton(true)}
                  onMouseLeave={() => handleImageButton(false)}
                >
                  {/* {img && <Background src={img} />} */}
                  <ChipsContainer>
                    {volunteerFlag && count > 0 && (
                      <ProfileChip
                        name={t('bonus')}
                        value={count}
                        icon={<Icon src={manat} />}
                        right={'7.5rem'}
                        loading={countLoading}
                      />
                    )}
                  </ChipsContainer>
                  {img && showImageButtons && (
                    <DeleteBtn
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, transition: { duration: 0.1 } }}
                      exit={{ opacity: 0 }}
                      onClick={handleDelete}
                    >
                      <DeleteOutlineRoundedIcon />
                    </DeleteBtn>
                  )}
                  <input
                    style={{ display: 'none' }}
                    id='uploadImg'
                    name='uploadImg'
                    type='file'
                    accept='image/*'
                    onChange={handleImg}
                  />
                  {showImageButtons && (
                    <AddImgBtn
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, transition: { duration: 0.1 } }}
                      exit={{ opacity: 0 }}
                      htmlFor='uploadImg'
                    >
                      <AddPhotoAlternateRoundedIcon />
                    </AddImgBtn>
                  )}
                  {isAddLoading ? (
                    <Spinner />
                  ) : (
                    <Image onClick={handleImageOpen} src={img || placeholder} alt='Profile Image' />
                  )}
                </UploadSection>
                <Label>{t('first_name')}</Label>
                <Input
                  value={formData.firstname}
                  onChange={handleChange}
                  placeholder={t('first_name')}
                  name='firstname'
                  autoFocus
                />
                {formData.firstname.length < 3 && <ErrorText>{t('invalid_first_name')}</ErrorText>}
                <Label>{t('last_name')}</Label>
                <Input
                  value={formData.lastname}
                  onChange={handleChange}
                  placeholder={t('last_name')}
                  name='lastname'
                />
                {formData.lastname.length < 3 && <ErrorText>{t('invalid_last_name')}</ErrorText>}
                <Label>{t('paternal_name')}</Label>
                <Input
                  value={formData?.middleName || ''}
                  onChange={handleChange}
                  placeholder={t('paternal_name')}
                  name='middleName'
                />
                {formData.middleName && formData?.middleName.length < 3 && (
                  <ErrorText>{t('invalid_paternal_name')}</ErrorText>
                )}
                <Label>{t('Email')}</Label>
                <Input
                  value={formData.email || ''}
                  onChange={handleChange}
                  placeholder={t('Email')}
                  name='email'
                />
                {!formData.email.match(emailRegex) && (
                  <ErrorText>{t('error_msg_email_valid')}</ErrorText>
                )}
                <Label>{t('mobile_number')}</Label>
                <NumberField>
                  <StaticBox>+{MOBILE_CODE}</StaticBox>
                  <NumberInput
                    format='## ### ## ##'
                    placeholder={t('mobile_number')}
                    value={formData.mobileNumber}
                    name='mobileNumber'
                    onValueChange={(values) => {
                      setFormData({
                        ...formData,
                        mobileNumber: validateMobileNumberPrefix(values.value),
                      });
                      setIsBtnDisabled(
                        btnDisable({
                          ...formData,
                          mobileNumber: validateMobileNumberPrefix(values.value),
                        })
                      );
                    }}
                  />
                </NumberField>
                {formData.mobileNumber.length !== 9 && (
                  <ErrorText>{t('error_msg_mobile_phone')}</ErrorText>
                )}
                <Label>{t('id_pin')}</Label>
                <Input
                  value={formData.pin}
                  onChange={handleChange}
                  placeholder={t('id_pin')}
                  disabled
                  name='pin'
                />
                <Label>{t('promo_code')}</Label>
                <Input
                  value={formData.referal}
                  onChange={handleChange}
                  placeholder={t('promo_code')}
                  disabled
                  name='referal'
                />
                <Label>{t('birth_date')}</Label>
                <Input
                  value={formData.dateOfBirth || ''}
                  onChange={handleChange}
                  name='dateOfBirth'
                  type='date'
                  min={maxAge}
                  max={thisYear}
                />

                <Btn whileTap={{ scale: 0.95 }} disabled={isBtnDisabled[0] || !isFormEdited}>
                  {isEditLoading ? <Spinner /> : t('save')}
                </Btn>
                {isCheckLoading ? (
                  <Spinner />
                ) : (
                  <FormFooter>
                    <PDFLink profile={1} href={license} target={'_blank'}>
                      {t('license_a')}
                    </PDFLink>
                    <FooterLink className='intro_profile_2' onClick={handlePasswordCheck}>
                      {t('change_password')}
                    </FooterLink>
                  </FormFooter>
                )}
              </>
            ) : null}
          </Form>
        </AnimatePresence>
      </Wrapper>
    </Container>
  );
};

export default Profile;
