import React from 'react'
import { placeholder } from '../../../../assets/images'
import Skeleton from '@mui/material/Skeleton'
import { UserImgContainer, UserImg } from './style'

export function UserImageContainer(props) {
  return (
    <UserImgContainer>
      {props.isLoading || props.isFetching ? (
        <Skeleton
          variant='circular'
          sx={{
            bgcolor: props.isLanding ? 'grey.200' : 'grey.200'
          }}
          width='100%'
          height='100%'
          animation='wave'
        />
      ) : (
        <UserImg src={props.img || placeholder} alt='User Image' />
      )}
    </UserImgContainer>
  )
}
