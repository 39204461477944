import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { Img as Image, ModalImg } from '../../app/components/containers/products/style';
import { Img } from '../../app/components/containers/subheader/style';
import { Img as DBImg } from '../../app/views/dashboard/style';
import { Icon } from '../../app/views/profile/style';
import {
  ORG_1001000,
  abbLogo,
  adyLogo,
  arrow,
  asanLogoColor,
  asanPR,
  azalLogo,
  bankRespublikaLogo,
  cityParkLogo,
  conventionCenterLogo,
  expressLogo,
  mallenomLogo,
  manat,
  mastercardLogo,
  parkBulvarLogo,
  parkingPR,
  parqourLogo,
  person,
  petrolPR,
  premiumLogo,
  rabiteLogo,
  turanBankLogo,
  wallet,
} from '../../assets/images';

export const mobileNumberPrefixes = ['10', '12', '50', '51', '55', '60', '70', '77', '99'];

export const emailDomains = {
  at: [
    '@gmail.com',
    '@yahoo.com',
    '@outlook.com',
    '@mail.ru',
    '@mail.com',
    '@hotmail.com',
    '@icloud.com',
    '@bk.ru',
    '@inbox.com',
    '@inbox.ru',
    '@list.ru',
    '@yandex.com',
    '@yandex.ru',
  ],
  noat: [
    'gmail.com',
    'yahoo.com',
    'outlook.com',
    'mail.ru',
    'mail.com',
    'hotmail.com',
    'icloud.com',
    'bk.ru',
    'inbox.com',
    'inbox.ru',
    'list.ru',
    'yandex.com',
    'yandex.ru',
  ],
  dotend: ['.com', '.ru', '.az'],
  end: ['com', 'ru', 'az'],
  domains: [
    'gmail',
    'mail',
    'yahoo',
    'outlook',
    'hotmail',
    'bk',
    'list',
    'inbox',
    'icloud',
    'yandex',
  ],
};

export const languages = [
  {
    code: 'en',
    name: 'English',
    countryCode: 'gb',
    shortName: 'EN',
  },
  {
    code: 'az',
    name: 'Azərbaycan Dili',
    countryCode: 'az',
    shortName: 'AZ',
  },
];

export const recoverTypesList = [
  { id: 1, name: 'recover_with_email', type: 'email' },
  { id: 2, name: 'recover_with_number', type: 'mobileNumber' },
];

export const menuList = [
  {
    id: 1,
    name: 'services',
    icon: () => <HomeRoundedIcon />,
    link: '/dashboard',
  },
  {
    id: 2,
    name: 'my_cards',
    icon: () => <CreditCardRoundedIcon />,
    link: '/dashboard/cards',
  },
  {
    id: 3,
    name: 'history',
    icon: () => <CalendarMonthRoundedIcon />,
    link: '/dashboard/history',
  },
  {
    id: 4,
    name: 'personal_info',
    icon: () => <PersonRoundedIcon />,
    link: '/dashboard/profile',
  },
];

export const faqData = [
  {
    name: 'panel1',
    aria: 'panel1d-content',
    id: 'panel1d-header',
    title: 'faq_q1',
    desc: 'faq_q1_desc',
  },
  {
    name: 'panel2',
    aria: 'panel2d-content',
    id: 'panel2d-header',
    title: 'faq_q2',
    desc: 'faq_q2_desc',
  },
  {
    name: 'panel3',
    aria: 'panel3d-content',
    id: 'panel3d-header',
    title: 'faq_q3',
    desc: 'faq_q3_desc',
  },
  {
    name: 'panel4',
    aria: 'panel4d-content',
    id: 'panel4d-header',
    title: 'faq_q4',
    desc: 'faq_q4_desc',
  },
  {
    name: 'panel5',
    aria: 'panel5d-content',
    id: 'panel5d-header',
    title: 'faq_q5',
    desc: 'faq_q5_desc',
  },
];

export const subheaderCards = [
  {
    key: 1,
    title: 'sh_card_1_title',
    desc: 'sh_card_1_desc',
    icon: <Img src={wallet} alt='Wallet Icon' />,
  },
  {
    key: 2,
    title: 'sh_card_2_title',
    desc: 'sh_card_2_desc',
    icon: <Img src={person} alt='Person Icon' />,
  },
  {
    key: 3,
    title: 'sh_card_3_title',
    desc: 'sh_card_3_desc',
    icon: <Img src={arrow} alt='Arrow Icon' />,
  },
];

export const navList = [
  {
    name: 'about_us',
    link: '#about_us',
  },
  {
    name: 'partners',
    link: '#partners',
  },
  {
    name: 'contact',
    link: '#contact',
  },
  {
    name: 'faq',
    link: '#faq',
  },
];

export const productList = [
  {
    key: 1,
    title: 'pr_1_title',
    desc: 'pr_1_desc',
    short: 'pr_1_short',
    img: <Image src={asanPR} alt='Product Asan' />,
    modalImg: <ModalImg src={asanPR} alt='Product Asan' />,
    deco1: { top: '0', left: '3%' },
    deco2: { top: '30%', left: '1%' },
    deco3: { top: '-10%', left: '80%', rotate: '90deg' },
    order: true,
  },
  {
    key: 2,
    title: 'pr_2_title',
    desc: 'pr_2_desc',
    short: 'pr_2_short',
    img: <Image src={petrolPR} alt='Product Petrol' />,
    modalImg: <ModalImg src={petrolPR} alt='Product Petrol' />,
    deco1: { top: '0', left: '55%' },
    deco2: { top: '35%', left: '70%' },
    deco3: { top: '25%', left: '95%' },
    order: false,
  },
  {
    key: 3,
    title: 'pr_3_title',
    desc: 'pr_3_desc',
    short: 'pr_3_short',
    img: <Image src={parkingPR} alt='Product Parking' />,
    modalImg: <ModalImg src={parkingPR} alt='Product Parking' />,
    deco1: { top: '0', left: '3%' },
    deco2: { top: '30%', left: '20%' },
    deco3: { top: '0', left: '80%' },
    order: true,
  },
  // {
  //   key: 4,
  //   title: 'pr_4_title',
  //   desc: 'pr_4_desc',
  //   short: 'pr_4_short',
  //   img: <Image src={cardPR} alt='Product Card' />,
  //   modalImg: <ModalImg src={cardPR} alt='Product Card' />,
  //   deco1: { top: '0', left: '92%' },
  //   deco2: { top: '25%', left: '52%' },
  //   deco3: { top: '-15%', left: '30%', rotate: '90deg' },
  //   order: false
  // }
];

export const allowedImgTypes = ['image/jpg', 'image/jpeg', 'image/png'];

export const passwordValidationFields = [
  { name: 'isLenghtTrue', msg: 'pwd_is_length_true' },
  { name: 'isNumberTrue', msg: 'pwd_is_number_true' },
  { name: 'isSymbolTrue', msg: 'pwd_is_sybmol_true' },
  { name: 'isCapitalTrue', msg: 'pwd_is_capital_true' },
  { name: 'isLetterTrue', msg: 'pwd_is_letter_true' },
];

export const organizationImages = [{ id: 1001000, img: <DBImg src={ORG_1001000} alt={1001000} /> }];

export const carPlateFormats = [
  { type: 'nnssnnn', allow: [2, 5] },
  { type: 'nnnssnnn', allow: [3, 6] },
  { type: 'nnnsnnn', allow: [3, 5] },
  { type: 'snnnss', allow: [1, 5] },
  { type: 'nnsnnn', allow: [3, 4] },
  { type: 'snnnnnn', allow: [1, 5] },
];

export const partnersList = [
  { alt: 'Asan Logo', link: 'https://asan.gov.az/az', img: asanLogoColor },
  { alt: 'ABB Logo', link: 'https://abb-bank.az', img: abbLogo },
  {
    alt: 'Premium Bank Logo',
    link: 'https://www.premiumbank.az/',
    img: premiumLogo,
  },
  {
    alt: 'Master Card Logo',
    link: 'https://www.mastercard.az/az-az.html',
    img: mastercardLogo,
  },
  { alt: 'Azal Logo', link: 'https://www.azal.az/az/', img: azalLogo },
  { alt: 'Mallenom Logo', link: 'https://www.mallenom.com/', img: mallenomLogo },
  { alt: 'Rabite Logo', link: 'https://www.rabitabank.com/', img: rabiteLogo },
  { alt: 'Express Bank Logo', link: 'https://www.expressbank.az/', img: expressLogo },
  { alt: 'Bank Respublika Logo', link: 'https://www.bankrespublika.az/', img: bankRespublikaLogo },
  { alt: 'Turan Bank Logo', link: 'https://www.turanbank.az/', img: turanBankLogo },
  { alt: 'Park Bulvar Logo', link: 'https://www.parkbulvar.az/', img: parkBulvarLogo },
  { alt: 'City Park Logo', link: 'https://citypark.az/', img: cityParkLogo },
  { alt: 'Parqour Logo', link: 'https://www.parqour.com/', img: parqourLogo },
  { alt: 'ADY Logo', link: 'https://ticket.ady.az/', img: adyLogo },
  {
    alt: 'Baku Convention Center Logo',
    link: 'https://bakuconventioncenter.com/',
    img: conventionCenterLogo,
  },
];

export const socialMediaLinks = {
  facebook: 'https://www.facebook.com/tezode',
  instagram: 'https://www.instagram.com/tezode.az/',
  twitter: 'https://twitter.com/Tezode_',
};

export const i18order = [
  'cookie',
  'htmlTag',
  'localStorage',
  'sessionStorage',
  'navigator',
  'path',
  'subdomain',
];

export const serviceRegisterErrorCodes = [1053, 1055];

export const chips = [
  {
    name: 'referal',
    value: '256',
    icon: <PeopleAltRoundedIcon sx={{ height: '100%' }} />,
    right: '0.5rem',
  },
  {
    name: 'bonus',
    value: '0',
    icon: <Icon src={manat} />,
    right: '7.5rem',
  },
  // { name: 'bonus', value: '256', icon: , right: '7.5rem' }
  //bonusLoading
];
