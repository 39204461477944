import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { MOBILE_CODE } from '../../../../data/resources/constants';
import { checkRefferal, signUp } from '../../../../services/api/authApi';
import { capitalizeWord } from '../../../../utils/helpers/helpers';
import { useRegister } from '../../../../utils/hooks/useRegister';
import EyeContainer from '../login-form/EyeContainer';
import {
  BoxCell,
  ButtonCell,
  Cell,
  Checkbox,
  Container,
  Field,
  Label,
  PDFLink,
  Text,
} from '../login-form/style';
import { EyeIconContainer, Info, Input } from '../login-wrapper/style';
import OTPWrapper from '../otp-wrapper';
import PasswordTooltip from '../register-wrapper/PasswordTooltip';
import MobileField from './MobileField';
import { RegisterButton } from './RegisterButton';

import license from '../../../../assets/pdf/license-agreement.pdf';
import { opRefRegex } from '../../../../data/resources/regex';
import { useLogger } from '../../../../utils/hooks/useLogger';
import { useToast } from '../../../../utils/hooks/useToast';
import InfoModal from '../../modals/info';
import { Desc } from '../../modals/info/style';

const RegisterForm = () => {
  const { t } = useTranslation();
  const [showPasswordValidation, setShowPasswordValidation] = useState(false);
  const logger = useLogger();

  const [processUuid, setProcessUuid] = useState('');
  const [showOtpWrapper, setShowOtpWrapper] = useState(false);

  const {
    formData,
    setFormData,
    handleOnChange,
    showPassword,
    setShowPassword,
    showConfirmPassword,
    setShowConfirmPassword,
    passwordValidation,
    isBtnDisabled,
    isLicenseAccepted,
    setIsLicenseAccepted,
  } = useRegister();

  const [showInfo, setShowInfo] = useState(false);
  const { setError } = useToast();

  function register(formData) {
    const { firstName, lastName, middleName, email, mobileNumber, password, pin, referralCode } =
      formData;
    const data = {
      firstName: capitalizeWord(firstName),
      lastName: capitalizeWord(lastName),
      middleName: capitalizeWord(middleName),
      email: email.toLowerCase(),
      mobileNumber: MOBILE_CODE + mobileNumber,
      pin: pin.toUpperCase(),
      password,
      referralCode: referralCode || null,
    };

    logger('Registration starting', data);
    mutate(data);
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (isBtnDisabled.value) return;

    if (!formData?.referralCode || formData.referralCode.match(opRefRegex)) {
      register(formData);
    } else {
      checkMutate(formData.referralCode.toUpperCase());
    }
  };

  const { mutate, isLoading } = useMutation(signUp, {
    onError: (error) => {
      setError(error);
      logger('Error on signing up', error);
    },
    onSuccess: ({ data }) => {
      setProcessUuid(data.processUuid);
      logger('ProcessUUID on registration', data.processUuid);
      setShowOtpWrapper(true);
    },
  });

  const { mutate: checkMutate, isLoading: checkLoading } = useMutation(checkRefferal, {
    onError: (error) => {
      setError(error);
      logger('Error on checking referral', error);
    },
    onSuccess: ({ data: isTrue }) => {
      if (isTrue) {
        logger('Registration starting after checking referral', { ...formData, password: '' });
        register(formData);
      } else {
        logger('Referal code is not true (checked on referral/check)', { userPin: formData?.pin });
        setError(7007);
      }
    },
  });

  return (
    <Container onSubmit={handleOnSubmit}>
      {showOtpWrapper && (
        <OTPWrapper
          uuid={processUuid}
          close={() => setShowOtpWrapper(false)}
          mobileNumber={formData.mobileNumber}
          fdata={formData}
          sfdata={setFormData}
          isRegister={true}
          delUuid={() => setProcessUuid('')}
        />
      )}
      <Cell>
        <Label>{t('first_name')}</Label>
        <Input
          value={formData.firstName}
          onChange={handleOnChange}
          name='firstName'
          placeholder={t('enter')}
          autoFocus
        />
      </Cell>
      <Cell>
        <Label>{t('last_name')}</Label>
        <Input
          value={formData.lastName}
          onChange={handleOnChange}
          name='lastName'
          placeholder={t('enter')}
        />
      </Cell>
      <Cell>
        <Label>{t('paternal_name')}</Label>
        <Input
          value={formData.middleName}
          onChange={handleOnChange}
          name='middleName'
          placeholder={t('enter')}
        />
      </Cell>
      <Cell>
        <Label>{t('Email')}</Label>
        <Input
          value={formData.email}
          onChange={handleOnChange}
          name='email'
          placeholder={t('email_example')}
          autoComplete='off'
        />
      </Cell>
      <Cell>
        <Label>{t('id_pin')}</Label>
        <Input
          value={formData.pin}
          onChange={handleOnChange}
          name='pin'
          placeholder={t('enter')}
          maxLength={7}
        />
      </Cell>
      <Cell>
        <Label>{t('mobile_number')}</Label>
        <MobileField number={formData.mobileNumber} setNumber={setFormData} />
      </Cell>
      <Cell>
        <Label>{t('Password')}</Label>
        <Field>
          <Input
            isPasswordSame={
              formData.confirmPassword === formData.password &&
              formData.password &&
              passwordValidation.isEverythingTrue
            }
            value={formData.password}
            onChange={handleOnChange}
            name='password'
            placeholder={t('enter')}
            type={showPassword ? 'text' : 'password'}
            onFocus={() => setShowPasswordValidation(true)}
            onBlur={() => setShowPasswordValidation(false)}
          />
          <EyeContainer field={formData.password} open={showPassword} setOpen={setShowPassword} />
          <AnimatePresence>
            <PasswordTooltip show={showPasswordValidation} validation={passwordValidation} />
          </AnimatePresence>
        </Field>
      </Cell>
      <Cell>
        <Label>{t('confirm_password')}</Label>
        <Field>
          <Input
            isPasswordSame={
              formData.confirmPassword === formData.password &&
              formData.confirmPassword &&
              passwordValidation.isEverythingTrue
            }
            value={formData.confirmPassword}
            onChange={handleOnChange}
            name='confirmPassword'
            placeholder={t('enter')}
            type={showConfirmPassword ? 'text' : 'password'}
          />
          <EyeContainer
            field={formData.confirmPassword}
            open={showConfirmPassword}
            setOpen={setShowConfirmPassword}
          />
        </Field>
      </Cell>
      <Cell>
        <Label>{t('promo_code')}</Label>
        <Field>
          <Input
            value={formData.referralCode}
            onChange={handleOnChange}
            name='referralCode'
            placeholder={t('enter')}
            maxLength={7}
          />
          <EyeIconContainer
            onHoverStart={() => setShowInfo(true)}
            onHoverEnd={() => setShowInfo(false)}
            onTouchStart={() => setShowInfo(true)}
            onTouchEnd={() => setShowInfo(false)}
          >
            <Info />
          </EyeIconContainer>
          {showInfo && (
            <InfoModal title={'promo_code'}>
              <Desc>{t('faq_q4_desc')}</Desc>
            </InfoModal>
          )}
        </Field>
      </Cell>
      <BoxCell>
        <Checkbox
          value={isLicenseAccepted}
          onChange={() => setIsLicenseAccepted((prev) => !prev)}
        />
        <Text>
          {t('license_p1')}
          <PDFLink href={license} target='_blank'>
            {t('license_a')}
          </PDFLink>
          {t('license_p2')}
        </Text>
      </BoxCell>
      <ButtonCell isButtonCell={'true'}>
        <RegisterButton
          t={t}
          isBtnDisabled={isBtnDisabled}
          isLoading={isLoading || checkLoading}
          text={'Register'}
        ></RegisterButton>
      </ButtonCell>
    </Container>
  );
};

export default RegisterForm;
