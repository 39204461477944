import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Joyride, { ACTIONS } from 'react-joyride';
import { COLORS } from '../../assets/styles/theme';

export const useIntro = (name, steps, check = true) => {
  const { t } = useTranslation();

  steps = steps.map((step) => ({ ...step, content: t(step.content) }));

  const [run, setRun] = useState(false);

  const styles = {
    primaryColor: COLORS.primary,
    textColor: COLORS.primary_d,
  };

  const handleCallback = (data) => {
    const { action } = data;

    if ([ACTIONS.CLOSE, ACTIONS.RESET].includes(action)) {
      localStorage.setItem(`intro-${name}`, 'done');
    }
  };

  useEffect(() => {
    const status = localStorage.getItem(`intro-${name}`);
    if (!status) {
      setRun(check);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Joyride
      steps={steps}
      styles={{ options: styles }}
      run={run}
      continuous
      showSkipButton
      callback={handleCallback}
      locale={{
        back: t('back'),
        close: t('close'),
        last: t('last'),
        next: t('next'),
        skip: t('skip'),
      }}
    />
  );
};
