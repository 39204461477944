import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../../../assets/styles/style/styled';
import { LOGOUT } from '../../../../data/resources/actionTypes';
import { initialPasswordValidation } from '../../../../data/resources/initialStates';
import { passwordUpdate, signOut } from '../../../../services/api/authApi';
import { getIsPwdBtnDisabled } from '../../../../utils/helpers/getIsBtnDisabled';
import { validateFields } from '../../../../utils/helpers/validateFields';
import { validatePassword } from '../../../../utils/helpers/validatePassword';
import { useAuth } from '../../../../utils/hooks/useAuth';
import { useLogger } from '../../../../utils/hooks/useLogger';
import { useToast } from '../../../../utils/hooks/useToast';
import BackDrop from '../../layout/backdrop';
import ToolTip from '../../modals/tooltip';
import { Btn, EyeIconContainer, Field, FooterLink, Input } from '../login-wrapper/style';
import PasswordTooltip from '../register-wrapper/PasswordTooltip';
import { Container, Form, Title } from './style';

const PwdChangeWrapper = ({ close, delUuid, uuid, recovery }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    user: { accessToken, pin },
  } = useAuth();

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPwdValidation, setShowPwdValidation] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(initialPasswordValidation);
  const [validation, setValidation] = useState([true, '']);
  const [btnTooltip, setBtnTooltip] = useState(false);
  const { setSuccess, setError } = useToast();
  const logger = useLogger();

  const handlePwdChange = (e) => {
    setPassword(e.target.value);
    const data = {
      password: e.target.value,
      confirmPassword,
    };
    setPasswordValidation(validatePassword(validateFields(e)));
    setValidation(getIsPwdBtnDisabled(data));
  };

  const handleConfirmPwdChange = (e) => {
    setConfirmPassword(e.target.value);
    const data = {
      password,
      confirmPassword: e.target.value,
    };
    setValidation(getIsPwdBtnDisabled(data));
  };

  const { mutate, isLoading } = useMutation((data) => passwordUpdate(data), {
    onSuccess: ({ data }) => {
      setSuccess('password_changed_successfully');
      logger('Password updated successfully', data);
      end();
    },
    onError: (err) => {
      logger('Password update error', err);
      setError(err);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!password || !confirmPassword || validation[0] || password !== confirmPassword) {
      // setFeedback(7003, 'error')
      setError(7003);
      return;
    }
    const data = {
      password,
      processUuid: uuid,
    };
    mutate(data);
  };

  const { dispatch } = useAuth();

  const { mutate: logOut } = useMutation(signOut, {
    onError: (err) => console.error(err),
    onSuccess: () => {
      dispatch({ type: LOGOUT });
      logger('User signed out after password change', { userPin: pin });
      navigate('/');
    },
  });

  const end = () => {
    delUuid();
    close();
    if (recovery) {
      navigate('/', { state: 'password_changed_successfully' });
    } else {
      logOut(accessToken);
    }
  };

  const goBack = () => {
    delUuid();
    close();
    if (recovery) {
      navigate('/');
    }
  };

  return (
    <BackDrop>
      <Container onClick={(e) => e.stopPropagation()}>
        <Title>{t('change_password')}</Title>
        <Form onSubmit={handleSubmit}>
          <Field>
            <EyeIconContainer>
              {password &&
                (showPassword ? (
                  <AiOutlineEyeInvisible
                    onClick={() => setShowPassword(false)}
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <AiOutlineEye
                    onClick={() => setShowPassword(true)}
                    style={{ cursor: 'pointer' }}
                  />
                ))}
            </EyeIconContainer>
            <Input
              value={password}
              onChange={handlePwdChange}
              onFocus={() => setShowPwdValidation(true)}
              onBlur={() => setShowPwdValidation(false)}
              name='password'
              type={showPassword ? 'text' : 'password'}
              placeholder={t('new_password')}
            />
          </Field>
          <AnimatePresence>
            <PasswordTooltip show={showPwdValidation} validation={passwordValidation} />
          </AnimatePresence>
          <Field>
            <EyeIconContainer>
              {confirmPassword &&
                (showConfirmPassword ? (
                  <AiOutlineEyeInvisible
                    onClick={() => setShowConfirmPassword(false)}
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <AiOutlineEye
                    onClick={() => setShowConfirmPassword(true)}
                    style={{ cursor: 'pointer' }}
                  />
                ))}
            </EyeIconContainer>
            <Input
              value={confirmPassword}
              onChange={handleConfirmPwdChange}
              name='confirmPassword'
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder={t('confirm_new_password')}
            />
          </Field>
          <Btn
            disabled={validation[0]}
            whileTap={{ scale: validation[0] ? 1 : 0.95 }}
            onHoverStart={() => setBtnTooltip(true)}
            onHoverEnd={() => setBtnTooltip(false)}
          >
            {isLoading ? <Spinner /> : t('approve')}
          </Btn>
          <AnimatePresence>
            {btnTooltip && validation[0] && <ToolTip bot='2rem'>{t(validation[1])}</ToolTip>}
          </AnimatePresence>
          <FooterLink onClick={goBack}>{t('cancel')}</FooterLink>
        </Form>
      </Container>
    </BackDrop>
  );
};

export default PwdChangeWrapper;
