import { STORAGE_KEYS } from '../../data/resources/constants'

const commonColors = {
  primary: '#28a745',
  primary_l: '#1AB53E',
  primary_d: '#118e2e',
  primary_ll: '#cdffd9',
  secondary: '#008aff',
  secondary_l: '#0093e4',
  secondary_d: '#0078ff',
  accent: '#223C76', //223C76
  accent_l: '#3456A2',
  accent_d: '#102554',
  success: '#009773',
  success_l: '#d5fff5',
  success_d: '#008061',
  warning: '#FF9941',
  error: '#e9001a',
  error_l: '#ffced3',
  error_ll: '#fff0f1',
  error_d: '#d10017'
}
// colors - for light and dark
const lightColors = {
  background: '#f6f8fb',
  background_l: '#fff',
  background_d: '#f6f9ff',
  black: '#2c2c2c',
  text: '#212121',
  text_l: '#313131',
  text_d: '#111111',
  gray: '#ccc',
  gray_l: '#dedede',
  gray_d: '#999999',
  nav_col: 'hsl(0deg 0% 90% / 60%)',
  ...commonColors
}
const darkColors = {
  background: '#303030',
  background_l: '#424242',
  background_d: '#212121',
  black: '#2c2c2c',
  text: '#212121',
  text_l: '#313131',
  text_d: '#111111',
  gray: '#ccc',
  gray_l: '#dedede',
  gray_d: '#999999',
  // nav_col: 'hsl(0deg 0% 100% / 0%)',
  nav_col: 'white',
  ...commonColors
}

export const COLORS = localStorage.getItem(STORAGE_KEYS.THEME) === 'dark' ? darkColors : lightColors

// breakpoints
export const BREAK_POINTS = {
  xxs: 'max-width: 250px', // smaller than 250
  xs: 'max-width: 370px', // from 250 to 370
  s: 'max-width: 460px', // from 370 to 460
  m: 'max-width: 800px', // from 460 to 800
  m_min: 'min-width: 800px',
  bm: 'min-width: 800px', // from 460 to 800
  l: 'max-width: 1200px', // from 800 to 1200
  l_min: 'min-width: 1200px',
  xl: 'max-width: 2000px', // from 1200 to 2000
  xxl: 'min-width: 2000px' // bigger than 2000
}

export const LARGE_BREAKPOINT = Number(BREAK_POINTS.l.split(' ')[1].slice(0, -2))
export const MOBILE_BREAKPOINT = Number(BREAK_POINTS.m.split(' ')[1].slice(0, -2))

const lightThemeShadows = {
  so_low: '0px 0px 15px -12px ' + lightColors.black,
  low: '0px 0px 15px -10px ' + lightColors.black,
  mid: '0px 0px 15px -8px ' + lightColors.black,
  high: '0px 0px 15px -6px ' + lightColors.black,
  b_low: '0px 0px 15px -8px ' + lightColors.background_l,
  b_mid: '0px 0px 15px -6px ' + lightColors.background_l
}
const darkThemeShadows = {
  low: '0px 0px 15px -5px ' + darkColors.black,
  mid: '0px 0px 15px -2px ' + darkColors.black,
  high: '0px 0px 15px 2px ' + darkColors.black
}

export const SHADOWS =
  localStorage.getItem(STORAGE_KEYS.THEME) === 'dark' ? darkThemeShadows : lightThemeShadows
