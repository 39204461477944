import styled from 'styled-components'
import { BREAK_POINTS, COLORS } from '../../../assets/styles/theme'

export const Container = styled.main`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 5rem 0;
`
export const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
`
export const Img = styled.img`
  height: 100%;
`
export const Title = styled.h1`
  font-size: 16rem;
  color: ${COLORS.background_l};
  text-shadow: 1px 2px 35px ${COLORS.gray_l};
  @media (${BREAK_POINTS.m}) {
    font-size: 12rem;
  }
  @media (${BREAK_POINTS.s}) {
    font-size: 9rem;
  }
`
export const Content = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  @media (${BREAK_POINTS.m}) {
    width: 80%;
  }
  @media (${BREAK_POINTS.s}) {
    width: 90%;
  }
`
export const ContentTitle = styled.h2``
export const Text = styled.span`
  text-align: center;
`
export const Background = styled.img`
  position: absolute;
  z-index: -3;
  font-size: 12rem;
  @media (${BREAK_POINTS.l}) {
    width: 95%;
  }
`
export const Oval = styled.img`
  position: absolute;
  z-index: -3;
  top: ${({ top }) => (top ? top : '0')};
  left: ${({ left }) => (left ? left : '0')};
`
