/* eslint-disable react-hooks/exhaustive-deps */
import { Backdrop, CircularProgress } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import styled from 'styled-components';
import { BREAK_POINTS, COLORS } from '../../../assets/styles/theme';
import {
  connectBulk,
  getBulkStatus,
  getConnectedPaymentsByEmail,
} from '../../../services/api/cardApi';
import { getMerchants } from '../../../services/api/merchantApi';
import { formatCardNumber } from '../../../utils/helpers/helpers';
import {
  formatPlate,
  validatePlate,
  validatePlateField,
} from '../../../utils/helpers/validatePlate';
import { useAuth } from '../../../utils/hooks/useAuth';
import { useFetch } from '../../../utils/hooks/useFetch';
import { useGetCardsByEmail } from '../../../utils/hooks/useGetCardsByEmail';
import { useLogger } from '../../../utils/hooks/useLogger';
import { useToast } from '../../../utils/hooks/useToast';
import { Button } from '../../components/features/login-form/style';
import { Input } from '../../components/features/login-wrapper/style';
import { useServiceStore } from '../../../context/ServiceIdProvider';

const Container = styled.main`
  width: 100%;
  height: 75vh;
  display: flex;
  gap: 1rem;
  @media (${BREAK_POINTS.m}) {
    flex-direction: column;
    min-height: 75vh;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 0.5rem;kur
`;
const Left = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const Right = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const ServicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const AddContainer = styled.form`
  display: flex;
  align-items: center;
`;

const tableStyles = {
  '& .MuiDataGrid-cell': {
    cursor: 'pointer',
  },
  boxShadow: 1,
  '& .MuiDataGrid-cell:hover': {
    color: COLORS.accent,
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
    width: '0.3em',
    height: '0.3em',
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.accent,
      light: COLORS.accent_l,
      dark: COLORS.accent_d,
    },
  },
});

const YDMView = () => {
  const { t } = useTranslation();

  const { YDM_SERVICE_ID: ydmServiceId } = useServiceStore();
  const lang = {
    // Root
    noRowsLabel: t('no_result_table'),
    noResultsOverlayLabel: t('no_result_table'),
    errorOverlayDefaultLabel: t('error_something_went_wrong'),

    // Columns panel text
    columnsPanelTextFieldLabel: t('toolbar_search_column'),
    columnsPanelTextFieldPlaceholder: t('search'),
    columnsPanelDragIconLabel: t('toolbar_search_column'),
    columnsPanelShowAllButton: t('toolbar_search_column_show'),
    columnsPanelHideAllButton: t('toolbar_search_column_hide'),

    // Filter panel text
    filterPanelAddFilter: t('toolbar_filter_add'),
    filterPanelDeleteIconLabel: t('toolbar_filter_add'),
    filterPanelLinkOperator: t('toolbar_filter_link_operators'),
    filterPanelOperators: t('toolbar_filter_operators'),

    // TODO v6: rename to filterPanelOperator
    filterPanelOperatorAnd: t('toolbar_op_and'),
    filterPanelOperatorOr: t('toolbar_op_or'),
    filterPanelColumns: t('toolbar_op_cols'),
    filterPanelInputLabel: t('toolbar_op_cols_input'),
    filterPanelInputPlaceholder: t('toolbar_op_value'),

    // Filter operators text
    filterOperatorContains: t('toolbar_val_contains'),
    filterOperatorEquals: t('toolbar_val_equals'),
    filterOperatorStartsWith: t('toolbar_val_stw'),
    filterOperatorEndsWith: t('toolbar_val_enw'),
    filterOperatorIs: t('toolbar_val_eq'),
    filterOperatorNot: t('toolbar_val_neq'),
    filterOperatorAfter: t('toolbar_val_after'),
    filterOperatorOnOrAfter: t('toolbar_val_on_after'),
    filterOperatorBefore: t('toolbar_val_before'),
    filterOperatorOnOrBefore: t('toolbar_val_on_before'),
    filterOperatorIsEmpty: t('toolbar_val_emp'),
    filterOperatorIsNotEmpty: t('toolbar_val_nemp'),

    // Column menu text
    columnMenuLabel: t('toolbar_col_menu'),
    columnMenuShowColumns: t('toolbar_col_menu_show'),
    columnMenuFilter: t('toolbar_col_add_filter'),
    columnMenuHideColumn: t('toolbar_col_hide'),
    columnMenuUnsort: t('toolbar_col_unsort'),
    columnMenuSortAsc: t('toolbar_col_sort_asc'),
    columnMenuSortDesc: t('toolbar_col_sort_desc'),

    // Column header text
    columnHeaderFiltersTooltipActive: (count) => t('toolbar_columns_active', { count }),
    columnHeaderFiltersLabel: t('toolbar_col_filters_show'),
    columnHeaderSortIconLabel: t('toolbar_col_filters_sort'),
  };

  const {
    user: { pin },
  } = useAuth();

  const { setSuccess, setError } = useToast();

  const { isLoading: merchantLoading } = useFetch('merchants', getMerchants, {
    onError: (err) => console.error(err),
    onSuccess: ({ data }) => {
      const parkingServices = data.services.find((item) => item.id === ydmServiceId);
      let operations = [];

      parkingServices.organizations.forEach((org) => {
        operations.push({
          id: org.id,
          organizationId: org.id,
          name: org.label,
        });
      });
      setServices(operations);
    },
  });

  const { cardsLoading } = useGetCardsByEmail((data) => setCards(data));

  const [chain, setChain] = useState([]);
  const [staticChain, setStaticChain] = useState([]);

  function createChain(plates, cards) {
    let result = chain.filter((item) => !plates.map((e) => e.plate).includes(item.plate));

    for (let i = 0; i < plates.length; i++) {
      for (let j = 0; j < cards.length; j++) {
        const obj = {
          id: plates[i].id,
          plate: plates[i].plate,
          card: cards[j].id,
        };
        result.push(obj);
      }
    }
    return result;
  }

  const { isLoading: platesLoading } = useFetch('plates', () => getConnectedPaymentsByEmail(), {
    onError: (err) => setError(err),
    onSuccess: ({ data }) => {
      const parkingData = data.filter(
        (item) =>
          item.isActive === 'true' && item.serviceId === ydmServiceId && item.statusCode === 0
      );

      let res = [];
      parkingData.forEach((item) => {
        const { id, organizationId, serviceData, cardId } = item;
        res.push({ id, service: organizationId, plate: serviceData, card: cardId });
      });
      setChain(res);
      setStaticChain(res);
      setPlates(
        uniqBy(
          res.map((item) => ({ id: item.id, plate: item.plate })),
          (obj) => obj.plate
        )
      );
    },
  });

  const [plates, setPlates] = useState([]);
  const [selectedPlates, setSelectedPlates] = useState([]);

  const [cards, setCards] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);

  const [services, setServices] = useState([]);

  const [saveLoading, setSaveLoading] = useState(false);

  const [addMode, setAddMode] = useState({
    open: false,
    value: '',
  });

  const queryClient = useQueryClient();

  const checkStatus = (id) => {
    const fetchInterval = setInterval(async () => {
      const res = await getBulkStatus(id);
      if (res.status === 200) {
        setSuccess(8001);
        clearInterval(fetchInterval);
        setSelectedPlates([]);
        setSelectedCards([]);
        queryClient.refetchQueries();
        setSaveLoading(false);
        return;
      }

      if (res.status === 500) {
        setError(7002);
        clearInterval(fetchInterval);
        queryClient.refetchQueries();
        setSaveLoading(false);
        return;
      }
    }, 1000);
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const logger = useLogger();

  const { mutate } = useMutation((data) => connectBulk(data), {
    onError: (err) => console.error('ERROR TO CONNECT', err),
    onSuccess: async ({ data }) => {
      if (data.processUuid) {
        checkStatus(data.processUuid);
        logger('Parking Connect Bulk, Checking Status', {
          userPin: pin,
          processUUID: data.processUuid,
        });
      } else {
        await sleep(3000);
        setSuccess(8001);
        logger('Parking Connect Bulk, Success', { userPin: pin, selectedPlates, selectedCards });
        setSelectedPlates([]);
        setSelectedCards([]);
        queryClient.refetchQueries();
        setSaveLoading(false);
      }
    },
  });

  const handleSave = () => {
    const serviceId = ydmServiceId.toString();
    const serviceData = uniq(selectedPlates.map((item) => item.plate));
    const cardId = uniq(selectedCards.map((item) => item.id));

    setSaveLoading(true);

    mutate({
      serviceId,
      pin,
      serviceData,
      cardId,
    });
    logger('Parking connect started', {
      serviceId,
      pin,
      serviceData,
      cardId,
    });
  };

  const handleAddCarBtn = () => {
    setAddMode({ open: true, value: '' });
  };
  const handleCloseAddCar = () => {
    setAddMode({ open: false, value: '' });
  };
  const handleAddCar = (e) => {
    e.preventDefault();
    if (!validatePlate(addMode.value, plates)[0]) setError(validatePlate(addMode.value, plates)[1]);
    else {
      const newPlate = {
        id: new Date().valueOf(),
        plate: addMode.value.trim().toUpperCase(),
        cards: [],
      };
      setPlates((prev) => [...prev, newPlate]);
      setSelectedPlates((prev) => [...prev, newPlate]);
      setAddMode({ open: false, value: '' });
      setSuccess(8003);
    }
  };

  const plateColumns = [
    {
      field: 'plate',
      headerName: t('car_plates'),
      width: 250,
      valueFormatter: ({ value }) => formatPlate(value),
    },
  ];
  const cardColumns = [
    {
      field: 'cardNumber',
      headerName: t('my_cards'),
      width: 200,
      valueFormatter: ({ value }) => formatCardNumber(value),
    },
    {
      field: 'type',
      headerName: t('type'),
      width: 160,
    },
  ];
  const serviceColumns = [{ field: 'name', headerName: t('services'), width: 320 }];

  const handlePlateSelect = (ids) => {
    setSelectedPlates(plates.filter((item) => ids.includes(item.id)));
  };
  const handleCardSelect = (ids) => {
    const newCards = cards.filter((item) => ids.includes(item.id));
    setSelectedCards(newCards);
    if (selectedPlates.length && newCards.length) {
      setChain(createChain(selectedPlates, newCards));
    }
  };

  useEffect(() => {
    if (selectedPlates.length === 0) {
      setSelectedCards([]);
      setChain(staticChain);
    } else {
      const plates = selectedPlates.map((item) => item.plate);
      let cardIds = uniq(
        chain.filter((item) => plates.includes(item.plate)).map((item) => item.card)
      );
      setSelectedCards(cards.filter((item) => cardIds.includes(item.id)));
    }
  }, [selectedPlates]);

  useHotkeys('mod+shift+k', () => {
    if (addMode.open) {
      handleCloseAddCar();
    } else {
      handleAddCarBtn();
    }
  });

  useHotkeys('esc', () => {
    if (addMode.open) {
      handleCloseAddCar();
    }
  });

  return (
    <Container>
      <ThemeProvider theme={theme}>
        <Left>
          <Wrapper>
            <DataGrid
              localeText={lang}
              sx={tableStyles}
              rows={platesLoading ? [] : plates}
              columns={plateColumns}
              checkboxSelection
              hideFooter
              onSelectionModelChange={handlePlateSelect}
              selectionModel={selectedPlates.map((item) => item.id)}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'plate', sort: 'asc' }],
                },
              }}
            />
            {addMode.open ? (
              <AddContainer onSubmit={handleAddCar}>
                <Button
                  bg={COLORS.error_d}
                  bgh={COLORS.error}
                  onClick={handleCloseAddCar}
                  width='3rem'
                  radius='5px 0 0 5px'
                  margin='0'
                  type='button'
                >
                  X
                </Button>
                <Input
                  autoFocus
                  value={addMode.value}
                  onChange={(e) =>
                    setAddMode((prev) => ({ ...prev, value: validatePlateField(e) }))
                  }
                  placeholder='Add: 99XX999'
                  radius='0'
                />
                {addMode.value && (
                  <Button margin='0' type='submit' width='10rem' radius='0 5px 5px 0'>
                    {t('confirm')}
                  </Button>
                )}
              </AddContainer>
            ) : (
              <Button margin='0' radius='5px' onClick={handleAddCarBtn}>
                {t('add_new_plate')}
              </Button>
            )}
          </Wrapper>
          <Wrapper>
            <DataGrid
              localeText={lang}
              sx={tableStyles}
              isRowSelectable={() => selectedPlates.length}
              rows={cardsLoading ? [] : cards}
              columns={cardColumns}
              checkboxSelection
              hideFooter
              onSelectionModelChange={handleCardSelect}
              selectionModel={selectedCards.map((item) => item.id)}
            />
          </Wrapper>
        </Left>
        <Right>
          <ServicesWrapper>
            <DataGrid
              localeText={lang}
              sx={tableStyles}
              rows={merchantLoading ? [] : services}
              columns={serviceColumns}
              hideFooter
            />
          </ServicesWrapper>
          <Button
            style={{ marginBottom: '3rem' }}
            isdisabled={
              !selectedPlates.length || !selectedCards.length || saveLoading ? 'true' : 'false'
            }
            radius='5px'
            onClick={
              !selectedPlates.length || !selectedCards.length || saveLoading ? () => {} : handleSave
            }
          >
            {t('save')}
          </Button>
        </Right>
      </ThemeProvider>
      {saveLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={saveLoading}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
    </Container>
  );
};

export default YDMView;
