import styled from 'styled-components';
import { motion } from 'framer-motion';
import { BREAK_POINTS, COLORS, SHADOWS } from '../../../../assets/styles/theme';

export const SIDEBAR_WIDTH = '15rem';

export const Container = styled(motion.aside)`
  height: 100vh;
  width: ${SIDEBAR_WIDTH};
  // background-color: ${COLORS.black};
  background-image: linear-gradient(to left bottom, #2c2c2c, #303030, #353535, #393939, #3e3e3e);
  box-shadow: ${SHADOWS.mid};
  position: fixed;
  top: 0;
  color: ${COLORS.background_d};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 20;
  @media (${BREAK_POINTS.m}) {
    display: none;
  }
`;
export const LogoContainer = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
`;
export const Logo = styled.img`
  cursor: pointer;
  width: 70%;
`;
export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const MenuHeader = styled.h4`
  padding: 0 1rem;
`;
export const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  height: 3rem;
  gap: 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  background-color: ${({ isactivePage }) => (isactivePage ? COLORS.primary : 'transparent')};
  &:hover {
    background-color: ${COLORS.primary_d};
  }
`;
export const ItemIcon = styled.img``;
export const ItemName = styled.span``;
