import React from 'react'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { EyeIconContainer } from '../login-wrapper/style'

const EyeContainer = ({ field, open, setOpen }) => {
  return (
    <EyeIconContainer>
      {field &&
        (open ? (
          <AiOutlineEyeInvisible onClick={() => setOpen(false)} style={{ cursor: 'pointer' }} />
        ) : (
          <AiOutlineEye onClick={() => setOpen(true)} style={{ cursor: 'pointer' }} />
        ))}
    </EyeIconContainer>
  )
}

export default EyeContainer
