import { Alert } from '@mui/material'
import { createContext, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const initialAlertState = {
  value: null,
  type: 'error',
  width: 300,
  height: 100,
  isIcon: false,
  isTranslate: true,
  isErrorObject: true,
  isCode: true
}

const DEFAULT = {
  type: 'error',
  timeout: 5000,
  height: 100,
  width: 300,
  isIcon: false,
  isTranslate: true,
  containerBottom: '1rem',
  containerRight: '1rem',
  isErrorObject: true,
  isCode: true
}

// const errorCodes = {
//   401: 'error_email_password',
//   500: 'error_email_password',
//   6001: 'error_email_password',
//   6002: 'card_not_found',
//   6003: 'signature_error',
//   6004: 'request_error',
//   6006: 'error_otp_wrong',
//   6007: 'error_something_went_wrong',
//   6008: 'process_done',
//   6009: 'process_unknown',
//   6010: 'email_in_use',
//   6011: 'mobile_number_in_use',
//   6012: 'pin_in_use',
//   6013: 'error_occured_on_saving_img',
//   6015: 'invalid_credentials',
//   6016: 'invalid_date_of_birth',
//   7001: 'no_change',
//   7002: 'service_in_use',
//   7003: 'fill_the_gaps',
//   7004: 'invalid_plate',
//   7005: 'plate_already_in_use',
//   7006: 'service_already_in_use',
//   8001: 'successfully_updated',
//   8002: 'deleted_successfully',
//   8003: 'plate_added',
//   8004: 'error_msg_email_valid'
// }

const AlertContext = createContext({})

const Container = styled.div`
  position: fixed;
  bottom: ${DEFAULT.containerBottom};
  right: ${DEFAULT.containerRight};
`

/*
isCode true = 435
    isObject true
        isTranslate true
        isTranslate false
    isObject false
        isTranslate true
        isTranslate false
isCode false
    isObject true
        isTranslate true
        isTranslate false
    isObject false
        isTranslate true
        isTranslate false
*/

// const getErrorValue = (value, isObject, isTranslate, isCode, t) => {
//   if (isObject) {
//     const error = value.response.data.code
//       ? value.response.data.code
//       : value.response.status
//       ? value.response.status
//       : 6007
//     return t(errorCodes[error])
//   }
//   return isTranslate ? t(errorCodes[value]) : errorCodes[value]
// }

const AlertContainer = ({
  value,
  type,
  isTranslate = DEFAULT.isTranslate,
  height = DEFAULT.height,
  width = DEFAULT.width,
  isIcon = DEFAULT.isIcon,
  isErrorObject = DEFAULT.isErrorObject,
  isCode = DEFAULT.isCode
}) => {
  const { t } = useTranslation()

  return (
    <Container>
      <Alert icon={isIcon} sx={{ height, width }} severity={type}>
        {isTranslate ? t(value) : value}
        {/* {getErrorValue(value, isErrorObject, isTranslate, isCode, t)} */}
      </Alert>
    </Container>
  )
}

/*
err
err.response.data.code
err.response.status
default code
*/

const AlertProvider = ({ children }) => {
  const [alertData, setAlertData] = useState(initialAlertState)

  const clearAlert = () => setAlertData(initialAlertState)

  /**
   *
   * @param {string} value - value of the alert
   * @param {'error' | 'success'} type - type of the alert (error, success)
   * @param {{errorObject: boolean, timeout: number, height: number, width: number, icon: boolean, translate: boolean, code: boolean}} options
   * @returns {void}
   */
  const setAlert = (
    value,
    type = 'error',
    { errorObject, timeout, height, width, icon, translate, code }
  ) => {
    if (!value) return new Error('Value must be provided')

    if (!height) height = DEFAULT.height
    if (!width) width = DEFAULT.width
    if (!icon) icon = DEFAULT.isIcon
    if (!translate) translate = DEFAULT.isTranslate
    if (!timeout) timeout = DEFAULT.timeout
    if (!errorObject) errorObject = DEFAULT.isErrorObject
    if (!code) code = DEFAULT.isCode

    setAlertData({
      value,
      type,
      height,
      width,
      isIcon: icon,
      isTranslate: translate,
      isErrorObject: errorObject,
      isCode: code
    })
    setTimeout(() => clearAlert(), timeout)
  }

  const getAlertValue = () => alertData.value || null

  return (
    <AlertContext.Provider value={{ setAlert, getAlertValue }}>
      {alertData.value && (
        <AlertContainer
          value={alertData.value}
          type={alertData.type}
          isTranslate={alertData.isTranslate}
          isIcon={alertData.isIcon}
          height={alertData.height}
          width={alertData.width}
          isErrorObject={alertData.isErrorObject}
          isCode={alertData.isCode}
        />
      )}
      {children}
    </AlertContext.Provider>
  )
}

const useAlert = () => useContext(AlertContext)

export { AlertProvider, useAlert }
