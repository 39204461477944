import { INITAL_USER } from '../../context/AuthProvider'

export const getLocal = (key) => {
  const itemStr = sessionStorage.getItem(key)
  if (!itemStr) return INITAL_USER
  const item = JSON.parse(itemStr)
  const now = new Date()
  if (now.getTime() > item.expiry) {
    sessionStorage.removeItem(key)
    return INITAL_USER
  }
  return item.value
}

export const setLocal = (key, value, exp) => {
  const now = new Date()
  const item = {
    value: value,
    expiry: now.getTime() + exp
  }
  sessionStorage.setItem(key, JSON.stringify(item))
  return `${key}: ${JSON.stringify(item)} => Has been set to the "LocalStorage" successfully!`
}

export const removeLocal = (key) => {
  sessionStorage.removeItem(key)
}
