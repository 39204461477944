import styled from 'styled-components';
import { BREAK_POINTS, COLORS } from '../../../../assets/styles/theme';

export const Container = styled.main`
  width: 100vw;
  min-height: 30vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  gap: 3rem;
  @media (${BREAK_POINTS.m}) {
    gap: 2rem;
  }
`;
export const Title = styled.h2``;
export const MoreButton = styled.button`
  padding: 1rem;
  margin-top: -1rem;
  background-color: ${COLORS.background_l};
  text-decoration: underline;
  color: ${COLORS.accent_d};
  border-radius: 5px;

  &:hover {
    background-color: ${COLORS.accent_l};
    color: ${COLORS.background_l};
  }
`;
export const Content = styled.span`
  width: 90%;
  display: flex;
  align-items: center;
  gap: 4rem;
  justify-content: center;
  flex-wrap: wrap;
`;
export const Img = styled.img`
  height: 5rem;
  width: 10rem;
  object-fit: contain;
  cursor: pointer;
  transition: all 0.3s ease;
  @media (${BREAK_POINTS.l}) {
    width: 8rem;
  }
  @media (${BREAK_POINTS.m}) {
    width: 6rem;
  }

  &:hover {
    transform: scale(1.05);
  }
`;
