import styled from 'styled-components'
import { motion } from 'framer-motion'
import { BREAK_POINTS, COLORS } from '../../../../assets/styles/theme'

export const Container = styled(motion.div)`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  position: absolute;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 4rem;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.primary};
  color: ${COLORS.background_d};
`
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Text = styled.h4`
  @media (${BREAK_POINTS.m}) {
    font-size: 0.7rem;
    margin-right: 6rem;
  }
  @media (${BREAK_POINTS.s}) {
    font-size: 0.5rem;
    margin-right: 5.5rem;
  }
`
export const Btn = styled.button`
  position: absolute;
  color: ${COLORS.background_d};
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  height: 3rem;
  border-radius: 4px;
  width: 10rem;
  background-color: ${COLORS.primary_l};
  &:hover {
    background-color: ${COLORS.primary_d};
  }
  @media (${BREAK_POINTS.m}) {
    width: 6rem;
    height: 2rem;
    font-size: 0.75rem;
  }
  @media (${BREAK_POINTS.s}) {
    width: 4rem;
    height: 1.75rem;
    font-size: 0.55rem;
  }
`
