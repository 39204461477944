import styled from 'styled-components'
import { motion } from 'framer-motion'
import { BREAK_POINTS, COLORS, SHADOWS } from '../../../../assets/styles/theme'

export const TOPBAR_HEIGHT = '4rem'

export const Container = styled(motion.nav)`
  position: fixed;
  top: 0;
  height: ${TOPBAR_HEIGHT};
  width: 100vw;
  box-shadow: ${SHADOWS.mid};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (${BREAK_POINTS.m}) {
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 0 1rem;
  }
  padding: 0 2rem;
  position: relative;
`
export const MenuBtn = styled.div`
  display: none;
  height: 3rem;
  aspect-ratio: 1;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease;
  &:hover {
    background-color: ${COLORS.background_d};
  }
  @media (${BREAK_POINTS.m}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const LogoContainer = styled.div`
  width: 15rem;
  height: 4rem;
  padding: 1rem 1rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  @media (${BREAK_POINTS.m}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
