import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../../../assets/styles/style/styled';
import { MOBILE_CODE, SOURCE } from '../../../../data/resources/constants';
import { initialRecoveryData } from '../../../../data/resources/initialStates';
import { recoverTypesList } from '../../../../data/resources/lists';
import { passwordCheck } from '../../../../services/api/authApi';
import { getIsRecoveryBtnDisabled } from '../../../../utils/helpers/getIsRecoveryBtnDisabled';
import { validateMobileNumberPrefix } from '../../../../utils/helpers/validateMobileNumber';
import { useLogger } from '../../../../utils/hooks/useLogger';
import { useToast } from '../../../../utils/hooks/useToast';
import { OTPWrapper, PwdChangeWrapper, ToolTip } from '../../../components';
import { Btn, ErrorBlock, FooterLink, HiddenField, Input } from '../login-wrapper/style';
import { NumberField, NumberInput, StaticBox } from '../register-wrapper/style';
import {
  Check,
  Footer,
  Form,
  Header,
  HeaderBtns,
  HeaderText,
  Label,
  LabelText,
  Line,
  Wrapper,
} from './style';

const RecoveryWrapper = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [recoveryType, setRecoveryType] = useState(null);
  const [recoveryData, setRecoveryData] = useState(initialRecoveryData);
  const [btnTooltip, setBtnTooltip] = useState(false);

  const [showWrapper, setShowWrapper] = useState(false);
  const [uuid, setUuid] = useState('');
  const [showPwdChange, setShowPwdChange] = useState(false);
  const { setError } = useToast();
  const logger = useLogger();

  const handleTypeSelect = (t) => {
    setRecoveryType(t.type);
    setRecoveryData(initialRecoveryData);
    getIsRecoveryBtnDisabled(t.type, initialRecoveryData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBtnTooltip(false);
    setRecoveryData({ ...recoveryData, [name]: value });
    getIsRecoveryBtnDisabled(recoveryType, { ...recoveryData, [name]: value });
  };

  const getIfBtnDisabled = () => getIsRecoveryBtnDisabled(recoveryType, recoveryData)[0];
  const getBtnDisabledMsg = () => getIsRecoveryBtnDisabled(recoveryType, recoveryData)[1];

  const { mutate, isLoading, isFetching } = useMutation((data) => passwordCheck(data), {
    onError: (err) => {
      setError(400);
      logger('Password checking error on recovery page', err);
    },
    onSuccess: ({ data }) => {
      if (data.code === 0 && data.processUuid) {
        logger('Password checking on recovery page', data);
        setUuid(data.processUuid);
        setShowWrapper(true);
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      source: SOURCE,
    };
    if (recoveryType === 'email') {
      data.mobileNumber = recoveryData.email;
      data.phone = false;
    } else {
      data.mobileNumber = MOBILE_CODE + recoveryData.mobileNumber;
      data.phone = true;
    }
    logger('Password recovery is starting', data);
    mutate(data);
  };

  return (
    <Wrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Helmet>
        <title>{t('pt_auth_recover')}</title>
      </Helmet>
      {showWrapper && (
        <OTPWrapper
          uuid={uuid}
          close={() => setShowWrapper(false)}
          mobileNumber={
            recoveryType === 'mobileNumber' ? recoveryData.mobileNumber : recoveryData.email
          }
          type={recoveryType === 'email' ? true : false}
          isPwd={true}
          delUuid={() => setUuid('')}
          setUuid={setUuid}
          setShowPwdChange={() => setShowPwdChange(true)}
        />
      )}
      {showPwdChange && (
        <PwdChangeWrapper
          close={() => setShowPwdChange(false)}
          delUuid={() => setUuid('')}
          uuid={uuid}
          recovery={true}
        />
      )}
      <Header>
        <HeaderText>{t('recover_password')}</HeaderText>
        <HeaderBtns>
          {recoverTypesList.map((rt) => (
            <Label
              onChange={() => handleTypeSelect(rt)}
              key={rt.name}
              isSelected={recoveryType === rt.type}
            >
              <Check name='recovery-type' type='radio' />
              <LabelText>{t(rt.name)}</LabelText>
            </Label>
          ))}
        </HeaderBtns>
      </Header>
      <Line />
      <Form>
        {recoveryType === 'email' ? (
          <Input
            value={recoveryData.email}
            name='email'
            onChange={handleChange}
            placeholder={t('Email')}
            autoFocus
          />
        ) : recoveryType === 'mobileNumber' ? (
          <NumberField>
            <StaticBox>+{MOBILE_CODE}</StaticBox>
            <NumberInput
              autoFocus
              format='## ### ## ##'
              placeholder={t('mobile_number')}
              value={recoveryData.mobileNumber}
              name='mobileNumber'
              onValueChange={(values) => {
                setRecoveryData({
                  ...recoveryData,
                  mobileNumber: validateMobileNumberPrefix(values.value),
                });
                getIsRecoveryBtnDisabled(recoveryType, {
                  ...recoveryData,
                  mobileNumber: validateMobileNumberPrefix(values.value),
                });
                setBtnTooltip(false);
              }}
            />
          </NumberField>
        ) : (
          <ErrorBlock mgh='0rem'>{t('error_select_recovery_type')}</ErrorBlock>
        )}
        <HiddenField isNone={getIfBtnDisabled()} onClick={() => setBtnTooltip(true)} />
        <Btn
          whileTap={{ scale: getIfBtnDisabled ? 1 : 0.95 }}
          onHoverStart={() => setBtnTooltip(true)}
          onHoverEnd={() => setBtnTooltip(false)}
          disabled={getIfBtnDisabled() || isLoading || isFetching}
          onClick={handleSubmit}
        >
          {isLoading || isFetching ? <Spinner /> : t('recover_btn')}
        </Btn>
        <AnimatePresence>
          {btnTooltip && getIfBtnDisabled() && <ToolTip>{t(getBtnDisabledMsg())}</ToolTip>}
        </AnimatePresence>
      </Form>
      <Footer>
        <FooterLink onClick={() => navigate('/', { state: { tab: 'Login' } })}>
          {t('back_to_login')}
        </FooterLink>
      </Footer>
    </Wrapper>
  );
};

export default RecoveryWrapper;
