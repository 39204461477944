import styled from 'styled-components';
import { BREAK_POINTS, COLORS, SHADOWS } from '../../../assets/styles/theme';
import { SIDEBAR_WIDTH } from '../../components/layout/sidebar/style';

export const Container = styled.main`
  margin-left: ${SIDEBAR_WIDTH};
  width: calc(100% - ${SIDEBAR_WIDTH});
  @media (${BREAK_POINTS.m}) {
    margin-left: 0;
    width: 100%;
  }
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const Wrapper = styled.section`
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  @media (${BREAK_POINTS.l}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (${BREAK_POINTS.m}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (${BREAK_POINTS.s}) {
    grid-template-columns: 1fr;
  }
`;
export const FormWrapper = styled.div`
  width: 100%;
`;
export const Title = styled.h2`
  cursor: pointer;
`;
export const Service = styled.div`
  height: 6rem;
  cursor: pointer;
  display: flex;
  padding: 0 1rem;
  gap: 2rem;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${COLORS.gray_l};
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.01);
    box-shadow: ${SHADOWS.so_low};
    border-right: 10px solid ${COLORS.primary};
  }
`;
export const ImgContainer = styled.div`
  aspect-ratio: 1;
`;
export const Img = styled.img`
  height: 40%;
`;
export const ServiceName = styled.span`
  font-size: 1.2rem;
  @media (${BREAK_POINTS.l}) {
    font-size: 1.1rem;
  }
  @media (${BREAK_POINTS.m}) {
    font-size: 0.9rem;
  }
  @media (${BREAK_POINTS.s}) {
    font-size: 0.85rem;
  }
`;
export const CatName = styled.span`
  text-align: center;
  font-size: 1rem;
  @media (${BREAK_POINTS.m}) {
    font-size: 0.8rem;
  }
  @media (${BREAK_POINTS.s}) {
    font-size: 0.75rem;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  width: 50%;
  box-shadow: ${SHADOWS.mid};
  border-radius: 5px;
  @media (${BREAK_POINTS.l}) {
    width: 100%;
  }
`;
export const Field = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.25rem;
  // align-items: center;
  justify-content: space-between;
`;
export const Label = styled.label``;
export const Select = styled.select`
  width: 100%;
  height: 2.75rem;
  cursor: pointer;
`;
export const Row = styled.div`
  display: flex;
  gap: 0.25rem;
`;
export const Option = styled.option``;
export const BreadCrumb = styled.span`
  color: ${COLORS.gray};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const CommisionText = styled.p`
  color: ${COLORS.gray_d};
  font-style: italic;
`;
