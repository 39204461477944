import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { mastercardLogoWT } from '../../../../assets/images'
import { AContainer, Close, IconContainer, Img } from './style'

const Annoc = ({ close }) => {
  const { t } = useTranslation()
  return (
    <AContainer>
      <Img src={mastercardLogoWT} />
      <Trans>{t('master_annoc')}</Trans>
      <IconContainer>
        <Close onClick={close} />
      </IconContainer>
    </AContainer>
  )
}

export default Annoc
