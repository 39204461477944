import React, { useEffect } from 'react';
import AllRoutes from './components/routes';
import Unsecured from './views/unsecured';
import { useLocation } from 'react-router-dom';

const App = () => {
  const location = useLocation();
  useEffect(() => {
    const lang = document?.cookie?.split('; ')?.reduce((acc, c) => {
      const [key, value] = c.split('=');
      acc[key] = value;
      return acc;
    }, {})?.i18next;

    if (lang) {
      document.documentElement.setAttribute('lang', lang);
    } else {
      document.documentElement.setAttribute('lang', 'en');
    }
  }, []);

  useEffect(() => {
    if (location.hash) {
      let hash = location.hash.replace('#', '');
      const el = document.getElementById(hash);
      el?.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.hash, location.pathname]);

  if (window.self !== window.top) {
    return <Unsecured />;
  }

  return <AllRoutes />;
};

export default App;
