import React from 'react';
import {
  Card,
  Container,
  Divider,
  Desc,
  Title,
  Label,
  TextPanel,
  ImgContainer,
  Img,
} from './style';
import { adyLogo, airportLogo, cityParkLogo, parkBulvarLogo } from '../../../assets/images';
import { useTranslation } from 'react-i18next';

/**
 * @typedef {{ title: string, desc: string, info: string, contact: string, address: string, img: string, isActive?: boolean, isNew?: boolean, parkingTypes?: { outdoor?: boolean, indoor?: boolean } }} PartnerData
 */
/**
 * @type {PartnerData[]}
 */
const services = [
  {
    title: 'Heydər Əliyev Beynəlxalq Aeroportu',
    desc: 'partners_airport_desc',
    contact: '+994 12 497 27 27',
    address: 'partners_airport_address',
    img: airportLogo,
  },
  {
    title: 'Park Bulvar Mall',
    desc: 'partners_park_bulvar_desc',
    contact: '+994 12 598 80 80',
    address: 'partners_park_bulvar_address',
    parkingTypes: {
      outdoor: true,
      indoor: true,
    },
    img: parkBulvarLogo,
  },
  {
    title: 'City Park Mall',
    desc: 'partners_city_park_desc',
    contact: '+994 51 233 66 66',
    address: 'partners_city_park_address',
    parkingTypes: {
      outdoor: true,
      indoor: true,
    },
    img: cityParkLogo,
  },
  {
    title: 'Bakı Dəmiryol Vağzalı',
    desc: 'partners_ayd_desc',
    contact: '1822',
    address: 'partners_ayd_address',
    parkingTypes: {
      outdoor: true,
    },
    img: adyLogo,
  },
];

/**
 *
 * @param {undefined | { indoor: boolean, outdoor: boolean }} parkingType?
 */
function getParkingType(parkingType) {
  if (!parkingType) {
    return null;
  }

  if (parkingType.indoor && parkingType.outdoor) {
    return 'parking_type_both';
  }

  if (parkingType.indoor) {
    return 'parking_type_indoor';
  }

  return 'parking_type_outdoor';
}

const PartnersPage = () => {
  const { t } = useTranslation();

  return (
    <Container>
      {services.map((service) => (
        <Card key={service.title}>
          <ImgContainer>
            <Img src={service.img} alt={service.title + ' Logo'} />
          </ImgContainer>
          <Title>{service.title}</Title>
          <Desc>{t(service.desc)}</Desc>
          {Object.keys(service.parkingTypes ?? {}).length ? (
            <TextPanel>
              {t('parking_type')}: {t(getParkingType(service.parkingTypes))}
            </TextPanel>
          ) : null}
          <Divider />

          {service.contact && (
            <>
              <Label>{t('contact')}</Label>
              <Desc>{service.contact}</Desc>
            </>
          )}
          {service.address && (
            <>
              <Label>{t('address')}</Label>
              <Desc>{t(service.address)}</Desc>
            </>
          )}
        </Card>
      ))}
    </Container>
  );
};

export default PartnersPage;
