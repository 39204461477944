import styled from 'styled-components'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import { motion } from 'framer-motion'
import { BREAK_POINTS, COLORS, SHADOWS } from '../../../../assets/styles/theme'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

export const NAVBAR_HEIGHT = 4
export const ANNOC_HEIGHT = 2.5

export const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  width: 100vw;
  height: ${NAVBAR_HEIGHT}rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 20;
  transition: all 0.3s ease;
`
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0 5rem;
  @media (${BREAK_POINTS.l}) {
    padding: 0 2rem;
  }
  background-color: ${({ activebg }) => (activebg === 'true' ? COLORS.nav_col : 'transparent')};
  backdrop-filter: ${({ activebg }) => (activebg === 'true' ? 'blur(10px)' : 'blur(0)')};
  box-shadow: ${({ activebg }) => (activebg === 'true' ? SHADOWS.mid : 'none')};
`

export const LogoContainer = styled.div`
  @media (${BREAK_POINTS.bm}) {
    width: 25%;
  }
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Logo = styled.img`
  height: 70%;
`
export const NavList = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  justify-content: center;
  @media (${BREAK_POINTS.l}) {
    display: none;
  }
`
export const NavbarLangContainer = styled.div`
  height: 100%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: auto;
`
export const AuthList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  width: 25%;
  @media (${BREAK_POINTS.l}) {
    display: none;
  }
`
export const MAuthList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
`
export const Item = styled.div`
  height: 100%;
  transition: all 0.3s ease;
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.black};
  cursor: pointer;
  border-bottom: 2px solid transparent;
  margin-top: 2px;
  &:hover {
    border-bottom: 2px solid ${COLORS.primary_d};
  }
`
export const Btn = styled.button`
  height: 2.25rem;
  width: ${({ islogin, wid }) => (islogin && !wid ? '6rem' : '8rem')};
  border-radius: 3px;
  background-color: ${({ islogin }) => (islogin ? 'transparent' : COLORS.primary)};
  color: ${({ islogin }) => (islogin ? COLORS.primary : COLORS.background_d)};
  border: ${({ islogin }) => (islogin ? `2px solid ${COLORS.primary}` : 'none')};
  &:hover {
    background-color: ${({ islogin }) => (islogin ? COLORS.gray_l + 'a0' : COLORS.primary_d)};
    border: ${({ islogin }) => (islogin ? `2px solid ${COLORS.primary_d}` : 'none')};
  }
`
export const MenuList = styled(motion.div)`
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: ${({ ismobile }) => (ismobile ? 'flex-end' : 'flex-start')};
  align-items: flex-end;
  background-color: ${COLORS.black};
  width: 30vw;
  @media (${BREAK_POINTS.m}) {
    width: 50vw;
    padding-top: ${({ ismobile }) => (ismobile ? '0' : '1rem')};
    padding-bottom: 5rem;
  }
`
export const MenuItem = styled.div`
  transition: all 0.3s ease;
  height: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.background_d};
  cursor: pointer;
  border-bottom: 2px solid transparent;
  margin-top: 2px;
  padding: 1rem;
  &:hover {
    border-bottom: 2px solid ${COLORS.primary_d};
  }
`
export const MenuBtn = styled.div`
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  color: ${COLORS.black};
  @media (${BREAK_POINTS.l}) {
    display: flex;
  }
`
export const Icon = styled(MenuRoundedIcon)`
  font-size: 2rem !important;
  cursor: pointer;
`

export const AContainer = styled.div`
  position: relative;
  height: ${ANNOC_HEIGHT}rem;
  width: 100vw;
  background-color: ${COLORS.accent};
  color: ${COLORS.background};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  @media (${BREAK_POINTS.m}) {
    font-size: 12px;
  }
  @media (${BREAK_POINTS.s}) {
    font-size: 11px;
  }
`
export const Img = styled.img`
  height: 60%;
`
export const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0.1rem;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.accent_l};
  }
`
export const Close = styled(CloseRoundedIcon)`
  cursor: pointer;
`
