import styled from 'styled-components'
import { BREAK_POINTS } from '../../../../assets/styles/theme'
import { SIDEBAR_WIDTH } from '../sidebar/style'
import { TOPBAR_HEIGHT } from '../topbar/style'

export const Container = styled.main``
export const Wrapper = styled.section`
  padding-left: ${SIDEBAR_WIDTH};
  min-height: calc(100vh - ${TOPBAR_HEIGHT});
  display: flex;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  @media (${BREAK_POINTS.m}) {
    padding-left: 0;
  }
`
