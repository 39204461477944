import styled from 'styled-components'
import { motion } from 'framer-motion'
import { BREAK_POINTS, COLORS, SHADOWS } from '../../../../assets/styles/theme'
import { Btn } from '../login-wrapper/style'

export const Container = styled.section`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${COLORS.background};
`
export const Wrapper = styled(motion.div)`
  min-height: 18rem;
  z-index: 1000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  @media (${BREAK_POINTS.l}) {
    width: 65%;
  }
  @media (${BREAK_POINTS.m}) {
    width: 80%;
  }
  @media (${BREAK_POINTS.s}) {
    width: 90%;
  }
  background-color: ${COLORS.background_l};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: ${SHADOWS.low};
`
export const Title = styled.h2`
  margin-bottom: 1rem;
`
export const Desc = styled.span`
  color: ${COLORS.gray_d};
`
export const Btns = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`
export const Approve = styled(Btn)`
  width: 80%;
`
export const otpInputStyles = {
  width: '3rem',
  height: '3rem',
  border: '1px solid ' + COLORS.gray,
  borderRadius: '3px',
  fontSize: '1.2rem',
  margin: '0.5rem',
  marginBottom: '1rem'
}
export const otpInputStylesMobile = {
  width: '2.4rem',
  height: '2.4rem',
  border: '1px solid ' + COLORS.gray,
  borderRadius: '3px',
  fontSize: '1rem',
  margin: '0.5rem',
  marginBottom: '1rem'
}
