import Tooltip from '@mui/material/Tooltip';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../../../assets/styles/style/styled';
import { LOGIN_FAILURE, LOGIN_SUCCESS } from '../../../../data/resources/actionTypes';
import { ROLE_USER, SOURCE } from '../../../../data/resources/constants';
import { initialPasswordValidation } from '../../../../data/resources/initialStates';
import { signIn } from '../../../../services/api/authApi';
import { editUserEmail } from '../../../../services/api/userApi';
import { validateEmail } from '../../../../utils/helpers/mailSend';
import { validatePassword } from '../../../../utils/helpers/validatePassword';
import { useAuth } from '../../../../utils/hooks/useAuth';
import { useLogger } from '../../../../utils/hooks/useLogger';
import { useToast } from '../../../../utils/hooks/useToast';
import BackDrop from '../../layout/backdrop';
import { Input } from '../login-wrapper/style';
import PasswordTooltip from '../register-wrapper/PasswordTooltip';
import EyeContainer from './EyeContainer';
import { Button, Field, Label, LoginPopup, PPCell, PPTitle } from './style';

export function LoginPopupContainer(props) {
  const navigate = useNavigate();
  const { dispatch } = useAuth();
  const { t } = useTranslation();
  const logger = useLogger();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [showPasswordValidation, setShowPasswordValidation] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(initialPasswordValidation);
  const [isBtnDisabled, setIsBtnDisabled] = useState({ value: true, msg: 'fill_the_gaps' });
  const { setError } = useToast();

  const { mutate: loginMutate } = useMutation(signIn, {
    onError: (error) => {
      const errorMsg = error?.response?.data?.code || error?.response?.status;
      setError(error);
      logger('Error on login popup', { error: errorMsg });
      dispatch({
        type: LOGIN_FAILURE,
        payload: errorMsg,
      });
    },
    onSuccess: ({ data }) => {
      const { accessToken, roles, pin, email } = data;
      if (accessToken && roles.includes(ROLE_USER)) {
        dispatch({ type: LOGIN_SUCCESS, payload: { accessToken, email, pin } });

        let expires = new Date();
        expires.setDate(expires.getDate() + 1);

        props.setShowPopup(false);
        logger('Logged in from login popup', { accessToken, email, pin });
        navigate('/dashboard', { email });
      } else if (accessToken && !roles.includes(ROLE_USER)) {
        dispatch({
          type: LOGIN_FAILURE,
          payload: 6001,
        });

        setError(6001);
      }
    },
  });

  const { mutate, isLoading } = useMutation(
    (data) => editUserEmail(data, props.externalData.accessToken),
    {
      onSuccess: () => {
        const loginData = {
          email: formData.email,
          password: props.hidePwd ? props.externalPassword : formData.password,
          registrationSource: SOURCE,
        };

        logger('User email edited on Login Popup', { email: loginData.email });
        loginMutate(loginData);
      },
      onError: (error) => {
        const errorMsg = error.response.data.code
          ? error.response.data.code
          : error.response.status;
        setError(error);
        dispatch({
          type: LOGIN_FAILURE,
          payload: errorMsg,
        });
      },
    }
  );

  const handleSubmit = (e) => {
    if (isBtnDisabled.value) return;

    const { email, password } = props.formData;

    const valPwd = validatePassword(password);

    if (validateEmail(email)) {
      setError('error_msg_email_valid');
      return;
    } else if (!props.hidePwd && !valPwd.isEverythingTrue) {
      setError('error_msg_password');
      return;
    }

    const data = {
      email,
      password: props.hidePwd ? null : password,
      mobileNumber: props.externalData.mobileNumber,
    };

    logger('User email edit started on Login Popup', {
      email: data.email,
      mobileNumber: data.mobileNumber,
      hasPassword: !!props.hidePwd,
    });
    mutate(data);
  };

  function handleChange(e) {
    props.handleOnChange(e);

    const { name, value } = e.target;

    if (!props.hidePwd && name === 'password') {
      setPasswordValidation(validatePassword(e.target.value));
    }

    setFormData((prev) => ({ ...prev, [name]: value }));
  }

  const getIsBtnDisabled = () => {
    if (!formData.email || (!props.hidePwd && !formData.password)) {
      setIsBtnDisabled({ value: true, msg: 'fill_the_gaps' });
    } else if (!props.hidePwd && !validatePassword(formData.password).isEverythingTrue) {
      setIsBtnDisabled({ value: true, msg: 'error_msg_password' });
    } else if (formData.email.length > 50) {
      setIsBtnDisabled({ value: true, msg: 'invalid_email' });
    } else {
      setIsBtnDisabled({ value: false });
    }
  };

  useEffect(() => {
    getIsBtnDisabled();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <BackDrop onClick={() => props.setShowPopup(false)}>
      <LoginPopup onClick={(e) => e.stopPropagation()}>
        <PPCell>
          <PPTitle>{t('pp_title')}</PPTitle>
        </PPCell>
        <AnimatePresence></AnimatePresence>
        <PPCell>
          <Label>{t('Email')}</Label>
          <Field>
            <Input
              name='email'
              value={formData.email}
              onChange={handleChange}
              placeholder={t('enter')}
            />
          </Field>
        </PPCell>
        {!props.hidePwd && (
          <PPCell>
            <Label>{t('new_password')}</Label>
            <Field>
              <Input
                name='password'
                value={formData.password}
                onChange={handleChange}
                placeholder={t('enter')}
                type={props.showPassword ? 'text' : 'password'}
                onFocus={() => setShowPasswordValidation(true)}
                onBlur={() => setShowPasswordValidation(false)}
              />
              <EyeContainer
                field={props.formData.password}
                open={props.showPassword}
                setOpen={props.setShowPassword}
              />
              <PasswordTooltip show={showPasswordValidation} validation={passwordValidation} />
            </Field>
          </PPCell>
        )}
        <PPCell>
          <Tooltip title={t(props.isBtnDisabled.msg)} arrow>
            <Button
              type='button'
              whileTap={{
                scale: props.isBtnDisabled.value ? 1 : 0.95,
              }}
              isdisabled={isBtnDisabled.value || isLoading ? 'true' : 'false'}
              onClick={handleSubmit}
            >
              {isLoading ? <Spinner col={'acc'} wid='1.9rem' /> : t('Login')}
            </Button>
          </Tooltip>
        </PPCell>
      </LoginPopup>
    </BackDrop>
  );
}
