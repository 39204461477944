/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { serviceRegisterErrorCodes } from '../../../data/resources/lists';
import { getMerchants } from '../../../services/api/merchantApi';
import { useParams } from '../../../utils/hooks/useParams';
import { DialogBox } from '../../components';
import { Container } from './style';
// import { ErrorBlock } from '../../components/features/login-wrapper/style';
import { Helmet } from 'react-helmet';
import { steps } from '../../../data/resources/intro';
import {
  connectService,
  deleteService,
  getConnectedPaymentsByEmail,
} from '../../../services/api/cardApi';
import { getDashboardScreen } from '../../../utils/helpers/getDashboardScreen';
import { useAddCard } from '../../../utils/hooks/useAddCard';
import { useAuth } from '../../../utils/hooks/useAuth';
import { useGetCardsByEmail } from '../../../utils/hooks/useGetCardsByEmail';
import { useIntro } from '../../../utils/hooks/useIntro';
import { useLogger } from '../../../utils/hooks/useLogger';
import { useToast } from '../../../utils/hooks/useToast';
import Categories from './Categories';
import MarketView from './MarketView';
import Organizations from './Organizations';
import ParkingView from './ParkingView';
import ServiceForm from './ServiceForm';
import ServiceList from './ServiceList';
import TaxiView from './TaxiView';
import TitleRow from './TitleRow';
import YDMView from './YDMView';
import { useServiceStore } from '../../../context/ServiceIdProvider';

const Dashboard = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const JoyRide = useIntro('dashboard', steps.dashboard);

  const [currentScreen, setCurrentScreen] = useState('service-list');
  const [cards, setCards] = useState([]);
  const [xref, setXref] = useState(null);
  const [asanCats, setAsanCats] = useState([]);
  const [asanOrgs, setAsanOrgs] = useState([]);
  const [customLoading, setCustomLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [formData, setFormData] = useState({
    organization: '',
    category: '',
    card: location.state ? location.state.id : null,
  });

  const serviceIds = useServiceStore();

  useEffect(() => {
    if (serviceIds) {
      setCurrentScreen(getDashboardScreen(params, serviceIds));
    }
  }, [params]);

  const { setError, setSuccess } = useToast();
  const {
    user: { pin: userPin, email: userEmail },
  } = useAuth();
  const logger = useLogger();

  const { mutate: urlMutate } = useAddCard();

  useEffect(() => {
    setFormData({
      ...formData,
      organization: params ? params.organization || '' : '',
      category: params ? params.category || '' : '',
    });
  }, []);

  const getCategoryLabels = (service) => {
    const organizations = service.organizations;
    const operations = organizations.reduce((acc, org) => [...acc, ...org.operations], []);
    const labels = operations.map((op) => op.categoryLabel);
    const result = [...new Set(labels)];
    return { labels: result, orgs: organizations };
  };

  const onSuccess = async (data) => {
    const refetchData = await refetchServicesForUser();
    if (!refetchData.data.data.length) {
      setCards(data);
      return;
    }
    const successfull = refetchData.data.data.filter((s) => s.statusCode === 0);
    const obj = successfull.reduce((acc, s) => {
      if (acc[s.cardId]) {
        acc[s.cardId] = [...acc[s.cardId], s.serviceId];
      } else {
        acc[s.cardId] = [s.serviceId];
      }
      return acc;
    }, {});

    let cards = [];
    Object.entries(obj).forEach(([card, services]) => {
      if (!services.includes(1)) cards.push(card);
    });
    cards = cards.map((id) => data.find((c) => c.id === Number(id))).filter((c) => c);
    setCards([
      ...cards,
      ...data.filter((card) => !successfull.map((s) => s.cardId).includes(card.id)),
    ]);
  };
  useGetCardsByEmail(onSuccess);

  useQuery('merchants', getMerchants, {
    onSuccess: ({ data }) => {
      let allMerchants = data.services;

      let result = {};
      let operations = {};
      let services = {};

      allMerchants.forEach((s) => {
        services[s.id] = s.shortName;
        if (s.id === serviceIds.PARKING_SERVICE_ID) {
          s.organizations.forEach((org) => {
            result[org.id] = org.label;
            org.operations.forEach((op) => {
              operations[op.operationId] = op.name;
            });
          });
        } else {
          s.organizations.forEach((org) => {
            result[org.id] = org.label;
          });
        }
      });

      setAsanOrgs(
        getCategoryLabels(allMerchants.find((mer) => mer.id === serviceIds.ASAN_SERVICE_ID)).orgs
      );
      setAsanCats(
        getCategoryLabels(allMerchants.find((mer) => mer.id === serviceIds.ASAN_SERVICE_ID)).labels
      );
    },
    refetchOnWindowFocus: false,
  });

  const { refetch: refetchServices } = useQuery(
    ['services', formData.card],
    () => getConnectedPaymentsByEmail(),
    {
      onSuccess: ({ data }) => {
        return data.filter((c) => c.cardId === formData?.card);
      },
      refetchOnWindowFocus: false,
      enabled: false,
      retry: 1,
    }
  );

  const { refetch: refetchServicesForUser } = useQuery('services', getConnectedPaymentsByEmail, {
    onSuccess: ({ data }) => data,
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    mutate,
    isLoading: connectLoading,
    isFetching: connectFetching,
  } = useMutation((data) => connectService(data), {
    onSuccess: ({ data }) => {
      logger('Service connection started', { userPin, ...data });

      let myInt = setInterval(async () => {
        const refetchData = await refetchServices();
        let newService = refetchData.data.data.find((s) => s.xref === data.onboardCtpLogs.xref);
        if (newService.statusCode === 0) {
          setCustomLoading(false);
          setXref(null);
          navigate('/dashboard/cards');
          clearInterval(myInt);
        } else if (serviceRegisterErrorCodes.includes(newService.statusCode)) {
          setCustomLoading(false);
          setError(7002);
          clearInterval(myInt);
        }
      }, 1000);
    },
    onError: (err) => console.error(err),
  });

  const { mutate: deleteMutate } = useMutation((xref) => deleteService(xref), {
    onSuccess: async () => {
      const data = {
        serviceId: serviceIds.ASAN_SERVICE_ID,
        cardId: Number(formData.card),
        email: userEmail,
        serviceData: userPin,
      };
      logger('Service delete', data);
      mutate(data);
      queryClient.invalidateQueries(['cards', 'services']);
      setSuccess(8002);
    },
  });

  const handleServiceClick = (id) => {
    navigate('/dashboard?merchantId=' + id, { state: location.state || null });
  };

  const handleCategoryClick = (name) => {
    setFormData({ ...formData, category: name });
    navigate('/dashboard?merchantId=1&category=' + encodeURI(name), {
      state: location.state || null,
    });
  };

  const handleOrgClick = (org, cat) => {
    setFormData({ ...formData, organization: org.id });
    navigate(`/dashboard?merchantId=1&category=${cat}&organization=${org.id}`, {
      state: location.state || null,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCustomLoading(true);

    const data = {
      serviceId: serviceIds.ASAN_SERVICE_ID,
      cardId: Number(formData.card),
      email: userEmail,
      serviceData: userPin,
    };

    logger('Service connect started', data);
    mutate(data);
  };

  const acceptCardChange = (xref) => {
    // delete the service from the current card (service, card)
    if (xref) {
      logger('Service delete started', { xref });
      deleteMutate(xref);
    }
    // add the same service to the selected card (service, card)
  };

  useEffect(() => {
    if (formData.card === 'addCard') {
      urlMutate();
      setFormData({
        ...formData,
        card: location.state ? location.state.id : null,
      });
    }
  }, [formData.card]);

  return (
    <Container>
      <Helmet>
        <title>{t('pt_dashboard')}</title>
      </Helmet>
      {JoyRide}
      <DialogBox
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        desc='agree_to_change_card'
        // info={c.cardNumber}
        onAgree={() => acceptCardChange(xref)}
      />
      <TitleRow
        handleCategoryClick={handleCategoryClick}
        category={formData?.category}
        params={params}
      />
      {currentScreen === 'service-list' ? (
        <ServiceList handleServiceClick={handleServiceClick} />
      ) : currentScreen === 'parking-screen' ? (
        <ParkingView />
      ) : currentScreen === 'ydm-screen' ? (
        <YDMView />
      ) : currentScreen === 'market-screen' ? (
        <MarketView />
      ) : currentScreen === 'taxi-screen' ? (
        <TaxiView />
      ) : currentScreen === 'category-list' ? (
        <Categories cats={asanCats} handleCategoryClick={handleCategoryClick} />
      ) : currentScreen === 'organization-list' && params ? (
        <Organizations params={params} orgs={asanOrgs} handleOrgClick={handleOrgClick} />
      ) : currentScreen === 'service-form' && params ? (
        <ServiceForm
          handleSubmit={handleSubmit}
          formData={formData}
          setFormData={setFormData}
          catsList={asanCats}
          orgsList={asanOrgs}
          cardsList={cards}
          location={location}
          isLoading={connectLoading || connectFetching || customLoading}
        />
      ) : (
        // <ErrorBlock style={{ marginTop: '1rem' }}>{t('invalid_link')}</ErrorBlock>
        <ServiceList handleServiceClick={handleServiceClick} />
      )}
    </Container>
  );
};

export default Dashboard;
