import { Tooltip } from '@mui/material'
import React from 'react'
import { Spinner } from '../../../../assets/styles/style/styled'
import { Button } from '../login-form/style'

export function RegisterButton(props) {
  return (
    <Tooltip title={props.t(props.isBtnDisabled.msg)} arrow>
      <Button
        whileTap={{
          scale: props.isBtnDisabled.value ? 1 : 0.95
        }}
        isdisabled={props.isBtnDisabled.value || props.isLoading ? 'true' : 'false'}
      >
        {props.isLoading ? <Spinner col={'acc'} wid='1.9rem' /> : props.t(props.text)}
      </Button>
    </Tooltip>
  )
}
