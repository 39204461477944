import React from 'react';
import { useTranslation } from 'react-i18next';
import { partnersList } from '../../../../data/resources/lists';
import { Container, Content, Img, MoreButton, Title } from './style';
import { useNavigate } from 'react-router-dom';

const Partners = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container id='partners'>
      <Title>{t('partners')}</Title>
      <Content>
        {partnersList.map((p, i) => (
          <a href={p.link} key={p.alt} rel='noreferrer' target={'_blank'}>
            <Img src={p.img} alt={p.alt} />
          </a>
        ))}
      </Content>
      <MoreButton
        onClick={() => {
          navigate('/partners');
        }}
      >
        {t('click_for_more_information')}
      </MoreButton>
    </Container>
  );
};

export default Partners;
