import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../../assets/styles/theme';

const DialogBox = ({
  isOpen = false,
  setIsOpen,
  title = 'are_you_sure',
  desc,
  onAgree,
  info = null,
  token = null,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleAgree = () => {
    onAgree(token);
    setTimeout(() => {
      setIsOpen(false);
    }, 200);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{t(title)}</DialogTitle>
      <DialogContent sx={{ minWidth: '25rem', minHeight: '5rem' }}>
        <DialogContentText id='alert-dialog-description'>
          {info ? t(desc, { name: info }) : t(desc)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{ color: COLORS.error }} onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button sx={{ color: COLORS.primary }} onClick={handleAgree} autoFocus>
          {t('agree')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBox;

// username regex: (^(\+|)(994|)(0|)(50|55)[2-9][0-9]{6}$)|(^(?=.*[a-z])[a-z0-9]{5,7}$)|(^[\w.]+@([\w-]+\.)+[\w-]{2,4}$) > g
