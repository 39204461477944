import { useLocation } from 'react-router-dom'

/**
 * @typedef ParamResult
 * @type {object}
 */
/**
 * useParams gets the query parameters of the url and returns
 * @returns {ParamResult} - it is an object with key (query names) and value (query values) pairs
 */
export const useParams = () => {
  const location = useLocation()

  if (!location.search) return null

  let params = location.search.substring(1)
  let paramsArr = params.split('&')
  let result = {}
  paramsArr.forEach((param) => {
    let parts = param.split('=')
    result[parts[0]] = parts[1]
  })

  return result
}
