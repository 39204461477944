import React from 'react'
import { organizationImages } from '../../../data/resources/lists'
import { CatName, Service, Wrapper } from './style'

const Organizations = ({ params, orgs, handleOrgClick }) => {
  return (
    <Wrapper>
      {orgs
        .filter((org) =>
          org.operations.some((op) => op.categoryLabel === decodeURI(params.category))
        )
        .map((org) => (
          <Service key={org.id} onClick={() => handleOrgClick(org, params.category)}>
            {organizationImages.find((i) => i.id === org.id).img}
            <CatName>{org.label}</CatName>
          </Service>
        ))}
    </Wrapper>
  )
}

export default Organizations
