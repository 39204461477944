import React, { useState } from 'react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import i18n from 'i18next'
import { languages } from '../../../../data/resources/lists'
import { useWindow } from '../../../../utils/hooks/useWindow'
import { Container, Wrapper, Item, ItemImg, ItemName, Icon } from './style'
import 'flag-icon-css/css/flag-icons.min.css'
import { BREAK_POINTS, COLORS } from '../../../../assets/styles/theme'
import { Helmet } from 'react-helmet'
import { Menu, MenuItem } from '@mui/material'

const LangMenu = () => {
  const { width } = useWindow()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [lang, setLang] = useState(
    i18n.language ? languages.find((l) => i18n.language.toString() === l.code) : languages[0]
  )

  const handleLangSelect = (code) => {
    setLang(languages.find((l) => code === l.code))
    i18n.changeLanguage(code)
    setIsMenuOpen(false)
    document.documentElement.setAttribute('lang', code)
  }

  const mobileBreakPoint = Number(BREAK_POINTS.m.split(' ')[1].slice(0, -2))

  const menuItemStyle = {
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    width: '12rem'
  }

  const selectedMenuItemStyle = {
    backgroundColor: COLORS.primary,
    color: COLORS.background_d,
    '&:hover': {
      backgroundColor: COLORS.primary_d
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <Container isMenuOpen={isMenuOpen}>
      <Helmet htmlAttributes={{ lang: lang.code }}></Helmet>
      <Wrapper onClick={handleClick}>
        <Item>
          {lang ? (
            <>
              <ItemImg className={`flag-icon flag-icon-${lang.countryCode}`}></ItemImg>
              <ItemName langLen={lang.name.length}>
                {width > mobileBreakPoint ? lang.name : lang.shortName}
              </ItemName>
            </>
          ) : (
            'loading...'
          )}
        </Item>
        <Icon>{isMenuOpen ? <BsChevronUp /> : <BsChevronDown />}</Icon>
      </Wrapper>
      <Menu
        id='profile-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {languages.map((l) => (
          <MenuItem
            key={l.countryCode}
            sx={
              l.code === lang.code ? { ...menuItemStyle, ...selectedMenuItemStyle } : menuItemStyle
            }
            onClick={
              l.code === lang.code
                ? () => {}
                : (e) => {
                    handleLangSelect(l.code)
                    handleClose()
                  }
            }
          >
            <ItemImg className={`flag-icon flag-icon-${l.countryCode}`}></ItemImg>
            <ItemName>{width > mobileBreakPoint ? l.name : l.shortName}</ItemName>
          </MenuItem>
        ))}
      </Menu>
    </Container>
  )
}

export default LangMenu
