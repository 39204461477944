export const getDashboardScreen = (params, serviceIds) => {
  serviceIds = Object.fromEntries(
    Object.entries(serviceIds).map(([key, value]) => [key, value.toString()])
  );

  if (params) {
    if (params.merchantId === serviceIds.ASAN_SERVICE_ID.toString()) {
      if (params.category) {
        if (params.organization) {
          return 'service-form';
        } else {
          return 'organization-list';
        }
      } else {
        return 'category-list';
      }
    } else if (params.merchantId === serviceIds.PARKING_SERVICE_ID.toString()) {
      return 'parking-screen';
    } else if (params.merchantId === serviceIds.YDM_SERVICE_ID.toString()) {
      return 'ydm-screen';
    } else if (params.merchantId === serviceIds.MARKET_SERVICE_ID.toString()) {
      return 'market-screen';
    } else if (params.merchantId === serviceIds.TAKSI_SERVICE_ID.toString()) {
      return 'taxi-screen';
    } else return 'error-screen';
  } else {
    return 'service-list';
  }
};
