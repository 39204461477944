import React from 'react'
import { Container, Arrow, Content } from './style'

const ToolTip = ({ children, wid, left, bot, isText, dir, bg }) => {
  return (
    <Container
      hastext={children}
      istext={isText}
      bot={bot}
      left={left}
      dir={dir ? dir : 'bottom'}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Arrow dir={dir ? dir : 'bottom'} bg={bg} />
      <Content bg={bg} wid={wid}>
        {children}
      </Content>
    </Container>
  )
}

export default ToolTip
