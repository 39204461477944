/* eslint-disable react-hooks/exhaustive-deps */
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { uniq, uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import styled from 'styled-components';
import { Spinner } from '../../../assets/styles/style/styled';
import { BREAK_POINTS, COLORS } from '../../../assets/styles/theme';
import {
  connectBulkOrg,
  getBulkStatus,
  getConnectedPaymentsByEmail,
} from '../../../services/api/cardApi';
import { getMerchants } from '../../../services/api/merchantApi';
import { formatCardNumber } from '../../../utils/helpers/helpers';
import { formatBonusCard, validateMarketBonus } from '../../../utils/helpers/validatePlate';
import { useAuth } from '../../../utils/hooks/useAuth';
import { useFetch } from '../../../utils/hooks/useFetch';
import { useGetCardsByEmail } from '../../../utils/hooks/useGetCardsByEmail';
import { useLogger } from '../../../utils/hooks/useLogger';
import { useToast } from '../../../utils/hooks/useToast';
import { Button } from '../../components/features/login-form/style';
import { Input } from '../../components/features/login-wrapper/style';
import { useServiceStore } from '../../../context/ServiceIdProvider';

const Container = styled.main`
  width: 100%;
  height: 75vh;
  display: flex;
  gap: 1rem;
  @media (${BREAK_POINTS.m}) {
    flex-direction: column;
    min-height: 75vh;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  gap: 1rem;
`;
const Left = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const Right = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const ServicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 90%;
  flex: 1;
`;
const AddContainer = styled.form`
  display: flex;
  align-items: center;
`;

const tableStyles = {
  '& .MuiDataGrid-cell': {
    cursor: 'pointer',
  },
  boxShadow: 1,
  '& .MuiDataGrid-cell:hover': {
    color: COLORS.accent,
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
    width: '0.3em',
    height: '0.3em',
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.accent,
      light: COLORS.accent_l,
      dark: COLORS.accent_d,
    },
  },
});

const MarketView = () => {
  const { t } = useTranslation();
  const { MARKET_SERVICE_ID: marketServiceId } = useServiceStore();

  const lang = {
    // Root
    noRowsLabel: t('no_result_table'),
    noResultsOverlayLabel: t('no_result_table'),
    errorOverlayDefaultLabel: t('error_something_went_wrong'),

    // Columns panel text
    columnsPanelTextFieldLabel: t('toolbar_search_column'),
    columnsPanelTextFieldPlaceholder: t('search'),
    columnsPanelDragIconLabel: t('toolbar_search_column'),
    columnsPanelShowAllButton: t('toolbar_search_column_show'),
    columnsPanelHideAllButton: t('toolbar_search_column_hide'),

    // Filter panel text
    filterPanelAddFilter: t('toolbar_filter_add'),
    filterPanelDeleteIconLabel: t('toolbar_filter_add'),
    filterPanelLinkOperator: t('toolbar_filter_link_operators'),
    filterPanelOperators: t('toolbar_filter_operators'),

    // TODO v6: rename to filterPanelOperator
    filterPanelOperatorAnd: t('toolbar_op_and'),
    filterPanelOperatorOr: t('toolbar_op_or'),
    filterPanelColumns: t('toolbar_op_cols'),
    filterPanelInputLabel: t('toolbar_op_cols_input'),
    filterPanelInputPlaceholder: t('toolbar_op_value'),

    // Filter operators text
    filterOperatorContains: t('toolbar_val_contains'),
    filterOperatorEquals: t('toolbar_val_equals'),
    filterOperatorStartsWith: t('toolbar_val_stw'),
    filterOperatorEndsWith: t('toolbar_val_enw'),
    filterOperatorIs: t('toolbar_val_eq'),
    filterOperatorNot: t('toolbar_val_neq'),
    filterOperatorAfter: t('toolbar_val_after'),
    filterOperatorOnOrAfter: t('toolbar_val_on_after'),
    filterOperatorBefore: t('toolbar_val_before'),
    filterOperatorOnOrBefore: t('toolbar_val_on_before'),
    filterOperatorIsEmpty: t('toolbar_val_emp'),
    filterOperatorIsNotEmpty: t('toolbar_val_nemp'),

    // Column menu text
    columnMenuLabel: t('toolbar_col_menu'),
    columnMenuShowColumns: t('toolbar_col_menu_show'),
    columnMenuFilter: t('toolbar_col_add_filter'),
    columnMenuHideColumn: t('toolbar_col_hide'),
    columnMenuUnsort: t('toolbar_col_unsort'),
    columnMenuSortAsc: t('toolbar_col_sort_asc'),
    columnMenuSortDesc: t('toolbar_col_sort_desc'),

    // Column header text
    columnHeaderFiltersTooltipActive: (count) => t('toolbar_columns_active', { count }),
    columnHeaderFiltersLabel: t('toolbar_col_filters_show'),
    columnHeaderSortIconLabel: t('toolbar_col_filters_sort'),
  };

  const {
    user: { email },
  } = useAuth();

  const { setSuccess, setError } = useToast();

  const { isLoading } = useFetch('merchants', getMerchants, {
    onError: (err) => console.error(err),
    onSuccess: ({ data }) => {
      const marketServices = data.services.find((item) => item.id === marketServiceId);
      let result = [];
      marketServices.organizations.forEach((org) => {
        result.push({ id: org.id, market: org.label });
      });
      setMarkets(result);
    },
  });

  const [bonusCards, setBonusCards] = useState([]);
  const [selectedBonusCards, setSelectedBonusCards] = useState([]);

  const [cards, setCards] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);

  const [markets, setMarkets] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState([]);

  const [chain, setChain] = useState([]);

  const { cardsLoading } = useGetCardsByEmail((data) => setCards(data));

  const [saveLoading, setSaveLoading] = useState(false);
  const [addMode, setAddMode] = useState({
    open: false,
    value: '',
    market: null,
  });

  const queryClient = useQueryClient();
  const logger = useLogger();
  const {
    user: { pin },
  } = useAuth();

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useFetch('bonusCards', () => getConnectedPaymentsByEmail(), {
    onError: (err) => setError(err),
    onSuccess: ({ data }) => {
      const marketData = data.filter(
        (item) =>
          item.isActive === 'true' && item.serviceId === marketServiceId && item.statusCode === 0
      );
      let res = [];
      marketData.forEach((item) => {
        const { id, organizationId, serviceData, cardId } = item;
        res.push({ id, service: organizationId, bonusCard: serviceData, card: cardId });
      });
      setChain(res);
      setBonusCards(
        uniqBy(
          res.map((item) => ({ id: item.id, bonusCard: item.bonusCard, service: item.service })),
          (obj) => obj.bonusCard
        )
      );
    },
  });

  const checkStatus = (id) => {
    const fetchInterval = setInterval(async () => {
      const res = await getBulkStatus(id);
      if (res.status === 200) {
        setSuccess(8001);
        setSelectedBonusCards([]);
        setSelectedCards([]);
        setSelectedMarkets([]);
      } else if (res.status === 500) {
        setError(7002);
      }

      if (res.status === 200 || res.status === 500) {
        clearInterval(fetchInterval);
        queryClient.refetchQueries();
        setSaveLoading(false);
      }
    }, 1000);
  };

  const { mutate } = useMutation((data) => connectBulkOrg(data), {
    onError: (err) => {
      console.error(err);
      setSaveLoading(false);
      setError(err);
    },
    onSuccess: async ({ data }) => {
      if (data.processUuid) {
        checkStatus(data.processUuid);
        logger('Market Connect Bulk, Checking Status', {
          userPin: pin,
          processUUID: data.processUuid,
        });
      } else {
        await sleep(3000);
        logger('Parking Connect Bulk, Success', {
          userPin: pin,
          selectedBonusCards,
          selectedCards,
          selectedMarkets,
        });
        setSuccess(8001);
        setSelectedBonusCards([]);
        setSelectedCards([]);
        setSelectedMarkets([]);
        queryClient.refetchQueries();
        setSaveLoading(false);
      }
    },
  });

  const handleSave = () => {
    const serviceId = marketServiceId.toString();
    const serviceData = uniq(selectedBonusCards.map((item) => item.bonusCard));
    const cardId = uniq(selectedCards.map((item) => item.id));

    setSaveLoading(true);

    mutate({
      serviceId,
      email,
      serviceData,
      cardId,
      services: [{ organizationId: selectedBonusCards[0].service.toString() }],
    });
    logger('Market Connect started', {
      serviceId,
      email,
      serviceData,
      cardId,
      services: [{ organizationId: selectedBonusCards[0].service.toString() }],
    });
  };

  const bonusCardColumns = [
    {
      field: 'bonusCard',
      headerName: t('bonus_cards'),
      width: 300,
      valueFormatter: ({ value }) => formatBonusCard(value),
    },
    {
      field: 'service',
      headerName: t('markets'),
      width: 250,

      valueFormatter: ({ value }) => {
        return markets.find((m) => m.id === +value).market;
      },
    },
    {
      field: 'new',
      hide: true,
    },
  ];
  const cardColumns = [
    {
      field: 'cardNumber',
      headerName: t('my_cards'),
      width: 200,
      valueFormatter: ({ value }) => formatCardNumber(value),
    },
    {
      field: 'type',
      headerName: t('type'),
      width: 160,
    },
  ];

  const handleBonusCardSelect = (ids) => {
    setSelectedBonusCards(bonusCards.filter((item) => ids.includes(item.id)));
  };
  const handleCardSelect = (ids) => {
    const newCards = cards.filter((item) => ids.includes(item.id));
    setSelectedCards(newCards);
  };

  useEffect(() => {
    if (selectedBonusCards.length === 0) {
      setSelectedCards([]);
    } else {
      const bonusCards = selectedBonusCards.map((item) => item.bonusCard);
      let cardIds = uniq(
        chain.filter((item) => bonusCards.includes(item.bonusCard)).map((item) => item.card)
      );

      setSelectedCards(cards.filter((item) => cardIds.includes(item.id)));
    }
  }, [selectedBonusCards]);

  useEffect(() => {
    if (selectedMarkets.length === 0) {
      setSelectedBonusCards([]);
      setSelectedCards([]);
    } else {
      const service = selectedMarkets[0].id;
      setSelectedBonusCards(bonusCards.filter((c) => +c.service === service));
    }
  }, [selectedMarkets]);

  const handleAddMarketBonusBtn = () => {
    setAddMode({ open: true, value: '', market: null });
  };
  const handleCloseAddMarketBonus = () => {
    setAddMode({ open: false, value: '', market: null });
  };
  const handleAddMarketBonus = (e) => {
    e.preventDefault();
    if (addMode.value.length !== 16) setError(7008);
    else if (addMode.market === null) setError(7009);
    else {
      const newMarketBonus = {
        id: new Date().valueOf(),
        bonusCard: addMode.value.trim(),
        service: addMode.market.toString(),
        cards: [],
        new: true,
      };
      setBonusCards((prev) => [...prev, newMarketBonus]);
      setAddMode({ open: false, value: '', market: null });
      setSuccess(8003);
    }
  };
  if (isLoading) return <Spinner />;
  return (
    <Container>
      <ThemeProvider theme={theme}>
        <Left>
          <Wrapper>
            <DataGrid
              localeText={lang}
              sx={tableStyles}
              rows={bonusCards}
              columns={bonusCardColumns}
              checkboxSelection
              disableColumnFilter
              disableColumnSelector
              hideFooter
              isRowSelectable={(params) => {
                if (selectedBonusCards.length === 0) return true;
                if (selectedBonusCards[0].service === params.row.service) return true;
                return false;
              }}
              onSelectionModelChange={handleBonusCardSelect}
              selectionModel={selectedBonusCards.map((item) => item.id)}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'plate', sort: 'asc' }],
                },
              }}
            />
            {addMode.open ? (
              <AddContainer onSubmit={handleAddMarketBonus}>
                <Dialog open={addMode.open} onClose={handleCloseAddMarketBonus}>
                  <DialogTitle>{t('add_new_market_bonus')}</DialogTitle>
                  <DialogContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '1rem',
                      width: '30rem',
                    }}
                  >
                    <Input
                      autoFocus
                      value={addMode.value}
                      maxLength={16}
                      onChange={(e) =>
                        setAddMode((prev) => ({ ...prev, value: validateMarketBonus(e) }))
                      }
                      placeholder={t('add_new_market_ph')}
                      radius='0'
                    />
                    <FormControl fullWidth>
                      <InputLabel id='add-market-id'>{t('market')}</InputLabel>
                      <Select
                        value={addMode.market}
                        labelId='add-market-id'
                        label={t('market')}
                        placeholder='helo'
                        onChange={(e) => {
                          setAddMode((prev) => ({ ...prev, market: e.target.value }));
                        }}
                        sx={{ height: '100%' }}
                      >
                        {markets.map((m) => (
                          <MenuItem key={m.id} value={m.id}>
                            {m.market}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </DialogContent>
                  <DialogActions sx={{ display: 'flex', gap: '1rem', paddingInline: '1rem' }}>
                    <Button
                      radius='0.2rem'
                      style={{ margin: 'auto' }}
                      onClick={handleAddMarketBonus}
                    >
                      {t('save')}
                    </Button>
                  </DialogActions>
                </Dialog>
              </AddContainer>
            ) : (
              <Button margin='0' radius='5px' onClick={handleAddMarketBonusBtn}>
                {t('add_new_market_bonus')}
              </Button>
            )}
          </Wrapper>
        </Left>
        <Right>
          <ServicesWrapper>
            <DataGrid
              localeText={lang}
              sx={tableStyles}
              isRowSelectable={() => selectedBonusCards.length}
              rows={cardsLoading ? [] : cards}
              columns={cardColumns}
              checkboxSelection
              hideFooter
              onSelectionModelChange={handleCardSelect}
              selectionModel={selectedCards.map((item) => item.id)}
            />
          </ServicesWrapper>
          <Button
            style={{ marginBottom: '3rem' }}
            isdisabled={
              !selectedBonusCards.length || !selectedCards.length || saveLoading ? 'true' : 'false'
            }
            radius='5px'
            onClick={
              !selectedBonusCards.length || !selectedCards.length || saveLoading
                ? () => {}
                : handleSave
            }
          >
            {t('save')}
          </Button>
        </Right>
      </ThemeProvider>
      {saveLoading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={saveLoading}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
    </Container>
  );
};

export default MarketView;
