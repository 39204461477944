import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export const codes = {
  400: 'error_something_went_wrong',
  401: 'error_email_password',
  404: 'error_something_went_wrong',
  500: 'error_email_password',
  502: 'error_something_went_wrong',
  503: 'error_something_went_wrong',
  6001: 'error_email_password',
  6002: 'card_not_found',
  6003: 'signature_error',
  6004: 'request_error',
  6006: 'error_otp_wrong',
  6007: 'error_something_went_wrong',
  6008: 'process_done',
  6009: 'process_unknown',
  6010: 'email_in_use',
  6011: 'mobile_number_in_use',
  6012: 'pin_in_use',
  6013: 'error_occured_on_saving_img',
  6014: 'password_used',
  6015: 'invalid_credentials',
  6016: 'invalid_date_of_birth',
  7001: 'no_change',
  7002: 'service_in_use',
  7003: 'fill_the_gaps',
  7004: 'invalid_plate',
  7005: 'plate_already_in_use',
  7006: 'service_already_in_use',
  7007: 'wrong_referral',
  7008: 'invalid_market_bonus',
  7009: 'market_required',
  7010: 'invalid_plate_format',
  8001: 'successfully_updated',
  8002: 'deleted_successfully',
  8003: 'plate_added',
  8004: 'error_msg_email_valid',
  8005: 'email_sent_successfully',
  8006: 'plate_added_invalid',
};

export const useToast = () => {
  const { t } = useTranslation();

  function setToast(data, type = 'error', options = {}) {
    if (type === 'error') {
      setError(data, options);
    } else {
      setSuccess(data, options);
    }
  }

  function setError(err, options) {
    if (typeof err === 'string') {
      toast.error(t(err), options);
    } else if (typeof err === 'number') {
      const msg = codes[err];
      toast.error(t(msg), options);
    } else {
      const code = err?.response?.data?.code || err?.response?.status || 400;
      const msg = codes[code];
      toast.error(t(msg), options);
    }
  }

  function setErrorWithId(err, id, options = {}) {
    if (typeof err === 'string') {
      toast.error(t(err), {
        id,
        ...options,
      });
    } else if (typeof err === 'number') {
      const msg = codes[err];
      toast.error(t(msg), {
        id,
        ...options,
      });
    } else {
      const code = err?.response?.data?.code || err?.response?.status || 400;
      const msg = codes[code];
      toast.error(t(msg), {
        id,
        ...options,
      });
    }
  }

  function setSuccess(data, options) {
    if (typeof data === 'string') {
      toast.success(t(data), options);
    } else if (typeof data === 'number') {
      const msg = codes[data];
      toast.success(t(msg), options);
    } else {
      const msg = data?.message || 'successfully_updated';
      toast.success(t(msg), options);
    }
  }

  function setSuccessWithId(data, id, options = {}) {
    if (typeof data === 'string') {
      toast.success(t(data), {
        id,
        ...options,
      });
    } else if (typeof data === 'number') {
      const msg = codes[data];
      toast.success(t(msg), {
        id,
        ...options,
      });
    } else {
      const msg = data?.message || 'successfully_updated';
      toast.success(t(msg), { id, ...options });
    }
  }

  return { setError, setSuccess, setToast, setErrorWithId, setSuccessWithId };
};
