import { mobileNumberPrefixes } from './lists';
const accept = mobileNumberPrefixes.join('|');

export const nameRegex = new RegExp(/^[a-z ,.'-]+$/i);
export const alphaNumeric = /^[a-zA-Z0-9]/g;
export const nonAlphaNumeric = /[^a-zA-Z\d\s:]/g;
export const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
export const upperCaseRegex = /[A-Z]/g;
export const lowerCaseRegex = /[a-z]/g;
export const numberRegex = /[0-9]/g;
export const nonNumberRegex = /[^0-9]/g;
export const mobNumRegex = new RegExp(`^(${accept})[2-9][0-9]{6}$`);
export const specialCharRegex = /(?=.*[@$!%*?&#])/g;
export const pinRegex = /^[A-Za-z0-9]*$/g;

// export const plateRegex =
//   /^(0[1-9]|1[0-24-9]|[2-6][0-9]|7[0-7]|9[09])([A-HJ-VX-Z]{1,2})((?!000)\d{3})$/gi;
export const plateRegex =
  /^([0-9]{2})([A-HJ-VX-Z]{1,2})((?!000)\d{3})$/gi;
export const abroadCitizenPlateRegex = /^([MHP])((?!000000)[0-9]{6})$/gi;
export const diplomaticPlateRegex = /^((?!000)\d{3})([DT]|FK)((?!000)\d{2,3})$/gi;
export const militaryPlateRegex =
  /^(([A-HJ-VX-Z]{2})([A-HJ-VX-Z])((?!000)\d{3}))|(([A-HJ-VX-Z])((?!000)\d{3})([A-HJ-VX-Z]{2}))$/gi;

export const opRefRegex = /^A\d{6}$/g;

// export const fullPlateRegex =
//   /^((0[1-9]|1[0-24-9]|[2-6][0-9]|7[0-7]|9[09])([A-HJ-VX-Z]{1,2})((?!000)\d{3}))|(([MHP])((?!000000)[0-9]{6}))|(((?!000)\d{3})([DT]|FK)((?!000)\d{2,3}))|((([A-HJ-VX-Z]{2})([A-HJ-VX-Z])((?!000)\d{3}))|(([A-HJ-VX-Z])((?!000)\d{3})([A-HJ-VX-Z]{2})))$/gi;
export const fullPlateRegex =
  /^(([0-9]{2})([A-HJ-VX-Z]{1,2})((?!000)\d{3}))|(([MHP])((?!000000)[0-9]{6}))|(((?!000)\d{3})([DT]|FK)((?!000)\d{2,3}))|((([A-HJ-VX-Z]{2})([A-HJ-VX-Z])((?!000)\d{3}))|(([A-HJ-VX-Z])((?!000)\d{3})([A-HJ-VX-Z]{2})))$/gi;
