import React from 'react';
import { Spinner } from '../../../assets/styles/style/styled';
import { ChipContainer, ChipName, ChipValue, IconContainer } from './style';

const ProfileChip = ({ name, value, icon, right, loading }) => {
  return (
    <ChipContainer right={right}>
      <ChipName>{name}</ChipName>
      {loading ? (
        <Spinner wid='.5rem' col='acc' />
      ) : (
        <ChipValue>
          <span>{value}</span>
          <IconContainer>{icon}</IconContainer>
        </ChipValue>
      )}
    </ChipContainer>
  );
};

export default ProfileChip;
