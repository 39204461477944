import styled from 'styled-components'
import { RiGitRepositoryPrivateLine } from 'react-icons/ri'

const Container = styled.main`
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;

  background-color: #f5f5f5;

  font-style: italic;
  color: #212121;
`
const Icon = styled(RiGitRepositoryPrivateLine)``
const Desc = styled.p``

const Unsecured = () => {
  return (
    <Container>
      <Icon size={'2rem'} />
      <Desc>www.tezode.az refused to connect.</Desc>
    </Container>
  )
}

export default Unsecured
