import styled from 'styled-components'
import { motion } from 'framer-motion'
import { BREAK_POINTS, COLORS, SHADOWS } from '../../../../assets/styles/theme'

export const Wrapper = styled(motion.section)`
  min-height: 18rem;
  width: 40%;
  @media (${BREAK_POINTS.l}) {
    width: 65%;
  }
  @media (${BREAK_POINTS.m}) {
    width: 80%;
  }
  @media (${BREAK_POINTS.s}) {
    width: 90%;
  }
  background-color: ${COLORS.background_l};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: ${SHADOWS.low};
`
export const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  @media (${BREAK_POINTS.m}) {
    width: 95%;
  }
  gap: 1rem;
`
export const HeaderText = styled.h2`
  text-align: center;
  margin-bottom: 0.2rem;
`
export const HeaderBtns = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
`
export const Label = styled.label`
  height: 3rem;
  width: 100%;
  background-color: ${({ isSelected }) => (isSelected ? COLORS.background_d : COLORS.background)};
  border: 1px solid ${COLORS.gray_l};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-left: 2rem;
  @media (${BREAK_POINTS.m}) {
    padding-left: 1rem;
  }
  border-radius: 6px;
  transition: all 0.3s ease;
  &:hover {
    background-color: ${COLORS.background_d};
  }
`
export const Check = styled.input`
  cursor: pointer;
`
export const LabelText = styled.span`
  font-size: 0.9rem;
  @media (${BREAK_POINTS.m}) {
    font-size: 0.75rem;
  }
`
export const Form = styled.form`
  width: 80%;
  @media (${BREAK_POINTS.m}) {
    width: 95%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
`
export const Footer = styled.footer``
export const Line = styled.div`
  width: 80%;
  @media (${BREAK_POINTS.m}) {
    width: 95%;
  }
  height: 1px;
  background-color: ${COLORS.gray_l};
  margin: 1rem 0;
`
