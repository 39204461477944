import axios from 'axios'
import { merchantBase } from './baseURL'
import { requestHeaders, URLS } from '../../data/resources/constants'

const merchantApi = axios.create({
  baseURL: merchantBase,
  headers: requestHeaders
})

export const getMerchants = async () => await merchantApi(URLS.merchant_get)
