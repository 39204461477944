import styled from 'styled-components'
import { BREAK_POINTS, COLORS, SHADOWS } from '../../../../assets/styles/theme'
import { motion } from 'framer-motion'

const USER_SECTION_HEIGHT = '3rem'
export const Container = styled.div`
  display: flex;
  @media (${BREAK_POINTS.l}) {
    display: ${({ isLanding }) => (isLanding ? 'none' : 'flex')};
  }
  align-items: center;
  gap: 1rem;
  height: ${USER_SECTION_HEIGHT};
  padding: 0.5rem 1rem;
  border-radius: ${({ isDropOpen, isMenu }) => (isDropOpen ? '5px 5px 0 0' : isMenu ? '0' : '5px')};
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  ${({ isMenu }) =>
    isMenu &&
    `
  width: 100%;
  justify-content: center;
  `}
  &:hover {
    background-color: ${({ isLanding }) => (isLanding ? COLORS.nav_col : COLORS.background)};
    color: ${({ isMenu, isLanding }) => (isMenu ? COLORS.black : COLORS.black)};
  }
  border: ${({ isLanding, isMenu }) =>
    isLanding || isMenu ? 'none' : `1px solid ${COLORS.gray_l}`};
  color: ${({ isLanding, isMenu }) => (isMenu ? COLORS.background_d : COLORS.black)};
  @media (${BREAK_POINTS.m}) {
    height: 2.75rem;
    width: ${({ isMenu }) => (isMenu ? '100%' : '5rem')};
    padding: 0.5rem 0 0.5rem 0.5rem;
    justify-content: center;
    gap: 0.5rem;
  }
  @media (${BREAK_POINTS.s}) {
    height: 2.5rem;
    width: ${({ isMenu }) => (isMenu ? '100%' : '4rem')};
  }
`
export const UserName = styled.span`
  font-size: 0.85rem;
  transition: all 0.1s ease;
  @media (${BREAK_POINTS.m}) {
    display: ${({ isMenu }) => (isMenu ? 'block' : 'none')};
  }
`
export const UserImgContainer = styled.div`
  height: 2.5rem;
  aspect-ratio: 1;
  @media (${BREAK_POINTS.m}) {
    height: 2rem;
  }
  @media (${BREAK_POINTS.s}) {
    height: 1.5rem;
  }
`
export const UserImg = styled.img`
  height: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  object-fit: cover;
`
export const Chevron = styled.div`
  height: 100%;
  aspect-ratio: 1;
  margin-left: -0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease;
  color: black;
  &:hover {
    background-color: ${({ isLanding }) => (isLanding ? 'red' : COLORS.gray_l)};
  }
`
export const DropDown = styled(motion.div)`
  position: absolute;
  top: ${USER_SECTION_HEIGHT};
  left: 0;
  width: 100%;
  border-radius: 0 0 5px 5px;
  @media (${BREAK_POINTS.m}) {
    width: 10rem;
    border-radius: 0 5px 5px 5px;
  }
  color: ${COLORS.background_d};
  background-color: ${COLORS.black};
`
export const DropDownItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.8rem;
  height: 2.25rem;
  padding-left: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  &:last-child {
    border-radius: 0 0 5px 5px;
  }
  @media (${BREAK_POINTS.m}) {
    &:last-child {
      border-radius: 0 5px 5px 5px;
    }
  }
  &:hover {
    background-color: ${COLORS.primary};
  }
`
export const DropDownName = styled.span``
export const Block = styled.div`
  height: 1.5rem;
  width: 6rem;
  background-color: ${COLORS.gray_l};
  border-radius: 3px;
`
export const LanguageModal = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${COLORS.background};
  box-shadow: ${SHADOWS.mid};
  width: 25rem;
  @media (${BREAK_POINTS.m}) {
    width: 90%;
  }
  border-radius: 5px;
`

export const LangItem = styled.div`
  height: 4rem;
  @media (${BREAK_POINTS.m}) {
    height: 3.5rem;
  }
  @media (${BREAK_POINTS.s}) {
    height: 3rem;
  }
  padding: 0.5rem;
  padding-left: 1rem;
  background-color: ${(props) => (props.isActive ? COLORS.primary : COLORS.background_d)};
  color: ${(props) => (props.isActive ? COLORS.background : COLORS.text)};
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
  cursor: ${(props) => (props.isActive ? 'default' : 'cursor')};
  &:hover {
    background-color: ${COLORS.primary_l};
    color: ${COLORS.background};
  }
  &:last-child {
    border-radius: 0 0 5px 5px;
  }
  &:first-child {
    border-radius: 5px 5px 0 0;
  }
`
