import styled from 'styled-components'
import { BREAK_POINTS, COLORS, SHADOWS } from '../../../../assets/styles/theme'

const IMG_CONTAINER_HEIGHT = 8
const IMG_CONTAINER_HEIGHT_S = 6
export const Container = styled.main`
  width: 100vw;
  min-height: 90vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
  position: relative;
  @media (${BREAK_POINTS.l}) {
    padding-top: 4rem;
  }
`
export const Title = styled.h2``
export const Subtitle = styled.h4`
  z-index: 10;
  text-align: center;

  @media (${BREAK_POINTS.m}) {
    padding-inline: 1rem;
  }
`
export const Content = styled.span`
  width: 90%;
  display: flex;
  align-items: center;
  gap: 3rem;
  justify-content: center;
  margin-top: 5rem;
  @media (${BREAK_POINTS.m}) {
    flex-direction: column;
    gap: 2rem;
  }
`
export const Img = styled.img`
  height: ${IMG_CONTAINER_HEIGHT / 2}rem;
  @media (${BREAK_POINTS.m}) {
    height: ${IMG_CONTAINER_HEIGHT_S / 2}rem;
  }
`
export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 20rem;
  height: 15rem;
  background-color: ${COLORS.background_l};
  border-radius: 5px;
  box-shadow: ${SHADOWS.low};
  transition: all 0.3s ease;
  cursor: default;
  &:hover {
    transform: translateY(-1rem);
  }
  @media (${BREAK_POINTS.m}) {
    width: 80%;
    height: 10rem;
  }
`
export const ImgContainer = styled.div`
  position: absolute;
  height: ${IMG_CONTAINER_HEIGHT}rem;
  aspect-ratio: 1;
  top: -${IMG_CONTAINER_HEIGHT / 2}rem;
  left: 50%;
  transform: translateX(-50%);
  background: ${COLORS.gray_l};
  display: flex;
  align-items: center;
  border-radius: 50%;
  box-shadow: ${SHADOWS.low};
  justify-content: center;
  transition: all 0.5s ease-in-out;
  @media (${BREAK_POINTS.m}) {
    height: ${IMG_CONTAINER_HEIGHT_S}rem;
    top: -${IMG_CONTAINER_HEIGHT_S / 2}rem;
  }
`
export const CardTitle = styled.h3``
export const CardDesc = styled.span``
export const Dots = styled.img`
  position: absolute;
  left: ${({ pos }) => (pos.left ? pos.left : '0')};
  top: ${({ pos }) => (pos.top ? pos.top : '0')};
  @media (${BREAK_POINTS.l}) {
    top: ${({ pos }) => (pos.top ? Number(pos.top.split('r')[0]) + 4 + 'rem' : '0')};
  }
`
