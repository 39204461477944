import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { t } from 'i18next'

import styled from 'styled-components'
import { COLORS, SHADOWS } from '../../assets/styles/theme'

import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'
import { AnimatePresence, motion } from 'framer-motion'

export const errorMsgs = {
  400: 'error_something_went_wrong',
  401: 'error_email_password',
  404: 'error_something_went_wrong',
  500: 'error_email_password',
  6001: 'error_email_password',
  6002: 'card_not_found',
  6003: 'signature_error',
  6004: 'request_error',
  6006: 'error_otp_wrong',
  6007: 'error_something_went_wrong',
  6008: 'process_done',
  6009: 'process_unknown',
  6010: 'error_something_went_wrong', // email_in_use
  6011: 'error_something_went_wrong', // mobile_number_in_use
  6012: 'error_something_went_wrong', // pin_in_use
  6013: 'error_occured_on_saving_img',
  6014: 'password_used',
  6015: 'invalid_credentials',
  6016: 'invalid_date_of_birth',
  7001: 'no_change',
  7002: 'service_in_use',
  7003: 'fill_the_gaps',
  7004: 'invalid_plate',
  7005: 'plate_already_in_use',
  7006: 'service_already_in_use',
  7007: 'wrong_referral',
  8001: 'successfully_updated',
  8002: 'deleted_successfully',
  8003: 'plate_added',
  8004: 'error_msg_email_valid',
  8005: 'email_sent_successfully'
}

const DEFAULT_OPTIONS = {
  height: '3rem',
  width: '20rem'
}

const typeMap = {
  error: <ErrorOutlineRoundedIcon />,
  success: <CheckCircleOutlineRoundedIcon />,
  info: <InfoRoundedIcon />,
  warning: <WarningAmberRoundedIcon />
}

const typeContainerMap = {
  error: { color: COLORS.error_d, bg: COLORS.error_ll },
  success: { color: COLORS.primary_d, bg: COLORS.primary_ll },
  warning: { color: COLORS.background_d, bg: COLORS.warning },
  info: { color: COLORS.accent_d, bg: COLORS.accent }
}

const Feedback = ({ children, type, options, isText }) => {
  return (
    <Container
      initial={{ y: '5rem' }}
      animate={{ y: 0 }}
      exit={{ y: '5rem' }}
      type={type}
      options={options}
    >
      <Icon>{typeMap[type]}</Icon>
      <Text>{isText ? t(children) : t(errorMsgs[children])}</Text>
    </Container>
  )
}

const Container = styled(motion.div)`
  position: fixed;
  height: ${({ options }) => (options.height ? options.height : DEFAULT_OPTIONS.height)};
  width: ${({ options }) => (options.width ? options.width : DEFAULT_OPTIONS.width)};
  bottom: 1rem;
  right: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  box-shadow: ${SHADOWS.so_low};
  display: flex;
  align-items: center;
  gap: 1rem;
  color: ${({ type }) => (type ? typeContainerMap[type].color : COLORS.error_d)};
  background-color: ${({ type }) => (type ? typeContainerMap[type].bg : COLORS.error_ll)};
  cursor: pointer;
  z-index: 1000;
`
const Text = styled.span``
const Icon = styled.div`
  height: 100%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DEFAULT_TIMEOUT = 3000
const FB_TYPES = ['error', 'warning', 'info', 'success']
const INITIAL_FB = {
  value: '',
  type: '',
  display: null
}

/**
 * @typedef OptionsObject
 * @type {object}
 * @property {string} [height = DEFAULT_OPTIONS.height] - height of the feedback block - e.g. '1rem', '20%', '10vh' etc.
 * @property {string} [width = DEFAULT_OPTIONS.width] - width of the feedback block - e.g. '2rem', '20%', '10vw' etc.
 */
/**
 * @typedef ReturnsObject
 * @type {object}
 * @property {string} value - value or message of the feedback
 * @property {useFeedback~setFeedback} setFeedback - setting new feedback
 * @property {JSX} display - display variable is used to display the feedback on the screen
 */
/**
 *  useFeedback hook is used everytime you want to give feedback to the user. E.g. error, success, warning, etc.
 * @param {number} [timeout = DEFAULT_TIMEOUT]
 * @param {OptionsObject} options
 * @returns {ReturnsObject}
 */
export const useFeedback = (
  timeout = DEFAULT_TIMEOUT,
  options = { height: DEFAULT_OPTIONS.height, width: DEFAULT_OPTIONS.width },
  text = false
) => {
  const [feedback, feedbackSet] = useState(INITIAL_FB)
  const location = useLocation()

  /**
   * deleting values from the feedback state
   * @returns void
   */
  const deleteFb = () => feedbackSet(INITIAL_FB)

  /**
   * setFeedback sets new feedback, it changes 'value' and 'display' variables
   * @param {(string | number)} value - value or message of the feedback
   * @param {string} [type = 'error'] - type of the feedback. Use => error, success, warning or info
   * @param {number} [time = timeout] - time which indicates how long the feedback should stay on the screen
   * @param {OptionsObject} [opt = options] - options object for width and height of the feedback block
   */
  const setFeedback = (value, type = 'error', time = timeout, opt = options) => {
    if (!FB_TYPES.includes(type))
      throw new Error(
        'Invalid Feedback type. Available types are: "error", "success", "warning", "info".'
      )
    if (value === null || value === undefined) throw new Error('Value can not be empty.')
    if (typeof time !== 'number') throw new Error('Time has to be a number.')

    feedbackSet({
      value,
      type,
      display: (
        <AnimatePresence>
          <Feedback type={type} options={opt} isText={text}>
            {value}
          </Feedback>
        </AnimatePresence>
      )
    })
    setTimeout(() => deleteFb(), time)
  }

  useEffect(() => {
    deleteFb()
  }, [location.pathname])

  return { value: feedback.value, setFeedback, display: feedback.display }
}
