import styled from 'styled-components'
import { BREAK_POINTS, COLORS, SHADOWS } from '../../../assets/styles/theme'
import { FooterLink } from '../../components/features/login-wrapper/style'

export const Container = styled.main`
  min-height: 100vh;
  width: 100vw;
  background-color: ${COLORS.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem 0;
`
export const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.background};
  height: 3.5rem;
  width: 12rem;
  border-radius: 4px;
  box-shadow: ${SHADOWS.mid};
`
export const Img = styled.img`
  width: 8rem;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
`
export const Footer = styled.footer`
  font-size: 0.8rem;
  font-style: italic;
  color: ${COLORS.gray_d};
  @media (${BREAK_POINTS.m}) {
    font-size: 0.7rem;
  }
  text-align: center;
`
export const Redirect = styled(FooterLink)`
  margin-left: 4px;
  font-size: 0.8rem;
  color: ${COLORS.gray};

  &:hover {
    color: ${COLORS.gray_d};
  }

  & > a {
    color: inherit;
    text-decoration: none;
  }
`
