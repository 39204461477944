import styled from 'styled-components'
import { BREAK_POINTS, COLORS, SHADOWS } from '../../../../assets/styles/theme'

export const Container = styled.main`
  @media (${BREAK_POINTS.l}) {
    height: 90vh;
    gap: 2rem;
  }
  display: flex;
  flex-direction: column;
  gap: 5rem;
  justify-content: center;
  align-items: center;
  position: relative;
`
export const Background = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -20;
`
export const Title = styled.h2`
  color: ${COLORS.background_d};
  margin-top: 1rem;
`
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 100%;
  gap: 2.5rem;
  @media (${BREAK_POINTS.l}) {
    flex-direction: column;
    gap: 3rem;
  }
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
  width: 30rem;
  @media (${BREAK_POINTS.l}) {
    width: 100%;
  }
`
export const Textarea = styled.textarea`
  width: 100%;
  height: 10rem;
  border: 1px solid ${COLORS.gray_l};
  padding: 0.5rem 1rem;
  border-radius: 5px;
  resize: none;
  &:hover {
    box-shadow: ${({ isBackgroundBlue }) =>
      isBackgroundBlue === 'true' ? SHADOWS.b_low : SHADOWS.so_low};
  }
  &:focus {
    outline: none;
    box-shadow: ${({ isBackgroundBlue }) =>
      isBackgroundBlue === 'true' ? SHADOWS.b_mid : SHADOWS.low};
  }
  &:disabled {
    background-color: ${COLORS.gray_l};
    &:hover {
      border: 1px solid ${COLORS.gray_l};
    }
  }
`
