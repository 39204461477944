import axios from 'axios';
import { base, token_type } from './baseURL';
import { requestHeaders, URLS } from '../../data/resources/constants';

const userApi = axios.create({
  baseURL: base + URLS.user,
  headers: requestHeaders,
});

const userApiPopup = axios.create({
  baseURL: base + URLS.user,
  headers: requestHeaders,
});

userApi.interceptors.request.use(function (config) {
  const user = JSON.parse(sessionStorage.getItem('user')) || null;
  const value = user?.value || null;
  const token = value.accessToken;

  config.headers['Authorization'] = token_type + token;
  return config;
});

export const getUserInfo = async () => await userApi(URLS.user_get_info);
export const getUserCount = async () => await userApi(URLS.user_get_count);
export const getTransactions = async () => await userApi(URLS.user_get_transactions);
export const editUser = async (data) => await userApi.post(URLS.user_edit_user, data);
export const addImage = async (data) => await userApi.post(URLS.user_add_image, data);
export const deleteImage = async () => await userApi.delete(URLS.user_delete_image);
export const sendOtp = async (data) => await userApi.post(URLS.user_send_otp, data);
// export const getBonus = async (referral) => await userApi(URLS.user_get_bonus + referral);
export const editUserEmail = async (data, token) =>
  await userApiPopup.post(URLS.auth_edit, data, {
    headers: {
      Authorization: token_type + token,
    },
  });
