import { createContext, useContext, useState } from 'react'
import { TRANSACTION_INITIAL } from '../data/resources/constants'
import { initialPaginationValues } from '../data/resources/initialStates'
import { compareNumbers, compareStrings, makeDate } from '../utils/helpers/sortingHelpers'

const TableContext = createContext({})

// const actionTypes = {
//   SHOW_ALL: 'SHOW_ALL'
// }

// const initialTable = {
//   rows: null,
//   initialRows: null,
//   currentPage: 1,
//   rowsPerPage: 5,
//   totalPageNumbers: 0,
//   start: 0,
//   end: 5,
//   query: '',
//   isAsc: true
// }

// const tableReducer = (state, action) => {
//   switch (action.type) {
//     case actionTypes.SHOW_ALL:
//     default:
//       return state
//   }
// }

const TableProvier = ({ children, user }) => {
  const [rows, setRows] = useState(null)
  const [initRows, setInitRows] = useState(null)
  const [currentPage, setCurrentPage] = useState(initialPaginationValues.currentPage)
  const [rowsPerPage, setRowsPerPage] = useState(initialPaginationValues.rowsPerPage)
  const [totalPageNumbers, setTotalPageNumbers] = useState(0)
  const [start, setStart] = useState(initialPaginationValues.start)
  const [end, setEnd] = useState(initialPaginationValues.end)
  const [query, setQuery] = useState('')

  const handlePaginate = (_, value) => {
    setCurrentPage(value)
    setStart((value - 1) * rowsPerPage)
    setEnd((value - 1) * rowsPerPage + rowsPerPage)
  }

  const showAll = () => {
    setRowsPerPage(rows.length)
    setTotalPageNumbers(1)
    setStart(0)
    setEnd(rows.length)
    setCurrentPage(1)
  }

  const handleSelect = (e) => {
    setCurrentPage(1)
    if (e.target.value === TRANSACTION_INITIAL) {
      showAll()
      return
    }
    let rpp = Number(e.target.value)
    if (rpp > rows.length) {
      showAll()
      return
    }
    setRowsPerPage(rpp)
    setTotalPageNumbers(Math.ceil(rows.length / rpp))
    setStart(0)
    setEnd(rpp)
  }

  const handleChange = (value) => {
    setQuery(value)
    let newResults = []
    initRows.forEach((row) => {
      for (let key in row) {
        if (typeof row[key] === 'string') {
          if (row[key].toLowerCase().includes(value.toLowerCase())) {
            newResults.push(row)
          }
        }
      }
    })
    setRows(newResults)
    setTotalPageNumbers(Math.ceil(newResults.length / rowsPerPage))
  }

  const handleSort = (name, type, asc) => {
    if (type === 'string') {
      setRows(rows.sort((a, b) => compareStrings(a[name], b[name], asc)))
      return
    }
    if (type === 'number') {
      setRows(rows.sort((a, b) => compareNumbers(a.amount, b.amount, asc)))
      return
    }
    setRows(
      rows.sort((a, b) =>
        asc ? makeDate(b.date) - makeDate(a.date) : makeDate(a.date) - makeDate(b.date)
      )
    )
  }

  return (
    <TableContext.Provider
      value={{
        rows,
        query,
        currentPage,
        totalPageNumbers,
        start,
        end,
        handlePaginate,
        handleSelect,
        handleChange,
        handleSort,
        setRows,
        setInitRows,
        setTotalPageNumbers
      }}
    >
      {children}
    </TableContext.Provider>
  )
}

const useTable = () => {
  return useContext(TableContext)
}

export { TableProvier, useTable }
