import { About, FAQ, GoTop, Header, Partners, Products, SubHeader } from '../../components';

const Landing = () => {
  return (
    <>
      <Header />
      <SubHeader />
      <Products />
      <About />
      <Partners />
      <FAQ />
      <GoTop />
    </>
  );
};

export default Landing;
