import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { LanguageModal, LangItem } from './style';
import BackDrop from '../../layout/backdrop';
import { languages } from '../../../../data/resources/lists';
import { ItemImg, ItemName } from '../lang-menu/style';

export function UserLanguageMenu(props) {
  return (
    <AnimatePresence>
      {props.isOpenModal && (
        <BackDrop onClick={() => props.closeLanguageModal()}>
          <LanguageModal onClick={(e) => e.stopPropagation()}>
            {languages.map((l) => (
              <LangItem
                isActive={l.code === props.lang.code}
                key={l.countryCode}
                onClick={
                  l.code === props.lang.code
                    ? () => {}
                    : () => {
                        props.handleLangSelect(l.code);
                        document.documentElement.setAttribute('lang', l.code);
                      }
                }
              >
                <ItemImg className={`flag-icon flag-icon-${l.countryCode}`}></ItemImg>
                <ItemName>{l.name}</ItemName>
              </LangItem>
            ))}
          </LanguageModal>
        </BackDrop>
      )}
    </AnimatePresence>
  );
}
