import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Title, Content } from './style'

const About = () => {
  const { t } = useTranslation()
  return (
    <Container id='about_us'>
      <Title>{t('about_us')}</Title>
      <Content>{t('about_desc')}</Content>
    </Container>
  )
}

export default About
