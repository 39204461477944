import axios from 'axios';
import { cardBase, token_type } from './baseURL';
import { requestHeaders, URLS } from '../../data/resources/constants';

const cardApi = axios.create({
  baseURL: cardBase,
  headers: requestHeaders,
});

cardApi.interceptors.request.use(function (config) {
  const user = JSON.parse(sessionStorage.getItem('user')) || null;
  const value = user?.value || null;
  const token = value.accessToken;

  config.headers['Authorization'] = token_type + token;
  return config;
});

// export const getConnectedPayments = async (id) =>
//   await cardApi(URLS.card_get_connected_payments + id);
export const getConnectedPaymentsByEmail = async () =>
  await cardApi(URLS.card_get_connected_byemail);
export const changeStatus = async (id) => await cardApi.post(URLS.card_change_status + id);
export const deactivateCard = async (id) => await cardApi.delete(URLS.card_deactivate + id);
export const getCardsByEmail = async () => await cardApi(URLS.card_get_cards_byemail);
export const deleteService = async (xref) => await cardApi.delete(URLS.card_delete_service + xref);
export const addCard = async (email, language = 'en') =>
  await cardApi.post(URLS.card_add_card, null, {
    params: { email, language },
  });
export const connectService = async (data) => await cardApi.post(URLS.card_connect_service, data);
export const connectBulk = async (data) => await cardApi.post(URLS.card_connect_bulk, data);
export const connectBulkOrg = async (data) => await cardApi.post(URLS.card_connect_bulk_org, data);
export const getBulkStatus = async (uuid) => await cardApi(URLS.card_get_bulk_status + uuid);
