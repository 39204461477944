import React from 'react'
import {
  Container,
  ImgContainer,
  Title,
  Oval,
  Background,
  Content,
  ContentTitle,
  Text,
  Img
} from './style'
import { attaLogoBigPng, notfoundbg, oval1, oval2, oval3, oval4 } from '../../../assets/images'
import { useNavigate } from 'react-router-dom'
import { FooterLink } from '../../components/features/login-wrapper/style'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

const NotFound = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Container>
      <Helmet>
        <title>{t('pt_not_found')}</title>
      </Helmet>
      <Background src={notfoundbg} alt='Not Found' />
      <Oval top='10rem' left='96%' src={oval1} alt='Oval' />
      <Oval top='35rem' left='80%' src={oval2} alt='Oval' />
      <Oval top='30rem' left='0' src={oval3} alt='Oval' />
      <Oval top='10rem' left='20%' src={oval4} alt='Oval' />
      <ImgContainer>
        <Img src={attaLogoBigPng} alt='tezödə Logo' />
      </ImgContainer>
      <Title>404</Title>
      <Content>
        <ContentTitle>{t('not_found_title')}</ContentTitle>
        <Text>
          {t('not_found')}
          <FooterLink onClick={() => navigate(-1)}> {t('click_here')} </FooterLink>
        </Text>
      </Content>
    </Container>
  )
}

export default NotFound
