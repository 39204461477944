import LocalGasStationRoundedIcon from '@mui/icons-material/LocalGasStationRounded';
import LocalGroceryStoreRoundedIcon from '@mui/icons-material/LocalGroceryStoreRounded';
import LocalParkingRoundedIcon from '@mui/icons-material/LocalParkingRounded';
import LocalTaxiRoundedIcon from '@mui/icons-material/LocalTaxiRounded';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { asanLogo } from '../../../assets/images';
import { Spinner } from '../../../assets/styles/style/styled';
import { COLORS } from '../../../assets/styles/theme';
import { getMerchants } from '../../../services/api/merchantApi';
import { useFetch } from '../../../utils/hooks/useFetch';
import { Img, Service, ServiceName, Wrapper } from './style';

const ICON_MAP = {
  361: <LocalTaxiRoundedIcon style={{ color: COLORS.primary }} fontSize='large' />,
  241: <LocalGasStationRoundedIcon style={{ color: COLORS.primary }} fontSize='large' />,
  345: <LocalGroceryStoreRoundedIcon style={{ color: COLORS.primary }} fontSize='large' />,
  41: <LocalParkingRoundedIcon style={{ color: COLORS.primary }} fontSize='large' />,
};

const ServiceList = ({ handleServiceClick }) => {
  useHotkeys('enter', () => {
    const el = document.activeElement;
    if (el.classList.contains('service-tab')) {
      el.click();
    }
  });

  const { data } = useFetch('merchants', getMerchants);
  if (!data) return <Spinner />;

  return (
    <Wrapper>
      {data?.data?.services?.map((v, i) => (
        <Service
          className='service-tab'
          tabIndex={'0'}
          key={i}
          onClick={() => handleServiceClick(v.id)}
        >
          {ICON_MAP?.[v.id] || <Img src={asanLogo} alt='Asan Logo' />}
          <ServiceName className='intro_dashboard_2'>{v.shortName}</ServiceName>
        </Service>
      ))}
    </Wrapper>
  );
};

export default ServiceList;
