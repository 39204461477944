import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { initialMessageFormData } from '../../../../data/resources/initialStates';
import { mailSend } from '../../../../services/api/commonApi';
import { formatEmail, formatName, validateEmail } from '../../../../utils/helpers/mailSend';
import { useAuth } from '../../../../utils/hooks/useAuth';
import { useLogger } from '../../../../utils/hooks/useLogger';
import { useToast } from '../../../../utils/hooks/useToast';
import { Input } from '../../features/login-wrapper/style';
import ContactWrapper from '../footer/contact-wrapper';
import { Btn } from '../header/style';
import { Container, Form, Textarea, Title, Wrapper } from './style';

const Contact = () => {
  const { user } = useAuth();
  const logger = useLogger();

  const { t } = useTranslation();
  const [formData, setFormData] = useState(
    user?.email ? { ...initialMessageFormData, email: user?.email } : initialMessageFormData
  );
  const { setError, setSuccess } = useToast();

  const clearForm = () => {
    setFormData(
      user?.email ? { ...initialMessageFormData, email: user?.email } : initialMessageFormData
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.email || !formData.name || !formData.message) {
      setError(7003);
    } else if (validateEmail(formData.email)) {
      setError(8004);
    } else {
      logger('Mail is being sent from contact', formData);
      mutate(formData);
    }
  };

  const { mutate } = useMutation(mailSend, {
    onError: (err) => {
      logger('Mail send error on contact', err);
      console.error(err);
    },
    onSuccess: (data) => {
      logger('Mail sent successfully', { ...data?.data, formData });
      setSuccess(8005);
      clearForm();
    },
  });

  return (
    <Container id='contact'>
      <Title>{t('contact')}</Title>
      <Wrapper>
        <Form onSubmit={handleSubmit}>
          <Input
            value={formData.name}
            isBackgroundBlue={'true'}
            onChange={({ target: { value } }) =>
              setFormData({ ...formData, name: formatName(value) })
            }
            autoComplete='off'
            placeholder={t('name')}
          />
          <Input
            onChange={({ target: { value } }) =>
              setFormData({ ...formData, email: formatEmail(value) })
            }
            isBackgroundBlue={'true'}
            value={formData.email}
            autoComplete='off'
            placeholder={t('Email')}
          />
          <Textarea
            isBackgroundBlue={'true'}
            onChange={({ target: { value } }) => setFormData({ ...formData, message: value })}
            value={formData.message}
            placeholder={t('your_message')}
          ></Textarea>
          <Btn whileTap={{ scale: 0.95 }}>{t('recover_btn')}</Btn>
        </Form>
        <ContactWrapper noTitle={true} />
      </Wrapper>
    </Container>
  );
};

export default Contact;
