import { createContext, useEffect, useReducer } from 'react';
import { LOGIN_FAILURE, LOGIN_START, LOGIN_SUCCESS, LOGOUT } from '../data/resources/actionTypes';
import { EXP, STORAGE_KEYS } from '../data/resources/constants';
import { getLocal, removeLocal, setLocal } from '../utils/helpers/local';

export const INITAL_USER = {
  email: '',
  pin: '',
  accessToken: '',
};

const INITIAL_STATE = {
  user: getLocal(STORAGE_KEYS.USER),
  isLoading: false,
  error: null,
};

const AuthContext = createContext(INITIAL_STATE);

const AuthReducer = (state, action) => {
  switch (action.type) {
    case LOGIN_START:
      return {
        user: null,
        isLoading: true,
        error: null,
      };
    case LOGIN_SUCCESS:
      setLocal(STORAGE_KEYS.USER, action.payload, EXP);
      return {
        user: action.payload,
        isLoading: false,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        user: null,
        isLoading: false,
        error: action.payload,
      };
    case LOGOUT:
      removeLocal(STORAGE_KEYS.USER);
      return {
        user: {
          email: '',
          pin: '',
          accessToken: '',
        },
        isLoading: false,
        error: null,
      };
    default:
      return state;
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  useEffect(() => {
    setLocal(STORAGE_KEYS.USER, state.user, EXP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{ user: state.user, isLoading: state.isLoading, error: state.error, dispatch }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
